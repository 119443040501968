import qs from 'qs'
import { decryptToken } from './crypto'

class UrlParsers {
  constructor (url) {
    this.url = new URL(url)
  }

  isWaiterWeb () {
    return this.url.host.split('.').shift().includes('waiter')
  }

  getParams () {
    const { host, search } = this.url
    let params = qs.parse(search.slice(1))
    if (host.includes('dimorder.com') || host.includes('localhost')) {
      if (params.x) {
        const q = decryptToken(params.x)
        if (q !== false) {
          params = qs.parse(q)
        }
      }
      if (params.f) {
        delete params.f
        params.dir = 'true'
      }
    }

    return params
  }

  getQuery (key) {
    const params = this.getParams()
    return params[key]
  }
}

export default UrlParsers
