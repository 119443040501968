import AppBar from '@/components/AppBar'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import { actions, useDispatch, useSelector } from '@/redux'
import { List } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PasswordInput from './PasswordInput'
import ChangeTable from './settings/ChangeTable'
import MenuVariant from './settings/MenuVariant'
import Reload from './settings/Reload'
import Vconsole from './settings/Vconsole'
import Version from './settings/Version'

export default function Setting (props) {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoginSetting = useSelector(state => state.app.isLoginSetting)

  if (!isLoginSetting) {
    return <PasswordInput />
  }

  return (
    <Page>
      <AppBar handleBack={() => {
        dispatch(actions.app.loginSetting(false))
        history.push('/')
      }}
      />
      <PageHeader title={t('app.setting.title')} />
      <List>
        <Version />
        <Reload />
        <ChangeTable />
        <MenuVariant />
        <Vconsole />
      </List>
    </Page>
  )
}
