import Alert from '@/components/Alerts/Alert'
import hooks from '@/hooks'
import { actions, useDispatch, useSelector } from '@/redux'
import { Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
// import CancelIcon from '@material-ui/icons/Cancel'
import ClearIcon from '@material-ui/icons/Clear'
import _ from 'lodash'
import React, { useState } from 'react'

export default function InputRemarkDialog (props) {
  const dispatch = useDispatch()
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const open = useSelector(state => state.app.dialogs.inputRemark)
  const handleClose = () => dispatch(actions.app.toggleDialog('inputRemark'))
  const [remark, setRemark] = useState('')
  const [remarks, setRemarks] = useState([])
  const isDisabled = !remark || _.includes(remarks, remark)
  const handleDeleteRemark = (index) => {
    const newRemarks = [...remarks]
    newRemarks.pop(index)
    setRemarks(newRemarks)
  }

  const alertConfig = {
    title: '備註',
    message: '',
    children: (
      <Box>
        <Box flexShrink='0' display='flex' alignItems='center'>
          <TextField
            inputRef={input => input && input.focus()}
            autoFocus
            style={{ flex: 1 }}
            label='輸入備註'
            // placeholder='輸入備註'
            value={remark}
            variant='outlined'
            margin='dense'
            onChange={(e) => setRemark(e.target.value)}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position='end'>
            //       <IconButton style={{ marginRight: -14 }}>
            //         <CancelIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          />
          <Button
            color='primary'
            variant='contained'
            className={classes.addButton}
            disabled={isDisabled}
            onClick={() => {
              setRemarks([...remarks, remark])
              setRemark('')
            }}
          >
            <AddIcon />
          </Button>
        </Box>
        <List>
          {_.map(remarks, (remark, index) => {
            return (
              <ListItem key={index} className={classes.remark}>
                <Typography>{remark}</Typography>
                <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryActionRoot }}>
                  <IconButton onClick={() => handleDeleteRemark(index)}>
                    <ClearIcon color='primary' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Box>
    ),
  }

  return (
    <Alert open={open} onClose={handleClose} alertConfig={alertConfig} />
  )
}

const useStyles = makeStyles(theme => ({
  addButton: {
    padding: 8,
    marginLeft: 8,
  },
  remark: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    margin: '4px 0px',
    wordBreak: 'break-word',
  },

  listItemSecondaryActionRoot: {
    right: 0,
  },
}))
