// import delay from '@/libs/delay'
import apiConfig from '@/config/api'
import i18n from '@/i18n'
import dimorderApi from '@/libs/api/dimorder'
import URLParser from '@/libs/URLParser'
import { actions } from '@/redux'
import axios from 'axios'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ActionTypes from './ActionTypes'

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    const UrlParser = new URLParser(window.location.href)
    const params = UrlParser.getParams()
    if (params.m) { localStorage.setItem('merchantId', params.m) }
    const merchantId = params.m || localStorage.getItem('merchantId')

    dispatch(updateLoading(true))
    dispatch(checkUpdate())
    await dispatch(restoreTable(params.t))
    await dispatch(restoreLang())
    await dispatch(actions.user.init())
    // only load data if merchantId is set
    if (merchantId) {
      await dispatch(actions.merchant.init(merchantId))
      await dispatch(actions.menu.init())
      await dispatch(actions.order.createOrder())
      await dispatch(actions.orderHistory.init())
    } else {
      dispatch(toggleAlert({
        title: '沒有設定餐廳',
        noBack: true,
      }))
    }
    dispatch(updateLoading(false))
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

export function checkUpdate () {
  return async (dispatch, getState) => {
    if (window.location.hostname === 'localhost') {
      console.log('localhost skip checkUpdate')
      return
    }
    const oldVersion = _.get([...document.getElementsByTagName('META')].filter((o) => o.name === 'ui-version'), '[0].content')
    // const newVersion = await dimorderApi.merchant.getUiVersion('customer-tablet')
    const isUpdateDialogOpen = getState().app.dialogs.updateVersion
    const newVersion = await axios.get(`${apiConfig.baseUrl}/s/health/version`, { params: { client: 'customer-tablet' } })
    if (oldVersion !== _.get(newVersion, 'data.version') && !isUpdateDialogOpen) {
      dispatch(updateNewVersionAvailable(true))
      dispatch(toggleDialog('updateVersion'))
    }
    dispatch({
      type: ActionTypes.CHECK_UPDATE,
      payload: {},
    })
  }
}
/**
 * @returns {ThunkFunction}
 * @param {boolean} isLoading
 */
export function updateLoading (isLoading) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { isLoading },
    })
  }
}

/**
 * @param {passcode} passcode
 * @returns {ThunkFunction}
 */
export function loginSetting (v) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_IS_LOGIN_SETTING,
      payload: { isLoginSetting: v },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} mode
 */
export function updateMode (mode) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_MODE,
      payload: { mode },
    })
  }
}

/**
 * @param {string} qTable t from query
 * @returns {ThunkFunction}
 */
export function restoreTable (qTable) {
  return (dispatch) => {
    let table = qTable
    if (qTable) {
      localStorage.setItem('table', qTable)
    } else {
      table = localStorage.getItem('table')
    }
    dispatch({
      type: ActionTypes.UPDATE_TABLE,
      payload: { table },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} mode
 */
export function updateTable (table) {
  return (dispatch) => {
    localStorage.setItem('table', table)
    dispatch({
      type: ActionTypes.UPDATE_TABLE,
      payload: { table },
    })
    dispatch(actions.order.createOrder())
  }
}

/**
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function restoreLang (lang) {
  return (dispatch) => {
    // restore from local storage
    const i18nextLng = localStorage.getItem('i18nextLng')
    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'

    // update api language
    dimorderApi.setLanguage(apiLanguage)
    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })
  }
}

/**
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function updateLang (lang) {
  return (dispatch) => {
    const broswerLang = navigator.language || navigator.userLanguage

    const i18nextLng = lang != null ? lang : broswerLang
    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'

    // update i18n language
    i18n.changeLanguage(i18nextLng)
    // update default language
    localStorage.setItem('i18nextLng', i18nextLng)
    // update api language
    dimorderApi.setLanguage(apiLanguage)
    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })

    // 更新菜單和訂單
    dispatch(actions.menu.fetchCategories())
    dispatch(actions.orderHistory.getOrders())
  }
}

/**
 * @returns {ThunkFunction}
 * @param {IAlertConfig?} alertConfig
 * @param {string?} id
 * @param {boolean?} open
 */
export function toggleAlert (alertConfig, id, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_ALERT,
      payload: {
        id: id || uuid(),
        open,
        alertConfig,
      },
    })
  }
}

/**
 * @return {ThunkFunction}
 * @param {string} drawer
 * @param {boolean?} open
 */
export function toggleDrawer (drawer, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DRAWER,
      payload: { drawer, open },
    })
  }
}

/**
 * @return {ThunkFunction}
 * @param {string} drawer
 * @param {boolean?} open
 */
export function toggleSetItemDrawer (drawer, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_SETITEM_DRAWER,
      payload: { drawer, open, data },
    })
  }
}

export function resetDrawers () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DRAWERS,
    })
  }
}

/**
 * @return {ThunkFunction}
 * @param {string} dialog
 * @param {boolean?} open
 * @param {any} data
 */
export function toggleDialog (dialog, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: { dialog, open, data },
    })
  }
}

export function toggleIModalDialog (dialog, open, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_IMODAL_DIALOG,
      payload: { dialog, open, data },
    })
  }
}

export function updateSetting (path, setting) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SETTING,
      payload: { path, setting },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {boolean} isLoading
 */
export function updateNewVersionAvailable (isNewVersionAvailable) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_NEW_VERSION_AVAILABLE,
      payload: { isNewVersionAvailable },
    })
  }
}
