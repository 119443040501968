import dimorderApi from '@/libs/api/dimorder'
import ActionTypes from './ActionTypes'

/**
 * @returns {ThunkFunction}
 */
export function init (params, options) {
  return async (dispatch, getState) => {
    if (options?.newUser) {
      await dispatch(registerUser(params?.payload, params?.hash, params?.client))
      await dispatch(createCustomer())
      return dispatch({
        type: ActionTypes.INIT,
        payload: {},
      })
    } else {
      // 設定 refresh token 的 update user callback
      dimorderApi.setUpdateUserCallback((auth) => dispatch(updateUser(auth)))
      // dimorderApi.setResetUserCallback(() => {
      //   // TODO: 重設狀態，重新開始 registerUser
      // })
    }

    // 用 localStorage 的 auth
    if (localStorage.getItem('auth')) {
      dispatch(restoreUser())
    } else {
      await dispatch(registerUser(params?.payload, params?.hash, params?.client))
      await dispatch(createCustomer())
    }
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function registerUser (payload, hash, client) {
  return async (dispatch) => {
    try {
      const auth = await dimorderApi.auth.register(payload, hash, client)
      localStorage.setItem('auth', JSON.stringify(auth))
      dispatch({
        type: ActionTypes.REGISTER_USER,
        payload: { auth },
      })
    } catch (error) {
      console.log('registerUser error', error)
    }
  }
}

export function updateUser (auth) {
  return async (dispatch) => {
    localStorage.setItem('auth', JSON.stringify(auth))
    dispatch({
      type: ActionTypes.REGISTER_USER,
      payload: { auth },
    })
  }
}

/** @returns {ThunkFunction} */
export function createCustomer () {
  return async (dispatch) => {
    try {
      const customer = await dimorderApi.auth.createCustomer()
      const userId = customer?.id
      localStorage.setItem('userId', userId)
      dispatch({
        type: ActionTypes.CREATE_CUSTOMER,
        payload: { userId },
      })
    } catch (error) {
      console.log('createCustomer error', error)
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function restoreUser () {
  return async (dispatch) => {
    const auth = JSON.parse(localStorage.getItem('auth'))
    const userId = localStorage.getItem('userId')
    dispatch({
      type: ActionTypes.REGISTER_USER,
      payload: { auth },
    })
    dispatch({
      type: ActionTypes.CREATE_CUSTOMER,
      payload: { userId },
    })
    dimorderApi.setToken(auth.token, auth.refresh)
  }
}
