import FooterComponent from '@/components/Footer'
import ServiceRequestButton from '@/components/Footer/ServiceRequestButton'
import CustomBBQTabletCart from '@/components/custom/BBQ/Cart'
import CustomBBQServiceRequestButton from '@/components/custom/BBQ/ServiceRequestButton'
import CustomBBQSubmitButton from '@/components/custom/BBQ/SubmitButton'
import constants from '@/constants'
import hooks from '@/hooks'
import libs from '@/libs'
import { useSelector } from '@/redux'
import _ from 'lodash'
import Cart from './Cart'
import SubmitButton from './SubmitButton'

export default function Footer (props) {
  const mode = hooks.app.useMode()
  const table = useSelector(state => state.orderBatch.selectedBatch?.table)
  // const menuItemQuantities = useSelector(state => state.orderBatch.menuItemQuantities)
  const selectedOrder = hooks.order.useSelectedOrder()
  const selectedBatch = hooks.order.useSelectedBatch()
  const totalQuantities = selectedOrder && _.sumBy([...selectedOrder.batches, selectedBatch], (batch) => {
    const { quantity } = libs.order.calculateDisplayBatchTotal(batch, selectedOrder)
    return quantity
  })
  const merchantId = useSelector(state => state.merchant.data?.id)

  const renderLeftContent = () => {
    if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId)) {
      return <CustomBBQServiceRequestButton />
    }
    return <ServiceRequestButton />
  }

  const rendeMiddleContent = () => {
    if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId) && mode.isTablet) {
      return <CustomBBQTabletCart table={table} totalQuantities={totalQuantities} />
    }
    return <Cart table={table} totalQuantities={totalQuantities} />
  }

  const renderRightContent = () => {
    if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId)) {
      return <CustomBBQSubmitButton totalQuantities={totalQuantities} />
    }
    return <SubmitButton totalQuantities={totalQuantities} />
  }

  return (
    <FooterComponent
      left={renderLeftContent()}
      middle={rendeMiddleContent()}
      right={renderRightContent()}
    />
  )
}
