import CategoryMenu from '@/components/CategoryMenu'
import MenuItemsMobile from '@/components/MenuItems/MenuItemsMobile'
import MenuItemRow from '@/components/MenuItems/MenuItemsRow'
import MenuItemsTablet from '@/components/MenuItems/MenuItemsTablet'
import SplitPanel from '@/components/SplitPanel'
import hooks from '@/hooks'
import { useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export default function Content (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const selectedCategory = hooks.menu.useSelectedCategory()
  const variant = useSelector(state => state.app.setting.menu.variant)
  const size = selectedCategory?.columnSize || 'md'

  /**
 * @param {{
 * variant: 'row' | 'card' | undefined
 * size: 'sm' | 'md' | 'lg' | undefined
 * }} options
 */
  const renderContent = (options) => {
    switch (options.variant) {
      case 'card': {
        if (mode.isMobile) return <MenuItemsMobile size={options.size} />
        if (mode.isTablet) return <MenuItemsTablet size={options.size} />
        break
      }
      case 'row': {
        return <MenuItemRow />
      }
      default: {
        return <MenuItemRow />
      }
    }
  }

  if (mode.isTablet) {
    return (
      <SplitPanel
        menu={<CategoryMenu />}
        content={renderContent({ variant, size })}
      />
    )
  }
  return (
    <main className={classes.contentContainer}>
      {renderContent({ variant, size })}
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
    backgroundColor: theme.palette.secondary.main,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}))
