import hooks from '@/hooks'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{
 * name: string,
 * quantity: number
 * }} props
 */
export default function Header (props) {
  const { name, quantity } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <div className={classes.headerConainer}>
      <Typography className={classes.itemQuantity} color='inherit'>
        {t('app.common.item.quantity', { quantity })}
      </Typography>
      <Typography className={classes.itemName} color='inherit'>
        {name}
      </Typography>
      <span className={classes.label} />
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  headerConainer: {
    position: 'relative',
    zIndex: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primaryDark,
    padding: 8,
  },
  itemQuantity: {
    fontSize: '1.2rem',
    color: `${theme.palette.text.primaryDark}${opacity[80]}`,
  },
  itemName: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '1.8rem'
    },
  },
  label: {
    zIndex: -1,
    position: 'absolute',
    left: -8,
    top: props => {
      if (props.mode.isMobile) return 33
      if (props.mode.isTablet) return 34
    },
    width: props => {
      if (props.mode.isMobile) return 16
      if (props.mode.isTablet) return 16
    },
    height: props => {
      if (props.mode.isMobile) return 12
      if (props.mode.isTablet) return 12
    },
    marginLeft: props => {
      if (props.mode.isMobile) return -16
      if (props.mode.isTablet) return -16
    },
    borderRadius: '0px 800px 800px 0px',
    backgroundColor: colors.components.label.item,
  },
}))
