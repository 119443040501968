import { createMuiTheme } from '@material-ui/core/styles'
import colors from './colors'
import custom from './colors/custom'

const fontFamily = 'Noto Sans HK, sans-serif, Roboto, Helvetica, Arial, sans-serif'

const theme = {
  shape: {
    borderRadius: 6,
  },
  palette: {
    action: {
      disabled: custom.primary.contrastText || colors.primary.contrastText,
      disabledBackground: custom.primary.disabled || colors.primary.disabled,
    },
    primary: {
      main: custom.primary.main || colors.primary.main,
      disabled: custom.primary.disabled || colors.primary.disabled,
      contrastText: custom.primary.contrastText || colors.primary.contrastText,
      contrastTextHighlight: custom.primary.contrastTextHighlight || colors.primary.contrastTextHighlight,
    },
    secondary: {
      main: custom.secondary.main || colors.secondary.main,
      disabled: custom.secondary.disabled || colors.secondary.disabled,
      contrastText: custom.secondary.contrastText || colors.secondary.contrastText,
      contrastTextHighlight: custom.secondary.contrastTextHighlight || colors.secondary.contrastTextHighlight,
    },
    disabled: {
      main: colors.disabled,
    },
    error: {
      main: colors.error.main,
    },
    warning: {
      main: colors.warning.main,
    },
    info: {
      main: colors.info.main,
    },
    text: {
      primary: colors.text.primary.main,
      primaryLight: colors.text.primary.light,
      primaryDark: colors.text.primary.dark,
    },
  },
  overrides: {
    MuiTypography: {
      root: { fontFamily },
      h1: { fontFamily },
      h2: { fontFamily },
      h3: { fontFamily },
      h4: { fontFamily },
      h5: { fontFamily },
      h6: { fontFamily },
      subtitle1: { fontFamily },
      subtitle2: { fontFamily },
      body1: { fontFamily },
      body2: { fontFamily },
      button: { fontFamily },
      caption: { fontFamily },
      overline: { fontFamily },
    },
    MuiButton: {
      root: {
        minWidth: 32,
      },
    },
  },
}

export default createMuiTheme(theme)
