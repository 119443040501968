import { useSelector } from '@/redux'

/**
 * @function
 * @returns {IAppBatchItem | IAppSet}
 */
export function useBatchItemTemp () {
  return useSelector(state => state.batchItemTemp.batchItemTemp)
}

/**
 * @function
 * @returns {IAppBatchItem}
 */
export function useBatchSetItemTemp () {
  return useSelector(state => state.batchItemTemp.batchSetItemTemp)
}
