import produce from 'immer'
import ActionTypes from './ActionTypes'

export const initialFilter = {
  deliveryType: [],
  status: [],
  takeawayStatus: [],
  shippingProvider: [],
}

/** @type {IOrderState} */
export const initialState = {
  orders: [],
  selectedOrderId: null,
  selectedOrderItems: [],
  query: '',
  filter: initialFilter,
}

export default produce(
  /**
   * @param {IOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.UPDATE_ORDERS: {
        const { orders } = action.payload
        if (!orders.find(order => order.id === draft.selectedOrderId)) {
          draft.selectedOrderId = null
        }
        draft.orders = orders
        break
      }
      case ActionTypes.UPDATE_ORDER: {
        const { order } = action.payload
        const index = draft.orders.findIndex(draftOrder => draftOrder.id === order.id)
        if (index >= 0) {
          draft.orders[index] = order
        } else {
          draft.orders.push(order)
        }
        break
      }
      case ActionTypes.SELECT_ORDER: {
        const { orderId } = action.payload
        draft.selectedOrderId = orderId
        break
      }
      case ActionTypes.SELECT_ORDER_ITEM: {
        const { item } = action.payload
        draft.selectedOrderItems.push(item)
        break
      }
      case ActionTypes.DESELECT_ORDER_ITEM: {
        const { index } = action.payload
        draft.selectedOrderItems.splice(index, 1)
        break
      }
      case ActionTypes.RESET_SELECTED_ORDER_ITEMS: {
        draft.selectedOrderItems = []
        break
      }
      case ActionTypes.UPDATE_FILTER: {
        const { filter } = action.payload
        console.log('UPDATE_FILTER', filter)
        draft.filter = filter
        break
      }
      case ActionTypes.UPDATE_QUERY: {
        const { query } = action.payload
        draft.query = query
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
