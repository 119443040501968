import Buttons from '@/components/Buttons'
import Page from '@/components/Page'
import PageContent from '@/components/Page/PageContent'
import PageHeader from '@/components/Page/PageHeader'
import { actions, useDispatch, useSelector } from '@/redux'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AppBar from './AppBar'
import OrderHistory from './OrderHistory'
import EmptyItem from './OrderHistory/EmptyItem'

/**
 * @param {{ page?: 'paid' }} props
 */
export default function History (props) {
  const { page } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const orderHistory = useSelector(state => state.orderHistory)
  const orders = _.filter(orderHistory.orders, order => order.items.length > 0) // 不顯示沒有東西的訂單記錄

  const handleReorder = async () => {
    await dispatch(actions.user.init(undefined, { newUser: true }))
    await dispatch(actions.order.createOrder())
    await dispatch(actions.orderHistory.getOrders())
    history.push('/')
  }

  useEffect(() => {
    if (page === 'paid') return
    dispatch(actions.orderHistory.getOrders())
  }, [])

  switch (page) {
    case 'paid': {
      return (
        <Page>
          <AppBar page={page} hideBackButton />
          <PageHeader title={t('app.history.welcome')} />
          <PageContent>
            {_.isEmpty(orders)
              ? <EmptyItem />
              : _.map(orders, (order, index) => {
                if (order.status === 'paid') {
                  return <OrderHistory key={index} order={order} />
                }
              })}
            <Buttons button={{ text: t('app.history.start_order'), onClick: handleReorder }} />
          </PageContent>
        </Page>
      )
    }
    default: {
      return (
        <Page>
          <AppBar />
          <PageHeader title={t('app.history.order_recent')} />
          <PageContent>
            {!_.isEmpty(orders)
              ? _.map(orders, (order, index) => <OrderHistory key={index} order={order} />)
              : <EmptyItem />}
          </PageContent>
        </Page>
      )
    }
  }
}
