import * as app from './app/actions'
import * as batchItemTemp from './batchItemTemp/actions'
import * as menu from './menu/actions'
import * as merchant from './merchant/actions'
import * as order from './order/actions'
import * as orderBatch from './orderBatch/actions'
import * as orderHistory from './orderHistory/actions'
import * as user from './user/actions'

export default {
  app,
  user,
  merchant,
  menu,
  order,
  orderBatch,
  orderHistory,
  batchItemTemp,
}
