import Alert from '@/components/Alerts/Alert'
import hooks from '@/hooks'
import { actions, useDispatch, useSelector } from '@/redux'
import { Checkbox, List, ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function DeleteSetItemsDialog (props) {
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  const selectedBatch = hooks.order.useSelectedBatch()
  const menuId = useSelector(state => state.app.dialogs.deleteSetItems.menuId)
  const items = _.filter(selectedBatch?.items, item => item.menuId === menuId)
  const defaultSelected = _.reduce(items, (result, item) => {
    result[item.key] = false
    return result
  }, {})
  const [selected, setSelected] = useState(defaultSelected)
  const open = Boolean(useSelector(state => state.app.dialogs.deleteSetItems))
  const handleClose = () => dispatch(actions.app.toggleDialog('deleteSetItems', false))

  const alertConfig = {
    title: '取消套餐餐點',
    message: '選擇要取消的套餐餐點',
    onConfirm: () => {
      const selectedKeys = Object.keys(selected)
      const deleteKeys = _.filter(selectedKeys, key => selected[key])
      for (const key of deleteKeys) {
        dispatch(actions.orderBatch.deleteItem(key))
      }
    },
    children: (
      <List>
        {_.map(items, (item, index) => {
          const isSelected = selected[item.key]
          /* const option = _.isEmpty(item.options)
            ? t('app.common.none')
            : _.map(item.options, option => option.name).join(', ') */
          const setItems = _.isEmpty(item.setItems)
            ? t('app.common.none')
            : _.map(item.setItems, setItem => setItem.name).join(', ')
          return (
            <ListItem
              button
              key={index + JSON.stringify(selected)} // force rerender on selected changed
              onClick={() => setSelected({ ...selected, [item.key]: !selected[item.key] })}
            >
              <Typography className={classes.optionName}>
                {setItems}
              </Typography>
              <ListItemSecondaryAction>
                <Checkbox
                  color='primary'
                  icon={<div />}
                  checkedIcon={<CheckIcon />}
                  classes={{ root: classes.checkbox }}
                  checked={isSelected}
                  onClick={() => setSelected({ ...selected, [item.key]: !selected[item.key] })}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

    ),
  }

  return (
    <Alert open={open} onClose={handleClose} alertConfig={alertConfig} />
  )
}

const useStyles = makeStyles(theme => ({
  optionName: {
    fontSize: '1.6rem',
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}))
