import MenuItemPanelLg from '@/assets/images/menuItem/menu-item-panel--lg.png'
import MenuItemPanelMd from '@/assets/images/menuItem/menu-item-panel--md.png'
import Stepper from '@/components/Stepper'
import constants from '@/constants'
import libs from '@/libs'
import { getMenuImage } from '@/libs/menu'
import { actions, useDispatch } from '@/redux'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

MenuItem.propTypes = {
  menu: PropTypes.object,
  setItemMenu: PropTypes.object,
}

/**
 * @param {{
 * size?: 'sm' | 'md' | 'lg',
 * menu: IAppMenuItem
 * setItemMenu?: IAppMenuItem
 * }} props
 */
export default function MenuItem (props) {
  const { size = 'md', menu, setItemMenu } = props
  const _menu = !_.isEmpty(setItemMenu) ? setItemMenu : menu
  const {
    desc,
  } = menu
  const name = setItemMenu ? setItemMenu.name : menu.name
  const image = libs.cloudinary.transformation(getMenuImage(_menu), 'w_1000') || constants.app.image.EMPTY_IMAGE
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, image })

  const handleShowDetail = () => {
    dispatch(actions.app.toggleAlert({
      dialogProps: { classes: { paper: classes.dialogPaper } },
      title: name,
      message: desc,
      children: (
        <Box
          flexShrink={0}
          display='flex'
          alignItems='center'
          justifyContent='center'
          style={{ marginTop: 16 }}
        >
          {image
            ? <div className={classes.menuItemImage} style={{ width: '60vh' }} />
            : <div className={classes.emptyImage} />}
        </Box>
      ),
    }))
  }

  switch (size) {
    case 'md':
      return (
        <Grid item md={3} className={classes.gridItem}>
          <div className={classes.menuItemContainer}>
            {image
              ? <div className={classes.menuItemImage} onClick={handleShowDetail} />
              : <div className={classes.emptyImage} onClick={handleShowDetail} />}
            <div className={classes.panel}>
              <Stepper size={size} menu={menu} setItemMenu={setItemMenu} disableBackground />
            </div>
          </div>
        </Grid>
      )
    case 'lg':
      return (
        <Grid item md={6} className={classes.gridItem}>
          <div className={classes.menuItemContainer}>
            {image
              ? <div className={classes.menuItemImage} onClick={handleShowDetail} />
              : <div className={classes.emptyImage} onClick={handleShowDetail} />}
            <div className={classes.panel}>
              <Stepper size={size} menu={menu} setItemMenu={setItemMenu} disableBackground />
            </div>
          </div>
        </Grid>
      )
    default:
      break
  }
}
const useStyles = makeStyles(theme => ({
  gridItem: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  menuItemContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  panel: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    // width: props => props.size === 'lg' && '100%',
    width: '100%',
    backgroundImage: props => {
      if (props.size === 'md') return `url(${MenuItemPanelMd})`
      if (props.size === 'lg') return `url(${MenuItemPanelLg})`
    },
  },
  emptyImage: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%', // aspect ratio 1:1
    backgroundColor: theme.palette.grey[200],
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      opacity: 0.15,
      backgroundImage: `url(${constants.app.image.EMPTY_IMAGE})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  menuItemImage: {
    width: '100%',
    paddingTop: '100%',
    backgroundImage: props => {
      const url = libs.cloudinary.transformation(props.image, `w_${constants.app.image['MENU/IMAGE_WIDTH']}`)
      return `url(${url})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
  },
}))
