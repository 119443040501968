import { actions, useDispatch, useSelector } from '@/redux'
import { Divider, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SettingListItem from './SettingListItem'

export default function MenuVariant (props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const variant = useSelector(state => state.app.setting.menu.variant)?.toUpperCase()
  const [anchorEl, setAnchorEl] = React.useState()
  const handleOpen = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <SettingListItem onClick={(e) => handleOpen(e)}>
        <ListItemText primary={t('app.setting.menu_variant')} />
        <ListItemSecondaryAction>
          <Typography>{variant}</Typography>
          <Menu open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
              value='row'
              onClick={() => {
                dispatch(actions.app.updateSetting(['menu', 'variant'], 'row'))
                handleClose()
              }}
            >Row
            </MenuItem>
            <MenuItem
              value='card'
              onClick={() => {
                dispatch(actions.app.updateSetting(['menu', 'variant'], 'card'))
                handleClose()
              }}
            >Card
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </SettingListItem>
      <Divider />
    </>
  )
}
