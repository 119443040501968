
import { useSelector } from '@/redux'

/**
 * @returns {IAppOrder?}
 */
export function useSelectedOrder () {
  return useSelector(state => state.order.selectedOrder)
}

/**
 * @returns {IAppOrderBatch}
 */
export function useSelectedBatch () {
  return useSelector(state => state.orderBatch.selectedBatch)
}

/**
 * @returns {[IAppBatchItem, IAppBatchItem]}
 */
export function useSelectedBatchItem () {
  const selectedBatch = useSelectedBatch()
  const selectedItemKey = useSelector(state => state.orderBatch.selectedItemKey)
  const selectedSetItemKey = useSelector(state => state.orderBatch.selectedSetItemKey)
  const selectedItem = selectedBatch?.items.find(item => item.key === selectedItemKey)
  const selectedSetItem = selectedItem?.setItems?.find(setItem => setItem.key === selectedSetItemKey)
  return [selectedItem, selectedSetItem]
}

export default {
  useSelectedOrder,
  useSelectedBatch,
  useSelectedBatchItem,
}
