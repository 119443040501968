import dimorderApi from '@/libs/api/dimorder'
import { parseCategories } from '@/libs/menu'
import { getSelectedBatchItem } from '@/libs/order'
import * as _ from 'lodash'
import actions from '../actions'
import ActionTypes from './ActionTypes'

export function init () {
  return async (dispatch, getState) => {
    await dispatch(fetchCategories())
    dispatch(selectDefaultCategory())
    setInterval(() => {
      dispatch(fetchCategories())
    }, 5 * 60 * 1000) // load every 5 minutes
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

export function selectDefaultCategory () {
  return (dispatch, getState) => {
    const { selectedCategory, rootCategory, rootSetCategory } = getState().menu
    if (selectedCategory) {
      // 有已選的分類，重新選擇新拿到的 category
      const category = getState().menu.categories[selectedCategory.id]
      dispatch(actions.menu.selectCategory(category))
    } else {
      // 沒有已選的分類時
      if (rootCategory.categories.length > 0) {
        // 有單點分類就先選單點分類的第一個
        dispatch(actions.menu.selectCategory(rootCategory.categories[0]))
      } else if (rootSetCategory.categories.length > 0) {
        // 沒有單點分類，有套餐分類就先選套餐分類的第一個
        dispatch(actions.menu.selectCategory(rootSetCategory.categories[0]))
      }
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchCategories () {
  return async (dispatch, getState) => {
    const merchantId = getState().merchant.data.id
    const categories = await dimorderApi.category.getCategories(merchantId)

    // formate categories, create root category, categoriesMap
    const [categoriesMap, rootCategory, rootSetCategory, menus, sets] = await parseCategories(merchantId, categories)

    dispatch({
      type: ActionTypes.UPDATE_CATEGORIES,
      payload: {
        rootCategory: rootCategory,
        rootSetCategory: rootSetCategory,
        categories: categoriesMap,
        menus: menus,
        sets: sets,
      },
    })

    // 如出現已選擇但已經沒有提供的分類，select default
    if (_.get(getState().menu.selectedCategory, 'id')) {
      if (
        !rootCategory.categories.map(o => o.id).includes(
          getState().menu.selectedCategory.id,
        )
      ) {
        if (!getState().menu.selectedCategory.isSet) {
          dispatch(selectCategory(rootCategory.categories[0]))
        }
      }
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updatePictureMenuMode (isPictureMode) {
  return (dispatch, getState) => {
    const rootCategory = getState().menu.categories.ROOT
    if (isPictureMode) {
      dispatch(selectCategory(rootCategory.categories[0]))
    }
    if (!isPictureMode) {
      dispatch(selectCategory(rootCategory))
    }
    dispatch(actions.orderBatch.deselectItems())
    dispatch({
      type: ActionTypes.UPDATE_PICTURE_MODE,
      payload: { isPictureMode },
    })
  }
}

export function updateBatchListExpand (isBatchListExpand) {
  return {
    type: ActionTypes.UPDATE_BATCH_LIST_EXPAND,
    payload: { isBatchListExpand },
  }
}

/**
 * @param {boolean} isSearchExpanded
 */
export function updateSearchExpanded (isSearchExpanded) {
  return {
    type: ActionTypes.UPDATE_SEARCH_EXPAND,
    payload: { isSearchExpanded },
  }
}

/**
 * @param {string} searchText
 */
export function updateSearchText (searchText) {
  return {
    type: ActionTypes.UPDATE_SEARCH_TEXT,
    payload: { searchText },
  }
}

export function resetSearchInput () {
  return (dispatch, getState) => {
    dispatch(updateSearchText(''))
    dispatch(updateSearchExpanded(false))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function swithToSetCategories () {
  return (dispatch, getState) => {
    const rootSetCategory = getState().menu.rootSetCategory
    dispatch(selectCategory(rootSetCategory.categories[0]))
    dispatch(actions.orderBatch.deselectItems())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function swithToSingleCategories () {
  return (dispatch, getState) => {
    const rootCategory = getState().menu.rootCategory
    dispatch(selectCategory(rootCategory.categories[0]))
    dispatch(actions.orderBatch.deselectItems())
  }
}

/**
 * @param {IAppCategory} category
 * @returns {ThunkFunction}
 */
export function selectCategory (category) {
  return (dispatch, getState) => {
    const [selectedItem] = getSelectedBatchItem()
    if (!selectedItem) {
      dispatch(selectSet(null))
      dispatch(selectSetStep(null))
    } else if (selectedItem.isSet) {
      dispatch(actions.orderBatch.deselectItems())
    }
    dispatch({
      type: ActionTypes.SELECT_CATEGORY,
      payload: { category },
    })
  }
}

/**
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function selectSet (set) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_SET,
      payload: { set },
    })
  }
}

/**
 * @param {IAppSetStep} setStep
 * @returns {ThunkFunction}
 */
export function selectSetStep (setStep) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_SET_STEP,
      payload: { setStep },
    })
  }
}
