import Page from '@/components/Page'
import PageContent from '@/components/Page/PageContent'
import PageHeader from '@/components/Page/PageHeader'
import hooks from '@/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from './AppBar'
import CheckoutItemList from './CheckoutItemList'
import Footer from './Footer'

export default function Checkout (props) {
  const { t } = useTranslation()
  const selectedBatch = hooks.order.useSelectedBatch()

  return (
    <Page>
      <AppBar />
      <PageHeader title={t('app.checkout.order_table', { table: selectedBatch?.table })} />
      <PageContent>
        <CheckoutItemList />
      </PageContent>
      <Footer />
    </Page>
  )
}
