import dimorderApi from '@/libs/api/dimorder'
import * as _ from 'lodash'
import actions from '../actions'
import ActionTypes from './ActionTypes'
import { initialFilter } from './reducer'
/**
 * @returns {ThunkFunction}
 */
export function init () {
  return (dispatch, getState) => {
    dispatch(getOrders())
    dispatch(startOrdersUpdater())
  }
}

/**
 * @returns {ThunkFunction}
 */
export function startOrdersUpdater () {
  return (dispatch, getState) => {
    // TODO: subscript websocket to update order
    setInterval(() => {
      dispatch(getOrders())
    }, 10 * 1000)
  }
}

/**
 * 更新單個 order
 * @param {IAppOrder} appOrder app 格式的 order
 * @returns {ThunkFunction}
 */
export function updateOrder (appOrder) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ORDER,
      payload: { order: appOrder },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
// ! DIFF:
export function getOrders (isFilteredByMe = false) {
  return async (dispatch, getState) => {
    const merchantId = getState().merchant.data.id
    const { table } = getState().app
    const { selectedOrder } = getState().order
    const status = 'pending'
    const orders = await dimorderApi.order.getOrders({
      merchantId,
      isFilteredByMe,
      table,
      status,
    })
    if (orders.length && !(orders.some(o => o.id === _.get(selectedOrder, 'id')))) {
      dispatch(actions.order.selectOrder(orders[0]))
    }
    dispatch({
      type: ActionTypes.UPDATE_ORDERS,
      payload: { orders },
    })
  }
}

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrder (orderId) {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    if (selectedOrderId !== orderId) {
      dispatch(resetSelectedOrderItems())
    }
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { orderId },
    })
  }
}

/**
 * 選擇/反選 batch item
 * @param {IAppBatchItem} item
 * @returns {ThunkFunction}
 */
export function toggleSelectItem (item) {
  return (dispatch, getState) => {
    const selectedItems = getState().orderHistory.selectedOrderItems
    const existsItemIndex = selectedItems.findIndex(selectedItem => selectedItem.id === item.id)
    if (existsItemIndex >= 0) {
      dispatch({
        type: ActionTypes.DESELECT_ORDER_ITEM,
        payload: { index: existsItemIndex },
      })
    } else {
      dispatch({
        type: ActionTypes.SELECT_ORDER_ITEM,
        payload: { item },
      })
    }
  }
}

/**
 * 清除選擇的 batch item
 * @returns {ThunkFunction}
 */
export function resetSelectedOrderItems () {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_SELECTED_ORDER_ITEMS,
    })
  }
}

/**
 * 套用篩選
 * @returns {ThunkFunction}
 */
export function applyFilter (filter) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter },
    })
  }
}

/**
 * 清除清除篩選
 * @returns {ThunkFunction}
 */
export function resetFilter () {
  return (dispatch, getState) => {
    console.log('resetFiler', initialFilter)
    dispatch({
      type: ActionTypes.UPDATE_FILTER,
      payload: { filter: initialFilter },
    })
  }
}

/**
 * 更改搜尋字串
 * @returns {ThunkFunction}
 */
export function updateQuery (query) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_QUERY,
      payload: { query },
    })
  }
}

/**
 * 取消選擇的餐點
 * @returns {ThunkFunction}
 */
export function cancelSelectedOrderItem () {
  return async (dispatch, getState) => {
    const selectedOrderId = getState().orderHistory.selectedOrderId
    const selectedItems = getState().orderHistory.selectedOrderItems
    const itemIds = selectedItems.map(item => item.id)

    const promises = itemIds.map(itemId => {
      return dimorderApi.order.cancelOrderItem(selectedOrderId, itemId)
    })
    await Promise.all(promises)
    const newOrders = await dimorderApi.order.getOrders({
      id: selectedOrderId,
    })
    if (newOrders?.[0]) {
      dispatch(updateOrder(newOrders[0]))
    }
  }
}
