import hooks from '@/hooks'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function EmptyItem (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  return (
    <Paper>
      <Typography className={classes.emptyItemText}>
        {t('app.checkout.order_empty')}
      </Typography>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  emptyItemText: {
    fontSize: '1.4rem',
    padding: props => {
      if (props.mode.isMobile) return '12px 16px'
      if (props.mode.isTablet) return '24px 32px'
    },
  },
}))
