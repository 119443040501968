import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Div100vh from 'react-div-100vh'

export default function Page (props) {
  const { children } = props
  const classes = useStyles(props)

  return (
    <Div100vh className={classes.pageContainer}>
      {children}
    </Div100vh>
  )
}

const useStyles = makeStyles(theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
