import PriceLabel from '@/components/PriceLabel'
import hooks from '@/hooks'
import libs from '@/libs'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ order: IAppOrder }} props
 */
export default function Total (props) {
  const { /* items, */ total, surcharge, surchargeTotal } = props.order
  const mode = hooks.app.useMode()
  const theme = useTheme()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  const selectedOrder = hooks.order.useSelectedOrder()
  const selectedBatch = hooks.order.useSelectedBatch()
  const totalQuantities = _.sumBy([...selectedOrder?.batches, selectedBatch], (batch) => {
    const { quantity } = libs.order.calculateDisplayBatchTotal(batch, selectedOrder)
    return quantity
  })
  return (
    <Box className={classes.totalContainer}>
      {
        Boolean(surchargeTotal) && (
          <Box className={classes.surchargeContainer}>
            <Typography className={clsx(classes.surcharge, classes.surchargeTitle)}>
              {t('app.common.item.surcharge', { percent: surcharge.percent })}
            </Typography>
            <Typography className={classes.surcharge}>{`$${surchargeTotal}`}</Typography>
          </Box>
        )
      }
      <Typography className={classes.totalQuantity}>
        {t('app.common.item.total_quantity', { quantity: totalQuantities })}
      </Typography>
      <PriceLabel
        price={total}
        fontSize='2.2rem'
        signSize='1.4rem'
        color={theme.palette.text.primary}
      />
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  totalContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '8px 0px',
    padding: 8,
  },
  totalQuantity: {
    fontSize: '1.4rem',
    color: theme.palette.text.primaryLight,
  },
  surchargeContainer: {
    flexShrink: 0,
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    padding: '4px 0px',
    margin: '4px 0px',
  },
  surcharge: {
    color: theme.palette.text.primaryLight,
    fontSize: '1.2rem',
  },
  surchargeTitle: {
    marginRight: 40,
  },
}))
