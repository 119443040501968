import hooks from '@/hooks'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { List, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import EmptyTable from './EmptyTable'
import TableItem from './TableItem'

/**
 * @param {{
 * title: string,
 * group: string[],
 * }} props
 */
export default function TableGroup (props) {
  const { title, group } = props
  const mode = hooks.app.useMode()
  const selectedTable = useSelector(state => state.app.table)
  const classes = useStyles({ ...props, mode })

  return (
    <Paper className={classes.tableGroupContainer}>
      <Typography variant='h4' className={classes.title}>{title}</Typography>
      <List>
        {
          !_.isEmpty(group)
            ? _.map(group, (table, index) => {
              const isSelected = table === selectedTable
              return <TableItem key={index} table={table} selected={isSelected} />
            })
            : <EmptyTable />
        }
      </List>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  tableGroupContainer: {
    marginBottom: 16,
  },
  title: {
    padding: '8px 16px',
    fontWeight: 'bold',
    fontSize: props => {
      if (props.mode.isMobile) return '2.4rem'
      if (props.mode.isTablet) return '3rem'
    },
    boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    backgroundColor: theme.palette.grey[200],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
}))
