import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @param {{
 * onClick: function,
 * children: any,
 * }} props
 */
export default function Cart (props) {
  const { onClick, children } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <div className={classes.cartContainer} onClick={onClick}>
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  cartContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px dotted ${theme.palette.text.primaryLight}`,
    paddingLeft: props => props.mode.isMobile ? 16 : 32,
  },
}))
