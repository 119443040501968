import AddIcon from '@/assets/icons/AddIcon'
import MinusIcon from '@/assets/icons/MinusIcon'
import constants from '@/constants'
import hooks from '@/hooks'
import { createBatchSetItem } from '@/libs/order'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import custom from '@/theme/colors/custom'
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const Stepper = React.forwardRef((props, ref) => {
  const {
    min = 0,
    max = constants.app.stepper.MAX,
    size = 'md',
    shadow = false,
    className,
    menu,
    disableBackground = false,
    setItemMenu,
  } = props
  const menuItemQuantities = useSelector(state => state.orderBatch.menuItemQuantities)
  const mode = hooks.app.useMode()
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, size, mode, shadow, disableBackground })
  const count = menuItemQuantities[menu.id] || 0
  const disableMinus = count <= min
  const disableAdd = count >= max
  const selectedBatch = hooks.order.useSelectedBatch()
  const isSet = Boolean(menu.steps)

  const handleClick = (event, num) => {
    event.stopPropagation()
    const isIncrement = num > 0
    const isDecrement = num < 0
    if (isIncrement) {
      if (isSet) {
        dispatch(actions.batchItemTemp.addItem(menu))
        dispatch(actions.app.toggleSetItemDrawer('setItem', true, { setMenu: menu, setItemMenu }))
        if (setItemMenu) {
          // 如果是把 step1 拆開來的套餐
          const hasOptions = !_.isEmpty(setItemMenu.options)
          if (hasOptions) {
            // 如果有選項則打開選項視窗
            dispatch(actions.batchItemTemp.addSetItem(setItemMenu))
            dispatch(actions.app.toggleIModalDialog('setItemOption', true, { setItemMenu }))
            return
          }
          dispatch(actions.batchItemTemp.updateItem(['setItems'], [createBatchSetItem(setItemMenu)]))
        }
      } else {
        if (!_.isEmpty(menu.options)) {
          dispatch(actions.app.toggleDrawer('option'))
        }
        dispatch(actions.orderBatch.addItem(menu))
      }
    }

    if (isDecrement) {
      // 若 items 都沒有選項則直接減一個
      if (!isSet) {
        const items = _.filter(selectedBatch.items, item => item.menuId === menu.id)
        if (!_.find(items, item => !_.isEmpty(item.options))) {
          return dispatch(actions.orderBatch.deleteItem(items[0]?.key))
        }

        // 打開 deleteItemsAlert 選則要刪除的 items
        dispatch(actions.app.toggleDialog('deleteItems', true, { menuId: menu.id }))
      } else {
        dispatch(actions.app.toggleDialog('deleteSetItems', true, { menuId: menu.id }))
      }
    }
  }

  return (
    <div ref={ref} className={clsx(classes.stepperContainer, className)}>
      <IconButton
        disabled={disableMinus}
        classes={{ root: classes.stepperIconButtonRoot }}
        onClick={(event) => handleClick(event, 1 * -1)}
      >
        <MinusIcon
          color='primary'
          strokecolor={
            custom.primary.main
              ? disableMinus ? theme.palette.primary.disabled : theme.palette.primary.main
              : disableMinus ? colors.components.stepper.disabled : colors.components.stepper.main
          }
          className={classes.stepperIcon}
          classes={{ root: classes.stepperIconRoot }}
        />
      </IconButton>

      <Typography className={classes.stepperCount}>
        {count}
      </Typography>

      <IconButton
        disabled={disableAdd}
        classes={{ root: classes.stepperIconButtonRoot }}
        onClick={(event) => handleClick(event, 1)}
      >
        <AddIcon
          strokecolor={
            custom.primary.main
              ? disableAdd ? theme.palette.primary.disabled : theme.palette.primary.main
              : disableAdd ? colors.components.stepper.disabled : colors.components.stepper.main
          }
          className={classes.stepperIcon}
          classes={{ root: classes.stepperIconRoot }}
        />
      </IconButton>
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  stepperContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props => !props.disableBackground && theme.palette.common.white,
    borderRadius: 800,
    boxShadow: props => props.shadow && '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  stepperIcon: {
    width: props => {
      if (props.mode.isMobile) {
        if (props.size === 'sm') return 24
        if (props.size === 'md') return 26
        if (props.size === 'lg') return 32
      }
      if (props.mode.isTablet) {
        if (props.size === 'sm') return 28
        if (props.size === 'md') return 42
        if (props.size === 'lg') return 48
      }
    },
    height: props => {
      if (props.mode.isMobile) {
        if (props.size === 'sm') return 24
        if (props.size === 'md') return 26
        if (props.size === 'lg') return 32
      }
      if (props.mode.isTablet) {
        if (props.size === 'sm') return 28
        if (props.size === 'md') return 42
        if (props.size === 'lg') return 48
      }
    },
  },
  stepperIconRoot: {
    fill: colors.transparent,
  },
  stepperIconButtonRoot: {
    padding: 6,
  },
  stepperCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: props => {
      if (props.mode.isMobile) return 16
      if (props.mode.isTablet) return 24
    },
    fontSize: props => {
      if (props.mode.isMobile) {
        if (props.size === 'sm') return '1.6rem'
        if (props.size === 'md') return '1.6rem'
        if (props.size === 'lg') return '1.8rem'
      }
      if (props.mode.isTablet) {
        if (props.size === 'sm') return '1.8rem'
        if (props.size === 'md') return '2.6rem'
        if (props.size === 'lg') return '3rem'
      }
    },
    fontWeight: 500,
    fontFamily: 'sans-serif',
    color: theme.palette.text.primaryDark,
  },
  selected: {
    color: 'red',
  },
}))

Stepper.propTypes = {
  // count: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  // size: PropTypes.string,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  menu: PropTypes.object,
  disableBackground: PropTypes.bool,
}

export default Stepper
