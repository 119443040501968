import CategoryBar from '@/components/CategoryBar'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import React from 'react'
import Content from './Content'

export default function Confirmation (props) {
  return (
    <Page>
      <Navbar />
      <CategoryBar />
      <Content />
    </Page>
  )
}
