import hooks from '@/hooks'
import { isOptionsComplete } from '@/libs/order'
import { actions, useDispatch } from '@/redux'
import orderappLibs from '@dimorder/orderapp-lib/dist/libs'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import DrawerHeader from './DrawerHeader'
import OptionGroup from './OptionGroup'
import SubmitButton from './SubmitButton'

export default function OptionDrawer (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const dispatch = useDispatch()
  const selectedOrder = hooks.order.useSelectedOrder()
  const [selectedItem] = hooks.order.useSelectedBatchItem()
  const menu = hooks.menu.useFindMenu(selectedItem?.menuId)
  const toggleDrawer = () => dispatch(actions.app.toggleDrawer('option'))

  if (!selectedItem) return null
  const calculatedItem = orderappLibs.calculateBatchItem(selectedItem, selectedOrder)
  const isComplete = isOptionsComplete(selectedItem, menu)

  return (
    <div className={classes.drawerContent}>
      <DrawerHeader name={menu.name} total={calculatedItem.total} />
      <div className={classes.options}>
        {_.map(menu?.options, (optionGroup, index) => {
          return <OptionGroup key={index} optionGroup={optionGroup} />
        })}
      </div>
      <SubmitButton disabled={!isComplete} onClick={toggleDrawer} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawerContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    padding: props => {
      if (props.mode.isMobile) return '32px 16px'
      if (props.mode.isTablet) return '32px 24px'
    },
  },
  options: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    margin: '8px 0px',
  },
}))
