import GroupHeader from '@/components/drawers/StepDrawer/GroupHeader'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

StepGroupHeader.propTypes = {
  group: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IAppSetStep
 * }} props
 */
export default function StepGroupHeader (props) {
  const { group } = props
  const { t } = useTranslation()
  const renderMinMaxHint = () => {
    if (group?.min === 1 && group?.max === 1) {
      return <Typography component='span'>{t('app.common.item.option.min1max1')}</Typography>
    }
    return (
      <>
        {group?.min === 0 && <Typography component='span'>{t('app.common.item.option.min0')}</Typography>}
        {group?.min > 0 && <Typography component='span'>{t('app.common.item.option.min', { min: group?.min })}</Typography>}
        {group?.max > 0 && <Typography component='span'>{t('app.common.item.option.max', { max: group?.max })}</Typography>}
      </>
    )
  }

  return (
    <GroupHeader
      title={group.name}
      hint={renderMinMaxHint()}
    />
  )
}
