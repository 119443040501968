import { useSelector } from '@/redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export function useMenus () {
  return useSelector(state => state.menu.menus)
}

export function useFindMenu (menuId) {
  const menus = useMenus()
  return _.find(menus, menu => menu.id === menuId)
}

export function useSold (menu, merchant) {
  return menu?.sold || (merchant.setting.inventory && !menu?.inventory)
}

export function useSingleCategories () {
  return useSelector(state => state.menu.rootCategory.categories)
}

export function useSetCategories () {
  return useSelector(state => state.menu.rootSetCategory.categories)
}

export function useCategories () {
  const { t } = useTranslation()
  const menus = useMenus()
  const promotedMenus = menus ? _.filter(menus, menu => menu.promoted) : []
  const setCategories = useSetCategories()
  const singleCategories = useSingleCategories()
  const categories = (setCategories && singleCategories) ? [...setCategories, ...singleCategories] : []
  const promotedCategory = {
    id: 'promoted-category',
    name: t('app.home.category_bar.promoted'),
    set: [],
    categories: [],
    menus: promotedMenus,
  }
  // 如果有推薦商品才顯示本店推薦
  if (!_.isEmpty(promotedCategory.menus)) {
    return [promotedCategory, ...categories]
  }
  return categories
}

export function useSelectedCategory () {
  return useSelector(state => state.menu.selectedCategory)
}

export default {
  useMenus,
  useFindMenu,
  useSingleCategories,
  useSetCategories,
  useCategories,
  useSelectedCategory,
}
