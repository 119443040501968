import CategoryBar from '@/components/CategoryBar'
import Navbar from '@/components/Navbar'
import Page from '@/components/Page'
import React from 'react'
import Content from './Content'
import Footer from './Footer'

export default function Landing (props) {
  return (
    <Page>
      <Navbar />
      <CategoryBar />
      <Content />
      <Footer />
    </Page>
  )
}
