import produce from 'immer'
import ActionTypes from './ActionTypes'

/** @type {IMerchant} */
const initialMerchant = {}

/** @type {IMerchantState} */
export const initalState = {
  isInit: false,
  data: initialMerchant,
}

export default produce(
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.FETCH_MERCHANT: {
        const { merchant } = action.payload
        draft.data = merchant
        break
      }
      default:
        break
    }
  },
  initalState,
)
