import PriceLabel from '@/components/PriceLabel'
import Stepper from '@/components/Stepper'
import hooks from '@/hooks'
import { findSeparatedStep, getSetItemMenu } from '@/libs/menu'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import Header from './Header'
import Options from './Options'
import SetItems from './SetItems'

/**
 * @param {{
 * menuId: string,
 * batchItems: IAppBatchItem[]
 * }} props
 */
export default function CheckoutItem (props) {
  const { menuId, batchItems } = props
  const classes = useStyles(props)
  const menus = useSelector(state => state.menu.menus)
  const sets = useSelector(state => state.menu.sets)
  const mode = hooks.app.useMode()
  const menu = menus[menuId] ?? sets[menuId]
  // const setItems = menu.setItems
  const total = _.sumBy(batchItems, 'total')
  const itemOptions = batchItems?.map(batchItem => batchItem.options)
  const itemSetItems = batchItems?.map(batchItem => batchItem.setItems)
  const separatedStep = findSeparatedStep(menu)
  const setItemMenu = getSetItemMenu(menu, separatedStep)
  return (
    <main className={classes.checkoutItemContainer}>
      <Header name={menu.name} quantity={batchItems.length} />
      <section className={classes.sectionMiddle}>
        <div className={classes.sectionInner}>
          <PriceLabel
            price={total}
            fontSize={mode.isMobile ? '2rem' : '2.2rem'}
            signSize={mode.isMobile ? '1.4rem' : '1.6rem'}
          />
        </div>
        <div className={classes.sectionInner}>
          <Stepper size='sm' menu={menu} setItemMenu={setItemMenu ? setItemMenu[0] : ''} className={classes.stepper} />
          {/* <RemarkButton menu={menu} /> */}
        </div>
        <Options itemOptions={itemOptions} />
        <SetItems setItems={itemSetItems} />
      </section>
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  checkoutItemContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    padding: 8,
    margin: '0px 16px',
  },
  sectionMiddle: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 4,
  },
  sectionInner: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
  },
  stepper: {
    marginRight: -8,
  },
}))
