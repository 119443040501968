import AppBar from '@/components/AppBar'
import Page from '@/components/Page'
import PageContent from '@/components/Page/PageContent'
import PageHeader from '@/components/Page/PageHeader'
import { useSelector } from '@/redux'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TableGroup from './TableGroup'

export default function ChangeTable (props) {
  const history = useHistory()
  const selectedTable = useSelector(state => state.app.table)
  const tables = useSelector(state => state.merchant.data?.tables)
  const tableGroups = useSelector(state => state.merchant.data?.tableGroups)
  const ungroupedTables = _.difference(tables, _.flatMap(tableGroups))
  const { t } = useTranslation()
  const table = useSelector(state => state.app.table) || t('app.common.unset')

  return (
    <Page>
      <AppBar handleBack={selectedTable ? () => history.goBack() : undefined} />
      <PageHeader title={selectedTable ? t('app.change_table.current_table', { table }) : t('app.change_table.select_table')} />
      <PageContent>
        {/* 桌位分組 */}
        {_.map(tableGroups, (group, key) => {
          return <TableGroup key={key} title={key} group={group} />
        })}
        {/* 如果有未分組的座位 */}
        {!_.isEmpty(ungroupedTables) && (
          <TableGroup title={t('app.change_table.ungrouped_tables')} group={ungroupedTables} />
        )}
      </PageContent>
    </Page>
  )
}
