export default {
  UPDATE_ORDERS: 'ORDER_HISTORY/UPDATE_ORDERS',
  UPDATE_ORDER: 'ORDER_HISTORY/UPDATE_ORDER',
  SELECT_ORDER: 'ORDER_HISTORY/SELECT_ORDER',
  SELECT_ORDER_ITEM: 'ORDER_HISTORY/SELECT_ORDER_ITEM',
  DESELECT_ORDER_ITEM: 'ORDER_HISTORY/DESELECT_ORDER_ITEM',
  RESET_SELECTED_ORDER_ITEMS: 'ORDER_HISTORY/RESET_SELECTED_ORDER_ITEMS',
  UPDATE_FILTER: 'ORDER_HISTORY/UPDATE_FILTER',
  UPDATE_QUERY: 'ORDER_HISTORY/UPDATE_QUERY',
}
