export default {
  INIT: 'APP/INIT',
  CHECK_UPDATE: 'APP/CHECK_UPDATE',
  UPDATE_LOADING: 'APP/UPDATE_LOADING',
  UPDATE_IS_LOGIN_SETTING: 'APP/UPDATE_IS_LOGIN_SETTING',
  UPDATE_MODE: 'APP/UPDATE_MODE',
  UPDATE_TABLE: 'APP/UPDATE_TABLE',
  UPDATE_LANG: 'APP/UPDATE_LANG',
  TOGGLE_ALERT: 'APP/TOGGLE_ALERT',
  TOGGLE_DRAWER: 'APP/TOGGLE_DRAWER',
  TOGGLE_SETITEM_DRAWER: 'APP/TOGGLE_SETITEM_DRAWER',
  TOGGLE_DIALOG: 'APP/TOGGLE_DIALOG',
  TOGGLE_IMODAL_DIALOG: 'APP/TOGGLE_IMODAL_DIALOG',
  UPDATE_SETTING: 'APP/UPDATE_SETTING',
  UPDATE_NEW_VERSION_AVAILABLE: 'APP/UPDATE_NEW_VERSION_AVAILABLE',
}
