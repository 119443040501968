import Badge from '@/components/Footer/Badge'
import SubmitButtonComponent from '@/components/Footer/SubmitButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

/**
 * @param {{
 * totalQuantities: number,
 * className?: string,
 * }} props
 */
export default function SubmitButton (props) {
  const { totalQuantities, className } = props
  const history = useHistory()
  const { t } = useTranslation()
  const isDisabled = !totalQuantities
  const handleClick = () => history.push('/checkout')
  const buttonText = isDisabled
    ? t('app.home.footer.checkout_immediate').toUpperCase()
    : t('app.home.footer.checkout').toUpperCase()

  return (
    <SubmitButtonComponent
      className={className}
      text={buttonText}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <Badge number={totalQuantities} />
    </SubmitButtonComponent>
  )
}
