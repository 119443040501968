import AppBarComponent from '@/components/AppBar'
import hooks from '@/hooks'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

/**
 * @param {{
 * page?: 'paid'
 * }} props
 */
export default function AppBar (props) {
  const { page } = props
  const mode = hooks.app.useMode()
  const history = useHistory()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleBack = () => history.push('/')
  const tabs = [
    t('app.history.appbar.tabs.mine'),
    t('app.history.appbar.tabs.all'),
  ]
  const rightContent = _.map(tabs, (tab, index) => {
    const isSelected = selectedIndex === index
    return (
      <Typography
        key={index}
        className={clsx(classes.tab, { [classes.tabSelected]: isSelected })}
        onClick={() => setSelectedIndex(index)}
      >
        {tab}
      </Typography>
    )
  })

  // paid 頁面不需要 backButton 與 tabs
  if (page === 'paid') {
    return <AppBarComponent />
  }

  return (
    <AppBarComponent handleBack={handleBack} right={rightContent} />
  )
}

const useStyles = makeStyles(theme => ({
  tab: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    margin: '0px 6px',
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '2rem'
    },
    color: theme.palette.common.white,
  },
  tabSelected: {
    color: theme.palette.primary.contrastTextHighlight,
    boxShadow: `${theme.palette.primary.contrastTextHighlight} 0px -3px 0px inset`,
  },
}))
