import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import OptionGroupHeader from './OptionGroupHeader'
import OptionItemList from './OptionItemList'

SetOptionGroup.propTypes = {
  group: PropTypes.object,
  menu: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IMenuOptionGroup
 * menu: IAppMenuItem
 * }} props
 * @returns {Node}
 */
export default function SetOptionGroup (props) {
  const { group, menu } = props
  const list = group.options
  const classes = useStyles(props)

  return (
    <li className={classes.container}>
      <OptionGroupHeader group={group} />
      <OptionItemList
        group={group}
        list={list}
        max={5}
        menu={menu}
      />
    </li>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))
