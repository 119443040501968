import { actions, useDispatch, useSelector } from '@/redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ lang: string }} props
 */
export default function UpdateDialog () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const open = useSelector(state => state.app.dialogs.updateVersion)
  const handleUpdate = () => {
    dispatch(actions.app.toggleDialog('updateVersion'))
    window.location.reload()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{t('app.dialog.update.title')}</DialogTitle>
      <DialogActions>
        <Button onClick={handleUpdate} color='primary'>
          {t('app.dialog.update.update')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
