import { Divider, IconButton, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SettingListItem from './SettingListItem'

export default function ChangeTable (props) {
  const history = useHistory()
  const { t } = useTranslation()
  const handleClick = () => history.push('/changeTable')

  return (
    <>
      <SettingListItem onClick={handleClick}>
        <ListItemText primary={t('app.setting.change_table')} />
        <ListItemSecondaryAction>
          <IconButton edge='end' onClick={handleClick}>
            <ChevronRightIcon fontSize='large' />
          </IconButton>
        </ListItemSecondaryAction>
      </SettingListItem>
      <Divider />
    </>
  )
}
