import produce from 'immer'
import ActionTypes from './ActionTypes'

/** @type {IAppOrderBatch} */
export const initBatch = {
  orderId: null,
  orderSerial: null,
  batchId: null,
  index: 0,
  table: null,
  items: [],
  remark: '',
  takeout: false, // 外帶
  delivery: false, // 是否, pickupp 外送
  storeDelivery: false, // 是否外送訂單
  shop: false, // 是否網店訂單
  takeaway: false, // 是否外帶訂單
  status: null,
}

/** @type {IAppOrder} */
export const initOrder = {
  id: null,
  deliveryType: 'takeaway',
  table: null,
  customers: 1,
  batches: [],
  surcharge: { percent: 0, amount: 0 },
  originalTotal: 0,
  discountTotal: 0,
  surchargeTotal: 0,
  total: 0,
  modifiers: [],
  payments: [],
}

/** @type {IOrderState} */
export const initialState = {
  selectedOrder: null,
  requestServiceTime: null,
}

export default produce(
  /**
   * @param {IOrderState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.SELECT_ORDER: {
        const { order } = action.payload
        draft.selectedOrder = order
        break
      }
      case ActionTypes.UPDATE_REQUEST_SERVICE_TIME: {
        const { time } = action.payload
        draft.requestServiceTime = time
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
