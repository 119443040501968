import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function SubmitSuccess (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 82 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='41' cy='41' r='38' stroke={strokecolor} strokeWidth='6' />
      <path d='M61.8727 26.8364L33.1727 55.9092L20.1272 42.6943' stroke={strokecolor} strokeWidth='6' strokeLinecap='square' />
    </SvgIcon>
  )
}
