import GroupHeader from '@/components/drawers/StepDrawer/GroupHeader'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

OptionGroupHeader.propTypes = {
  group: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IMenuOptionGroup
 * }} props
 */
export default function OptionGroupHeader (props) {
  const { group } = props
  const { t } = useTranslation()
  const isMultiple = group?.multiple && group?.max !== 1

  const renderMinHint = () => {
    if (group?.min === 0) {
      return <Typography component='span' variant='body2'>{t('app.common.item.option.min0')}</Typography>
    }
    if (isMultiple) {
      if (group?.min > 0) {
        return <Typography component='span' variant='body2'>{t('app.common.item.option.min', { min: group?.min })}</Typography>
      }
    }
  }

  const renderMaxHint = () => {
    if (isMultiple) {
      if (group.max === 0) {
        return <Typography component='span' variant='body2'>{t('app.common.item.option.multiple')}</Typography>
      }
      return <Typography component='span' variant='body2'>{t('app.common.item.option.max', { max: group?.max })}</Typography>
    } else {
      return <Typography component='span' variant='body2'>{t('app.common.item.option.min1max1')}</Typography>
    }
  }

  const renderMinMaxHint = () => {
    return (
      <>
        {renderMaxHint()}
        {renderMinHint()}
      </>
    )
  }

  return (
    <GroupHeader
      title={group.name}
      hint={renderMinMaxHint()}
    />
  )
}
