import hooks from '@/hooks'
import dateLibs from '@/libs/date'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ createdAt: string }} props
 */
export default function TimeLabel (props) {
  const { createdAt } = props
  const mode = hooks.app.useMode()
  const isToday = dateLibs.isToday(createdAt)
  const classes = useStyles({ ...props, mode, isToday })
  const { t } = useTranslation()
  const today = t('app.common.date.today')

  if (isToday) {
    return (
      <Box display='flex' alignItems='center' className={classes.timeLabel}>
        <Typography component='span' color='inherit' className={classes.timeLabelText}>
          {`${today} ${moment(createdAt).format('hh:mm:ss')}`}
        </Typography>
      </Box>
    )
  }
  return (
    <Box display='flex' alignItems='center' className={classes.timeLabel}>
      <Typography component='span' color='inherit' className={classes.timeLabelText}>
        {moment(createdAt).format('YYYY-MM-DD hh:mm:ss')}
      </Typography>
    </Box>
  )
}
const useStyles = makeStyles(theme => ({
  timeLabel: {
    color: theme.palette.common.white,
    backgroundColor: props => {
      if (props.isToday) return colors.components.label.time
      return `${colors.components.label.time}${opacity[50]}`
    },
    minWidth: 160,
    height: 24,
    marginLeft: -24,
    borderRadius: '0px 800px 800px 0px',
  },
  timeLabelText: {
    fontSize: '1.2rem',
    marginLeft: props => {
      if (props.mode.isMobile) return 24
      if (props.mode.isTablet) return 32
    },
  },
}))
