import constants from '@/constants'
import { actions, useDispatch } from '@/redux'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'

/**
 * @param {{
 * category: IAppCategory,
 * expanded?: boolean,
 * selected?: boolean,
 * onClick?: Function,
 * className?: string,
 * }} props
 */
export default function CategoryItem (props) {
  const { category, expanded = false, selected = false, onClick, className } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)

  return (
    <Typography
      id={category.id}
      className={clsx(
        className,
        classes.category,
        { [classes.expanded]: expanded },
        { [classes.selected]: selected },
      )}
      onClick={() => {
        onClick && onClick()
        const scrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'nearest' }
        document.getElementById(category.id)?.scrollIntoView(scrollIntoViewOptions)
        dispatch(actions.menu.selectCategory(category))
        history.push('/')
      }}
    >
      {category.name}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  category: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 60,
    padding: '0px 16px',
    fontSize: '1.4rem',
    color: theme.palette.common.white,
  },
  expanded: {
    justifyContent: 'center',
    height: constants.app.height.CATEGORYBAR,
    padding: 'unset',
    margin: '0px 16px',
  },
  selected: {
    color: theme.palette.primary.contrastTextHighlight,
    boxShadow: `${theme.palette.primary.contrastTextHighlight} 0px -4px 0px inset`,
  },
}))
