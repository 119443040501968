import constants from '@/constants'
import produce from 'immer'
import _ from 'lodash'
import ActionTypes from './ActionTypes'

/** @type {IMode} */
const initialMode = {
  name: '',
  isMobile: false,
  isTablet: false,
}

/** @type {IAlerts} */
const initialAlerts = {

}

/** @type {IModal} */
const initialModal = {
  open: false,
  data: {},
}

/** @type {IDrawers} */
const initialDrawers = {
  option: false,
  setItem: initialModal,
}

/** @type {IDialogs} */
const initalDialogs = {
  changLang: false,
  updateVersion: false,
  serviceRequest: false,
  deleteItems: false,
  deleteSetItems: false,
  inputRemark: false,
  setItemOption: initialModal,
}

/** @type {IAppState} */
export const initialState = {
  isInit: false,
  isLoading: false,
  isLoginSetting: false,
  isNewVersionAvailable: false,
  mode: initialMode,
  lang: 'zh-HK',
  alerts: initialAlerts,
  drawers: initialDrawers,
  dialogs: initalDialogs,
  setting: {
    menu: {
      variant: 'card',
    },
  },
}

export default produce(
  /**
   *  @param {IAppState} draft
   *  @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { isLoading } = action.payload
        draft.isLoading = isLoading
        break
      }
      case ActionTypes.UPDATE_NEW_VERSION_AVAILABLE: {
        const { isNewVersionAvailable } = action.payload
        draft.isNewVersionAvailable = isNewVersionAvailable
        break
      }
      case ActionTypes.UPDATE_IS_LOGIN_SETTING: {
        const { isLoginSetting } = action.payload
        draft.isLoginSetting = isLoginSetting
        break
      }
      case ActionTypes.UPDATE_MODE: {
        let newMode
        const { mode } = action.payload
        switch (mode) {
          case constants.app.mode.MOBILE:
            newMode = { ...initialMode, name: mode, isMobile: true }
            draft.mode = newMode
            break
          case constants.app.mode.TABLET:
            newMode = { ...initialMode, name: mode, isTablet: true }
            draft.mode = newMode
            break
          default:
            break
        }
        break
      }
      case ActionTypes.UPDATE_TABLE: {
        const { table } = action.payload
        draft.table = table
        break
      }
      case ActionTypes.UPDATE_LANG: {
        const { lang } = action.payload
        draft.lang = lang
        break
      }
      case ActionTypes.TOGGLE_ALERT: {
        const { alertConfig, id, open = true } = action.payload
        if (typeof open === 'boolean') {
          draft.alerts[id] = {
            id,
            open,
            alertConfig,
          }
          break
        }
        draft.alerts[id].open = !draft.alerts[id].open
        break
      }
      case ActionTypes.TOGGLE_DRAWER: {
        const { drawer, open } = action.payload
        if (typeof open === 'boolean') {
          draft.drawers[drawer] = open
          break
        }
        draft.drawers[drawer] = !draft.drawers[drawer]
        break
      }
      case ActionTypes.TOGGLE_SETITEM_DRAWER: {
        const { drawer, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.drawers[drawer].open = !draft.drawers[drawer].open
          draft.drawers[drawer].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.drawers[drawer].open = open
        }
        if (!_.isNil(data)) {
          draft.drawers[drawer].data = data
        }
        break
      }
      case ActionTypes.TOGGLE_DIALOG: {
        const { dialog, open, data } = action.payload
        if (data) {
          draft.dialogs[dialog] = data
          break
        }
        if (typeof open === 'boolean') {
          draft.dialogs[dialog] = open
          break
        }
        draft.dialogs[dialog] = !draft.dialogs[dialog]
        break
      }
      case ActionTypes.TOGGLE_IMODAL_DIALOG: {
        const { dialog, open, data } = action.payload
        if (_.isUndefined(open) && _.isUndefined(data)) {
          draft.dialogs[dialog].open = !draft.dialogs[dialog].open
          draft.dialogs[dialog].data = {}
          break
        }
        if (_.isBoolean(open)) {
          draft.dialogs[dialog].open = open
        }
        if (!_.isNil(data)) {
          draft.dialogs[dialog].data = data
        }
        break
      }
      case ActionTypes.UPDATE_SETTING: {
        const { path, setting } = action.payload
        _.set(draft.setting, path, setting)
        break
      }
      default:
        break
    }
  },
  initialState,
)
