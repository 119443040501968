import FooterComponent from '@/components/Footer'
import CustomBBQTabletCart from '@/components/custom/BBQ/Cart'
import CustomBBQSubmitButton from '@/components/custom/BBQ/SubmitButton'
import constants from '@/constants'
import hooks from '@/hooks'
import libs from '@/libs'
import { useSelector } from '@/redux'
import _ from 'lodash'
import Cart from './Cart'
import SubmitButton from './SubmitButton'

export default function Footer (props) {
  const mode = hooks.app.useMode()
  // const menuItemQuantities = useSelector(state => state.orderBatch.menuItemQuantities)
  const selectedOrder = hooks.order.useSelectedOrder()
  const selectedBatch = hooks.order.useSelectedBatch()
  const totalQuantities = selectedOrder && _.sumBy([...selectedOrder.batches, selectedBatch], (batch) => {
    const { quantity } = libs.order.calculateDisplayBatchTotal(batch, selectedOrder)
    return quantity
  })
  const merchantId = useSelector(state => state.merchant.data?.id)

  const renderMiddleContent = () => {
    if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId) && mode.isTablet) {
      return <CustomBBQTabletCart page='checkout' totalQuantities={totalQuantities} />
    }
    return <Cart totalQuantities={totalQuantities} />
  }

  const renderRightContent = () => {
    if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId)) {
      return <CustomBBQSubmitButton page='checkout' />
    }
    return <SubmitButton />
  }

  return (
    <FooterComponent
      middle={renderMiddleContent()}
      right={renderRightContent()}
    />
  )
}
