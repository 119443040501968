import ExpandButton from '@/components/drawers/StepDrawer/ExpandButton'
import GroupItem from '@/components/drawers/StepDrawer/GroupItem'
import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import { Collapse, List } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

OptionItemList.propTypes = {
  group: PropTypes.object,
  list: PropTypes.array,
  max: PropTypes.number,
  menu: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IMenuOptionGroup
 * list: IMenuOptionItem[]
 * max?: Number
 * menu: IAppMenuItem | IAppSet
 * }} props
 * @returns {Node}
 */
export default function OptionItemList (props) {
  const { group, list, max, menu } = props
  const isSet = Boolean(menu.step)
  const dispatch = useDispatch()
  const batchItem = isSet
    ? hooks.batchItemTemp.useBatchSetItemTemp()
    : hooks.batchItemTemp.useBatchItemTemp()
  const options = batchItem.options
  const selectedCount = _.reduce(options, (acc, opt) => {
    const match = opt.optionGroupId === group.id
    return match ? acc + opt.quantity : acc
  }, 0)
  const isMax = group.max > 0 && selectedCount >= group.max
  const isMultiple = group.multiple && group.max !== 1 // group.max === 1 的情況即為單選

  const [expanded, setExpanded] = useState(false)
  const collapsedList = _.take(list, max ?? list.length)
  const remainList = _.drop(list, max)

  // 自動選滿選項群底下的全部選項
  const shouldSelectAll = isMultiple && // 當選項群為複選
    _.size(group.options) <= group.min && // 選項群最少需點數量與底下選項數量相等
    _.every(group.options, option => option.max <= 1) // 底下每個選項皆為單選

  useEffect(() => {
    if (shouldSelectAll) {
      _.forEach(group.options, option => {
        dispatch(actions.batchItemTemp.updateOptions(option, group, isSet))
      })
    }
  }, [])

  const renderGroupItems = (list) => {
    return _.map(list, (option, index) => {
      const isSelected = Boolean(_.find(options, opt => opt.optionItemId === option.id))
      let isDisabled = false

      if (
        isMultiple && ( // 只有在多選要 disabled 其他選項
          shouldSelectAll || // 必須選滿的選項群底下的選項都不能被取消
          (isMax && !isSelected) // 已經點 max 了剩下沒選的選項都要被 disabled
        )
      ) {
        isDisabled = true
      }

      const handleClick = () => {
        if (isDisabled) return
        dispatch(actions.batchItemTemp.updateOptions(option, group, isSet))
      }

      return (
        isMultiple
          ? <GroupItem key={index} variant='radio' item={option} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
          : <GroupItem key={index} variant='radio' item={option} selected={isSelected} onClick={handleClick} />
      )
    })
  }

  return (
    <List>
      {renderGroupItems(collapsedList)}

      <Collapse in={expanded}>
        {renderGroupItems(remainList)}
      </Collapse>

      <Collapse in={!expanded && remainList.length > 0}>
        <ExpandButton
          number={remainList.length}
          onClick={() => setExpanded(true)}
        />
      </Collapse>
    </List>
  )
}
