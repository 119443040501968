
import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * menu: node,
 * content: node
 * }} props
 */
export default function SplitPanel (props) {
  const { menu, content } = props
  const mode = hooks.app.useMode()
  const classes = useStyles(props)

  if (mode.isTablet) {
    return (
      <main className={classes.splitPanelContainer}>
        <section className={clsx(classes.splitPanelSection, classes.splitPanelSectionMenu)}>
          {menu}
        </section>
        <section className={clsx(classes.splitPanelSection, classes.splitPanelSectionContent)}>
          {content}
        </section>
      </main>
    )
  }
  return null
}
const useStyles = makeStyles(theme => ({
  splitPanelContainer: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
  },
  splitPanelSection: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  splitPanelSectionMenu: {
    flexShrink: 0,
    flexDirection: 'column',
    alignItems: 'center',
    width: '20%',
    padding: 16,
    overflowX: 'hidden',
    overflowY: 'auto',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  splitPanelSectionContent: {
    flex: 1,
    flexDirection: 'column',
    padding: 16,
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.secondary.main,
  },
}))
