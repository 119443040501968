import ButtonsComponent from '@/components/Buttons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export default function Buttons (props) {
  const history = useHistory()
  const { t } = useTranslation()
  const buttons = [
    {
      text: t('app.confirmation.buttons.continue_order'),
      onClick: () => history.push('/'),
    },
    {
      text: t('app.confirmation.buttons.view_history'),
      onClick: () => history.push('/history'),
    },
  ]

  return <ButtonsComponent buttons={buttons} />
}
