import { combineReducers } from 'redux'
import app from './app/reducer'
import batchItemTemp from './batchItemTemp/reducer'
import menu from './menu/reducer'
import merchant from './merchant/reducer'
import order from './order/reducer'
import orderBatch from './orderBatch/reducer'
import orderHistory from './orderHistory/reducer'
import user from './user/reducer'

export default combineReducers({
  app,
  user,
  merchant,
  menu,
  order,
  orderBatch,
  orderHistory,
  batchItemTemp,
})
