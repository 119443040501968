import { Divider, IconButton, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import React from 'react'
import { useTranslation } from 'react-i18next'
import VConsole from 'vconsole'
import SettingListItem from './SettingListItem'

export default function (props) {
  const { t } = useTranslation()
  const handleClick = () => {
    const a = new VConsole()
    console.log(a)
  }

  return (
    <>
      <SettingListItem onClick={handleClick}>
        <ListItemText primary={t('vconsole')} />
        <ListItemSecondaryAction>
          <IconButton edge='end' onClick={handleClick}>
            <ArrowRightIcon fontSize='large' />
          </IconButton>
        </ListItemSecondaryAction>
      </SettingListItem>
      <Divider />
    </>
  )
}
