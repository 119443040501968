import constants from '@/constants'
import hooks from '@/hooks'
import opacity from '@/theme/opacity'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * left?: Node,
 * middle?: Node,
 * right?: Node
 * }} props
 */
export default function Footer (props) {
  const { left, middle, right } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <main className={classes.footerContainer}>
      <section className={clsx(classes.footerSection, classes.footerSectionLeft)}>
        {left}
      </section>
      <section className={clsx(classes.footerSection, classes.footerSectionMiddle)}>
        {middle}
      </section>
      <section className={clsx(classes.footerSection, classes.footerSectionRight)}>
        {right}
      </section>
    </main>
  )
}

const useStyles = makeStyles((theme, props) => ({
  footerContainer: {
    display: 'flex',
    height: props => props.mode.isMobile
      ? constants.app.height['FOOTER--MOBILE']
      : constants.app.height['FOOTER--TABLET'],
    boxShadow: `0 -11px 21px 0px ${theme.palette.common.black}${opacity[25]}`,
  },
  footerSection: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  footerSectionLeft: {
    justifyContent: 'center',
    minWidth: props => props.mode.isMobile ? 128 : '20%', // request service button
  },
  footerSectionMiddle: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  footerSectionRight: {
    justifyContent: 'flex-end',
  },
}))
