import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function AddIcon (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 66 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='32.9942' cy='32.0186' r='29.7686' stroke={strokecolor} strokeWidth='4.5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M35.2441 20.4824V18.2324H30.7441V20.4824V29.7685H21.458H19.208V34.2685H21.458H30.7441V43.5547V45.8047H35.2441V43.5547V34.2685H44.5302H46.7802V29.7685H44.5302H35.2441V20.4824Z' fill={strokecolor} />
    </SvgIcon>
  )
}
