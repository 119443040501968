import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{
 * price?: number,
 * marketPrice?: boolean,
 * fontSize?: string | number,
 * signSize?: string | number,
 * }} props
 */
export default function PriceLabel (props) {
  const defaultFontSize = '1.4rem'
  const {
    price = 0,
    marketPrice = false,
    fontSize = defaultFontSize,
    signSize = fontSize || defaultFontSize,
  } = props
  const classes = useStyles({ ...props, fontSize, signSize })
  const { t } = useTranslation()

  if (marketPrice) {
    return <Typography className={classes.priceLabel}>{t('app.common.market_price')}</Typography>
  }

  return (
    <Box flexShrink={0} display='flex' alignItems='center'>
      <Typography component='span' className={clsx(classes.priceLabel, classes.dollarSign)}>$&nbsp;</Typography>
      <Typography component='span' className={classes.priceLabel}>{price}</Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  priceLabel: {
    fontSize: props => props.fontSize,
    fontWeight: 'bold',
    color: props => props.color ? props.color : theme.palette.error.main,
    textShadow: '#ffffff 0em 0em 0.3em',
  },
  dollarSign: {
    fontSize: props => props.signSize,
    fontWeight: 'bold',
    color: props => props.signColor ? props.signColor : theme.palette.text.primaryDark,
    textShadow: '#ffffff 0em 0em 0.3em',
  },
}))
