const defaultColors = {
  primary: {
    main: '',
    disabled: '',
    contrastText: '',
    contrastTextHighlight: '',
  },
  secondary: {
    main: '',
    disabled: '',
    contrastText: '',
    contrastTextHighlight: '',
  },
}

const customColors = {
  primary: {
    main: '#E55B0C',
    disabled: '#F5BD9E',
    contrastText: '#ffffff',
    contrastTextHighlight: '#FFF500',
  },
  secondary: {
    main: '#EE7B05',
    disabled: '#F5BD9E',
    contrastText: '#ffffff',
    contrastTextHighlight: '#FFF500',
  },
}

const enableCustomColors = (open) => {
  return open ? customColors : defaultColors
}

export default enableCustomColors(true)
