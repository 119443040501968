import GroupContainer from '@//components/drawers/StepDrawer/GroupContainer'
import StepGroupHeader from '@/components/drawers/StepDrawer/StepGroup/StepGroupHeader'
import hooks from '@/hooks'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import StepItemList from './StepItemList'

StepGroup.propTypes = {
  group: PropTypes.object,
  menu: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IAppSetStep
 * menu: IAppSet
 * }} props
 * @returns {Node}
 */
export default function StepGroup (props) {
  const { group, menu } = props
  const list = _.filter(menu?.menus, menu => menu.step === group.key)
  const dependentItemId = group.dependsOnSetMenuItem

  // 如果套餐步驟裡有設定『承接餐單』的話，該步驟只有在點了指定餐點後才會出現。
  // 例：
  // step1 『漢堡』 （經典漢堡、炸雞漢堡）
  // step2 『選擇套餐』（沙拉、炸物）
  // step3 『沙拉』（水果沙拉、雞肉沙拉） <dependsOnSetMenuItem: 沙拉>
  // step4 『炸物』（薯條、可樂餅）  <dependsOnSetMenuItem: 炸物>
  // step5 『飲料』（可樂、紅茶、咖啡）
  // 則 step3 只應在 step2 選了『沙拉』這個指定餐點後才會出現
  // 且 step4 只應在 step2 選了『炸物』這個指定餐點後才會出現
  // 而 step1 和 step5 因沒有設定指定餐點因此都會出現
  if (dependentItemId) {
    const { setItems } = hooks.batchItemTemp.useBatchItemTemp()
    const hasDependentItem = _.find(setItems, item => item.setMenuId === dependentItemId)
    if (!hasDependentItem) return null
  }

  return (
    <GroupContainer>
      <StepGroupHeader group={group} />
      <StepItemList
        group={group}
        list={list}
        max={5}
        menu={menu}
      />
    </GroupContainer>
  )
}
