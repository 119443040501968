import PriceLabel from '@/components/PriceLabel'
import Stepper from '@/components/Stepper'
import constants from '@/constants'
import hooks from '@/hooks'
import libs from '@/libs'
import { getMenuImage } from '@/libs/menu'
import { actions, useDispatch } from '@/redux'
import opacity from '@/theme/opacity'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

MenuItem.propTypes = {
  menu: PropTypes.object,
  setItemMenu: PropTypes.object,
}

/**
 * @param {{
 * size: sm | md | lg
 * setItemMenu?: IAppMenuItem
 * }} props
 */
export default function MenuItem (props) {
  const { size = 'md', menu, setItemMenu } = props
  const { desc, price } = menu
  const name = setItemMenu ? setItemMenu.name : menu.name
  const _menu = !_.isEmpty(setItemMenu) ? setItemMenu : menu
  const image = libs.cloudinary.transformation(getMenuImage(_menu), 'w_1000') || constants.app.image.EMPTY_IMAGE
  const dispatch = useDispatch()
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, size, mode, image })

  const handleShowDetail = () => {
    dispatch(actions.app.toggleAlert({
      dialogProps: { classes: { paper: classes.dialogPaper } },
      title: name,
      message: desc,
      children: (
        <Box
          flexShrink={0}
          display='flex'
          alignItems='center'
          justifyContent='center'
          style={{ marginTop: 16 }}
        >
          {image
            ? <div className={classes.menuItemImage} style={{ width: '60vh' }} />
            : <div className={classes.emptyImage} />}
        </Box>
      ),
    }))
  }

  return (
    <Grid item xs={12} className={classes.gridItem}>
      <div className={classes.menuItemContainer} onClick={handleShowDetail}>
        <section className={classes.sectionLeft}>
          <Typography className={classes.menuItemName}>{name}</Typography>
          <Typography className={classes.menuItemDesc}>{desc}</Typography>
        </section>
        <section className={classes.sectionRight}>
          <PriceLabel
            price={price}
            fontSize={mode.isMobile ? '2rem' : '2.4rem'}
            signSize={mode.isMobile ? '1.3rem' : '1.5rem'}
          />
          <Stepper
            size={mode.isMobile ? 'lg' : 'md'}
            disableBackground
            className={classes.stepper}
            menu={menu}
            setItemMenu={setItemMenu}
          />
        </section>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  gridItem: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  menuItemContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
    minHeight: 56,
    padding: '8px 16px',
    backgroundImage: props => {
      const url = libs.cloudinary.transformation(props.image, `w_${constants.app.image['MENU/IMAGE_WIDTH']}`)
      return `url(${url})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&:after': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '""',
      backgroundImage: `linear-gradient(-180deg, #ffffff${opacity[0]}, #ffffff${opacity[72]} 38%, #ffffff 92%)`,
    },
  },
  sectionLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
  },
  sectionRight: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 1,
  },
  menuItemName: {
    color: theme.palette.text.primaryDark,
    fontWeight: 700,
    fontSize: props => {
      if (props.mode.isMobile) {
        if (props.size === 'md') return '1.6rem'
        if (props.size === 'lg') return '1.8rem'
      }
      if (props.mode.isTablet) {
        if (props.size === 'md') return '1.8rem'
        if (props.size === 'lg') return '2rem'
      }
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    textShadow: '#ffffff 0em 0em 0.2em',
  },
  menuItemDesc: {
    marginTop: 4,
    color: theme.palette.text.primaryLight,
    fontSize: props => {
      if (props.mode.isMobile) {
        if (props.size === 'md') return '1.3rem'
        if (props.size === 'lg') return '1.5rem'
      }
      if (props.mode.isTablet) {
        if (props.size === 'md') return '1.4rem'
        if (props.size === 'lg') return '1.6rem'
      }
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    textShadow: '#ffffff 0em 0em 0.2em',
  },
  emptyImage: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%', // aspect ratio 1:1
    backgroundColor: theme.palette.grey[200],
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      opacity: 0.15,
      backgroundImage: `url(${constants.app.image.EMPTY_IMAGE})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  menuItemImage: {
    width: '100%',
    paddingTop: '100%',
    backgroundImage: props => {
      const url = libs.cloudinary.transformation(props.image, `w_${constants.app.image['MENU/IMAGE_WIDTH']}`)
      return `url(${url})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}))
