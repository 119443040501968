import hooks from '@/hooks'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{
 * disabled?: boolean,
 * loading?: boolean,
 * onClick: function,
 * }} props
 */
export default function SubmitButton (props) {
  const { disabled = false, loading = false, onClick } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <Button
      disabled={disabled}
      variant='contained'
      color='primary'
      className={classes.submitButton}
      disableElevation
      onClick={onClick}
    >
      <Typography className={classes.submitButtonText}>
        {t('app.common.confirm_submit')}
      </Typography>

      {loading && (
        <div className={classes.loading}>
          <CircularProgress color='inherit' size={16} />
        </div>
      )}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  submitButton: {
    height: props => {
      if (props.mode.isMobile) return 48
      if (props.mode.isTablet) return 56
    },
    marginTop: props => {
      if (props.mode.isMobile) return 8
      if (props.mode.isTablet) return 24
    },
    border: `1px solid ${theme.palette.primary.main}`,
  },
  submitButtonText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '1.8rem'
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 16,
    color: theme.palette.common.white,
  },
}))
