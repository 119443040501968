import produce from 'immer'
import ActionTypes from './ActionTypes'

/** @type {IUserAuth} */
const initialAuth = {}

/** @type {IUserState} */
export const initalState = {
  isInit: false,
  auth: initialAuth,
  id: '',
}

export default produce(
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.REGISTER_USER: {
        const { auth } = action.payload
        draft.auth = auth
        break
      }
      case ActionTypes.CREATE_CUSTOMER: {
        const { userId } = action.payload
        draft.id = userId
        break
      }
      default:
        break
    }
  },
  initalState,
)
