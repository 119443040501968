import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import OrderHistoryItem from './OrderHistoryItem'

/**
 * @param {{ order: IAppOrder }} props
 */
export default function OrderHistoryItemList (props) {
  const { order } = props
  const classes = useStyles(props)
  const items = _.flatMap(order.batches, batch => batch.items)

  return (
    <Box className={classes.orderHistoryItemListContainer}>
      {_.map(items, (item, index) => <OrderHistoryItem key={index} item={item} />)}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  orderHistoryItemListContainer: {
  },
}))
