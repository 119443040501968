import MerchantLogo from '@/assets/custom/images/logo-yakiniku.png'
import constants from '@/constants'
import hooks from '@/hooks'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import CategoryMenuItem from './CategoryMenuItem'

export default function CategoryMenu (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const history = useHistory()
  const categories = hooks.menu.useCategories()
  const merchantId = useSelector(state => state.merchant.data?.id)

  const [logoClickTimes, setLogoClickTimes] = React.useState(0)

  React.useEffect(() => {
    let clickTimeout = null
    if (logoClickTimes !== 0) {
      clickTimeout = setTimeout(() => {
        setLogoClickTimes(0)
      }, 3000)
    }

    return () => {
      if (clickTimeout) {
        clearTimeout(clickTimeout)
      }
    }
  }, [logoClickTimes])

  return (
    <>
      <img
        src={MerchantLogo}
        alt='merchant-logo-image'
        className={classes.merchantLogo}
        onClick={() => {
          if (logoClickTimes === 9) {
            setLogoClickTimes(0)
            history.push('/setting')
          } else {
            setLogoClickTimes(logoClickTimes + 1)
          }
        }}
      />
      {_.map(categories, (category, index) => {
        if ([constants.merchant.BBQ, constants.merchant.YAKINIKU, constants.merchant.YAKINIKU_TKO, constants.merchant.w3].includes(merchantId) && category.id === 'promoted-category') {
          return null
        }
        return (
          <CategoryMenuItem
            key={index}
            category={category}
            onClick={() => dispatch(actions.menu.selectCategory(category))}
          />
        )
      })}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  merchantLogo: {
    width: '100%',
    padding: 8,
    boxSizing: 'border-box',
  },
}))
