import config from '@/config/api'
import shortCode from '@/libs/shortCode'
import Logger from 'dimorder-orderapp-lib/dist/libs/Logger'

export const logId = shortCode.generate(4)

const gcpLogger = new Logger('https://orderapp-logger-ssj7hdeukq-de.a.run.app/log', {
  // buildNumber: string,
  // appVersion: config.version,
  version: process.env.REACT_APP_VERSION,
  environment: config.env,
  sha: process.env.REACT_APP_GIT_SHA,
  // platform: Capacitor.getPlatform(),
  client: 'CUSTOMER_TABLET',
  logId,
})

const nativeSupportLogLevel = [
  'debug',
  'error',
  'info',
  'log',
  'trace',
  'warn',
]

const logger = {}

nativeSupportLogLevel.forEach(level => {
  logger[level] = (...args) => {
    const [message, data] = args
    console.log(...args)

    gcpLogger.log(level, {
      message: message,
      ...data,
    })
  }
})

// set user id after login
export function setUserId (userId) {
  gcpLogger.labels.userId = userId
}

export default logger
