import dimorderApi from '@/libs/api/dimorder'
import { getStepQuantityMax } from '@/libs/order'
import eachDeep from 'deepdash/eachDeep'
import filterDeep from 'deepdash/filterDeep'
import _ from 'lodash'

/**
 * @function
 * @param {string} merchantId
 * @param {ICategory[]} categories API 給的第一層 categories
 * @returns {Promise<[{[id: string]: IAppCategory}, IAppCategory, IAppCategory, {[id: string]:  IAppMenuItem}, {[id: string]: IAppSet}>}
 */
export async function parseCategories (merchantId, categories) {
  /** @type {IAppCategory[]} */
  const flattenCategories = []

  // 建立 flattenCategories 方便 get menus
  eachDeep(
    categories,
    (category, key, parentCategory, context) => {
      // 重新判斷 isSet（用來過濾舊版在 category 的套餐）
      category.isSet = category.isSet && category.steps.length > 0 && category.categories.length === 0
      if (!category.isSet) {
        category.steps = []
      }

      // 放到 flattenCategories
      flattenCategories.push(category)
    },
    { childrenPath: 'categories' },
  )

  // get menus
  await Promise.all(flattenCategories.map(async category => {
    const [menus, sets] = await Promise.all([
      dimorderApi.menu.getCategoryMenus(merchantId, category.id).catch(() => []),
      dimorderApi.menu.getCategorySets(merchantId, category.id).catch(() => []),
    ])

    for await (const set of sets) {
      set.menus = await dimorderApi.menu.getSetMenus(merchantId, set.id) || []
    }
    category.menus = menus
    category.sets = _.filter(sets, set => set.menus.length > 0)
  }))

  // 過濾空的和不該在單點目錄的分類
  const filteredCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SET') {
        // 是個套餐目錄，一定不留
        return false
      }

      if (category.menus.length > 0) {
        // 有 menu，一定要留
        return true
      } else if (category.categories.length === 0) {
        // 沒 menu 也沒 categories，一定不留
        return false
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  // 過濾空的和不該在套餐目錄的分類
  const filteredSetCategories = filterDeep(
    _.cloneDeep(categories),
    (category, key, parentCategory, context) => {
      if (category.isSet && parentCategory?.id) {
        // 舊版套餐，且不在第一層，一定不留
        return false
      }

      if (category.type === 'SINGLE') {
        // 是個單點目錄，一定不留
        return false
      }

      if (category.sets.length > 0) {
        // 有 set，一定要留
        return true
      } else if (category.categories.length === 0) {
        // 沒 set 也沒 categories，一定不留
        return false
      }

      // 舊版套餐但在第一層 (沒 parentCategory.id)，要留
      if (category.isSet && !parentCategory?.id) {
        return true
      }

      // 其他情況為 undefined，會繼續檢查 children
    },
    { childrenPath: 'categories' },
  ) || []

  const rootCategory = {
    id: 'ROOT',
    name: '目錄',
    parentId: null,
    categories: filteredCategories,
    menus: [],
    sets: [],
    path: 'ROOT',
  }
  const rootSetCategory = {
    id: 'SET_ROOT',
    name: '套餐',
    parentId: null,
    categories: filteredSetCategories,
    menus: [],
    sets: [],
    isInSetCategories: true,
    path: 'SET_ROOT',
  }

  /** @type {{[id: string]: IAppCategory}} */
  const categoriesMap = {
    ROOT: rootCategory,
    SET_ROOT: rootSetCategory,
  }
  const menus = {}
  const sets = {}

  // create categoriesMap, set parentId
  eachDeep(
    rootCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'ROOT'
      }
      category.isInSetCategories = false
      categoriesMap[category.id] = category
      category.menus.forEach(menu => {
        menu.menuId = menu.id
        menu.path = path
        menus[menu.id] = menu
      })
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          step.parentId = parentCategory.id
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.path = path
          menus[menu.id] = menu
        })
        set.separatedStep = findSeparatedStep(set)
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )

  eachDeep(
    rootSetCategory.categories,
    (category, key, parentCategory, context) => {
      // 記錄 path，用於搜尋
      const path = 'SET_ROOT.categories' + context.path

      category.path = path
      if (parentCategory && parentCategory.id) {
        category.parentId = parentCategory.id
      } else {
        category.parentId = 'SET_ROOT'
      }
      category.isInSetCategories = true
      categoriesMap[category.id] = category
      category.sets.forEach(set => {
        sets[set.id] = set
        set.path = path
        set.isSet = true
        set.categoryId = category.id
        set.steps.forEach(step => {
          step.id = step.key
          step.parentId = parentCategory.id
          step.setId = set.id
          step.isSetStep = true
        })
        set.menus.forEach(menu => {
          menu.path = path
          if (menu.promoted) {
            menu.promoted = false
            set.promoted = true
          }
          menus[menu.id] = menu
        })
        set.separatedStep = findSeparatedStep(set)
      })
    },
    { childrenPath: 'categories', pathFormat: 'string' },
  )

  return [categoriesMap, rootCategory, rootSetCategory, menus, sets]
}

/**
 * @function
 * 套餐的話找特定步驟（預設第一步）裡的第一個品項的圖片
 * @param {IAppMenuItem | IAppSet} menu
 * @param {number?} stepIndex 預設抓第一個
 * @returns {string}
 */
export function getMenuImage (menu, stepIndex = 0) {
  if (!menu.isSet) return menu.image ?? ''
  const firstStepId = menu.steps[stepIndex]?.id
  const firstStepfirstItem = _.find(menu.menus, m => m.step === firstStepId)
  return firstStepfirstItem?.image ?? ''
}

/**
 * @function
 * 如果第一步是單點則設定 separatedStep 為第一步的 id
 * 如果以後要擴充成透過 api 指定步驟的話會比較方便
 * 前端其他的地方可以用 separatedStep 去判斷
 * @param {IAppSet} set
 * @returns {string}
 */
export function findSeparatedStep (set) {
  if (!set.steps) return undefined
  const firstStep = set.steps[0]
  const firstStepMax = getStepQuantityMax(undefined, firstStep)
  const isMultiple = firstStepMax > 1
  if (!isMultiple) {
    return firstStep.id
  }
  return undefined
}

// get separatedStep 的 item
export function getSetItemMenu (set, separatedStep) {
  return set.menus?.filter(menu => {
    return menu.step === separatedStep
  })
}

export default {
  findSeparatedStep,
  getSetItemMenu,
  parseCategories,
  getMenuImage,
}
