import Radio from '@/components/drawers/StepDrawer/GroupItem/rightActions/Radio'
import PropTypes from 'prop-types'
import React from 'react'
import GroupItemBase from './GroupItemBase'

GroupItemRadio.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * item: IMenuOptionItem
 * selected: Boolean
 * onClick: Function
 * }} props
 */
export default function GroupItemRadio (props) {
  const { item, selected, onClick } = props
  const radio = <Radio selected={selected} onClick={onClick} />

  return (
    <GroupItemBase item={item} onClick={onClick} rightAction={radio} />
  )
}
