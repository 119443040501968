import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @typedef {Object} IService
 * @property {string} name
 * @property {function} onClick
 */

/**
 * @param {{ service: IService }} props
 */
export default function ServiceOption (props) {
  const { name: service, onClick } = props.service
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode })
  const handleClose = () => dispatch(actions.app.toggleDialog('serviceRequest'))

  return (
    <MenuItem onClick={() => {
      onClick()
      handleClose()
    }}
    >
      <Typography className={classes.optionText}>{service}</Typography>
    </MenuItem>
  )
}

const useStyles = makeStyles(theme => ({
  optionText: {
    minWidth: 200,
    fontSize: '1.6rem',
    textAlign: 'center',
    padding: '8px 16px',
  },
}))
