export default {
  mode: {
    MOBILE: 'MODE--MOBILE',
    TABLET: 'MODE--TABLET',
  },
  zIndex: {
    BACKDROP_LOADING: 100,
    CATEGORYBAR: 10,
  },
  height: {
    'NAVBAR--MOBILE': 48,
    'NAVBAR--TABLET': 48,
    'APPBAR--MOBILE': 48,
    'APPBAR--TABLET': 48,
    'FOOTER--MOBILE': 60,
    'FOOTER--TABLET': 64,
    CATEGORYBAR: 52,
  },
  width: {
    'FOOTER/SUBMIT_BUTTON--MOBILE': 144,
    'FOOTER/SUBMIT_BUTTON--TABLET': 400,
  },
  stepper: {
    MAX: 10, // 250?
  },
  image: {
    'MENU/IMAGE_WIDTH': 1125,
    EMPTY_IMAGE: 'https://res.cloudinary.com/dimorder/image/upload/w_170,h_170,c_lpad/v1610762674/assets/cooking_dyvgqf.png',
  },
  snackbar: {
    OPTIONS: {
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    },
  },
}
