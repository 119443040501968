import hooks from '@/hooks'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SetItems (props) {
  const { setItems = [] } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  // 若全部 setItems 都是 [] 的話不顯示 setItems
  if (!_.find(setItems, setItem => !_.isEmpty(setItem))) return null

  return (
    <Box className={classes.setItemsContainer}>
      {!_.isEmpty(setItems) && (
        <Typography className={classes.setItemTitle}>
          {t('app.common.item.setItems')}
        </Typography>
      )}
      <List>
        {_.map(setItems, (setItems, index) => {
          return (
            <ListItem key={index} className={classes.setItem}>
              {
                _.isEmpty(setItems)
                  ? <Typography color='inherit' component='span'>{t('app.common.none')}</Typography>
                  : (
                    _.map(setItems, (setItem, index) => {
                      const lastIndex = setItems.length - 1
                      const showDot = index !== lastIndex
                      return (
                        <Box key={index} className={classes.opt}>
                          <Typography className={classes.optText} color='inherit' component='span'>{setItem.name}</Typography>
                          {Boolean(setItem.price) && <Typography className={classes.optText} id='price' component='span'>{`($${setItem.price})`}</Typography>}
                          {showDot && <Typography className={classes.optText} color='inherit' component='span'>,</Typography>}
                        </Box>
                      )
                    })
                  )
              }
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  setItemsContainer: {
    padding: 4,
  },
  setItemTitle: {
    fontSize: '1.4rem',
    color: theme.palette.text.primaryLight,
  },
  setItem: {
    fontSize: '1.2rem',
    color: theme.palette.info.main,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    margin: '4px 0px',
  },
  opt: {
    fontSize: 'inherit',
    marginRight: 4,
    '& #price': {
      marginLeft: 4,
      color: theme.palette.grey[500],
    },
  },
  optText: {
    fontSize: 'inherit',
  },
}))
