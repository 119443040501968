import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @param {{ number: number }} props
 */
export default function Badge (props) {
  const { number } = props
  const mode = hooks.app.useMode()
  const classes = useStyles(props)

  return (
    mode.isMobile && number
      ? <div className={classes.badge}>{number}</div>
      : null
  )
}

const useStyles = makeStyles(theme => ({
  badge: {
    position: 'absolute',
    top: 4,
    right: 4,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    minWidth: 20,
    height: 20,
    padding: 2,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    borderRadius: 80,
  },
}))
