export default {
  INIT: 'MENU/INIT',
  UPDATE_PICTURE_MODE: 'MENU/UPDATE_PICTURE_MODE',
  UPDATE_BATCH_LIST_EXPAND: 'MENU/UPDATE_BATCH_LIST_EXPAND',
  UPDATE_SEARCH_EXPAND: 'MENU/UPDATE_SEARCH_EXPAND',
  UPDATE_CATEGORIES: 'MENU/UPDATE_CATEGORIES',
  SELECT_CATEGORY: 'MENU/SELECT_CATEGORY',
  SELECT_SET: 'MENU/SELECT_SET',
  SELECT_SET_STEP: 'MENU/SELECT_SET_STEP',
  UPDATE_SEARCH_TEXT: 'MENU/UPDATE_SEARCH_TEXT',
}
