import constants from '@/constants'
import { actions, useDispatch, useSelector } from '@/redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import _ from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function InitConfig (props) {
  const history = useHistory()
  const location = useLocation()
  window.appHistory = history
  window.appLocation = location

  const dispatch = useDispatch()
  const isTablet = useMediaQuery(theme => theme.breakpoints.up('md'))
  const { orders } = useSelector(state => state.orderHistory)
  const isAppInit = useSelector(state => state.app.isInit)

  useEffect(() => {
    let mode = constants.app.mode.MOBILE
    if (isTablet) mode = constants.app.mode.TABLET
    dispatch(actions.app.updateMode(mode))
  }, [isTablet])

  useEffect(() => {
    dispatch(actions.app.init())
  }, [])

  useEffect(async () => {
    setInterval(async function () {
      if (moment().format('HH:mm') === '04:00') {
        dispatch(actions.app.checkUpdate())
      }
    }, 10000)
  }, [])

  useEffect(() => {
    // 轉頁到 /paid
    if (isAppInit && _.isEmpty(orders)) {
      history.push('/paid')
    }
  }, [orders])

  return null
}
