import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import opacity from '@/theme/opacity'
import { ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import React from 'react'

/**
 * @param {{
 * table: string,
 * selected: boolean,
 * }} props
 */
export default function TableItem (props) {
  const { table, selected } = props
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode, selected })

  return (
    <ListItem button className={classes.table} onClick={() => dispatch(actions.app.updateTable(table))}>
      <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
        <ArrowRightIcon fontSize='large' className={classes.indicator} />
      </ListItemIcon>
      <Typography className={classes.tableText}>{table}</Typography>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  table: {

  },
  tableText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.4rem'
      if (props.mode.isTablet) return '2.2rem'
    },
    fontWeight: props => props.selected && 'bold',
    color: props => props.selected && theme.palette.warning.main,
  },
  listItemIconRoot: {
    minWidth: 'unset',
    marginRight: 4,
  },
  indicator: {
    color: props => props.selected
      ? theme.palette.warning.main
      : `${theme.palette.warning.main}${opacity[0]}`,
  },
}))
