import hooks from '@/hooks'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{
 * open?: boolean,
 * onClose: function,
 * alertConfig: IAlertConfig
 * }} props
 */
export default function Alert (props) {
  const { open = true, onClose } = props
  const {
    title,
    message,
    messages,
    renderMessage,
    messageAlign = 'center',
    onConfirm,
    children,
    dialogProps,
    button,
    buttons,
    noBack,
  } = props.alertConfig

  const mode = hooks.app.useMode()
  const theme = useTheme()
  const classes = useStyles({ ...props, mode, messageAlign })
  const { t } = useTranslation()

  const renderButtons = () => {
    if (button) {
      const { text, className, style, color, onClick, disabled } = button
      return (
        <ButtonBase
          style={{ ...style, ...color && { color } }}
          className={clsx(classes.actionButton, className)}
          disabled={disabled}
          onClick={() => {
            onClick && onClick()
            onClose()
          }}
        >
          {text}
        </ButtonBase>
      )
    }

    if (buttons) {
      return _.map(buttons, (button, index) => {
        const { text, className, style, color, onClick, disabled } = button
        return (
          <ButtonBase
            key={index}
            style={{ ...style, ...color && { color } }}
            className={clsx(classes.actionButton, className)}
            disabled={disabled}
            onClick={() => {
              onClick && onClick()
              onClose()
            }}
          >
            {text}
          </ButtonBase>
        )
      })
    }

    if (onConfirm) {
      const handleConfirm = () => {
        onConfirm()
        onClose()
      }
      return (
        <>
          <ButtonBase className={classes.actionButton} onClick={onClose}>{t('app.common.cancel')}</ButtonBase>
          <ButtonBase className={classes.actionButton} style={{ color: theme.palette.info.main }} onClick={handleConfirm}>{t('app.common.confirm')}</ButtonBase>
        </>
      )
    }

    if (noBack) {
      return null
    }
    return <ButtonBase className={classes.actionButton} style={{ color: theme.palette.info.main }} onClick={onClose}>{t('app.common.back')}</ButtonBase>
  }

  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
      </DialogTitle>

      <DialogContent className={classes.message}>
        <Typography className={classes.messageText}>
          {
            renderMessage
              ? renderMessage()
              : messages
                ? _.map(messages, (line, index) => <span key={index}>{line}</span>)
                : message
          }
        </Typography>
        {children}
      </DialogContent>

      <DialogActions className={classes.actionsContainer} disableSpacing>
        {renderButtons()}
      </DialogActions>

    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    minWidth: 240,
    padding: '4px 24px',
    marginTop: 8,
  },
  titleText: {
    fontSize: '1.6rem',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  message: {
    padding: '8px 16px',
    marginBottom: 8,
  },
  messageText: {
    fontSize: '1.6rem',
    marginBottom: 8,
    color: theme.palette.grey[600],
    textAlign: props => props.messageAlign,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 0,
    boxShadow: `0px -1px ${colors.divider}${opacity[25]}`,
  },
  actionButton: {
    flex: 1,
    padding: '8px 24px',
    minHeight: 48,
    minWidth: 120,
    boxShadow: `1px 0px ${colors.divider}${opacity[25]}`,
    fontSize: '1.6rem',
    color: theme.palette.common.black,
  },
}))
