import hooks from '@/hooks'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import MenuItem from './MenuItem'

export default function MenuItemsRow (props) {
  const classes = useStyles(props)
  const selectedCategory = hooks.menu.useSelectedCategory()

  return (
    <Grid
      container
      spacing={2}
      className={classes.menuItemGrid}
    >
      {
        selectedCategory?.isSet &&
        _.map(selectedCategory?.sets, (set, index) => {
          if (set.separatedStep) {
            const setItemMenus = _.filter(set.menus, menu => menu.step === set.separatedStep)
            return _.map(setItemMenus, setItemMenu => {
              return <MenuItem key={setItemMenu.id} menu={set} setItemMenu={setItemMenu} />
            })
          }
          // return <MenuItem key={index} size={size} menu={menu} />
        })
      }
      {
        _.map(selectedCategory?.menus, (menu, index) => {
          return <MenuItem key={index} menu={menu} />
        })
      }
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  menuItemGrid: {

  },
}))
