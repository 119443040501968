import CartComponent from '@/components/Footer/Cart'
import hooks from '@/hooks'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{
 * table: string,
 * totalQuantities: number,
 * onClick?: function
 * }} props
 */
export default function Cart (props) {
  const { table, totalQuantities, onClick } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <CartComponent onClick={onClick}>
      <Typography className={classes.table}>
        {t('app.common.table_number', { table })}
      </Typography>
      <Typography color='textPrimary' className={classes.totalQuantities}>
        {t('app.home.footer.total_dishes', { dishes: totalQuantities })}
      </Typography>
    </CartComponent>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    fontSize: props => props.mode.isMobile ? '1.2rem' : '1.6rem',
    color: theme.palette.text.primaryLight,
  },
  totalQuantities: {
    fontSize: props => props.mode.isMobile ? '1.4rem' : '1.8rem',
  },
}))
