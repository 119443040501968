import SubmitButtonComponent from '@/components/Footer/SubmitButton'
import { actions, useDispatch } from '@/redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ className?: string}} props
 */
export default function SubmitButton (props) {
  const { className } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitDisabled, setSubmitDisabled] = useState(0)

  const buttonText = t('app.home.footer.confirm_order').toUpperCase()
  const handleClick = async () => {
    setSubmitDisabled(true)
    setTimeout(() => {
      setSubmitDisabled(false)
    }, 5000)
    await dispatch(actions.order.submitOrderBatch())
  }

  return (
    <SubmitButtonComponent
      disabled={submitDisabled}
      className={className}
      text={buttonText}
      onClick={handleClick}
    />
  )
}
