import constants from '@/constants'
import { Box, ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

/**
 * @param {{
 * page?: 'checkout',
 * table?: string,
 * totalQuantities?: number,
 * }} props
 */
export default function Cart (props) {
  const { page, table, totalQuantities } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles({ ...props, page })

  switch (page) {
    case 'checkout': {
      return (
        <div className={classes.cartContainer}>
          <ButtonBase className={classes.cart} disableRipple>
            <Box flexShrink={0} display='flex' flexDirection='column'>
              <Typography color='inherit' className={classes.totalQuantities}>
                {t('app.custom.footer.cart.total_quantity', { quantity: totalQuantities })}
              </Typography>
            </Box>
          </ButtonBase>
        </div>
      )
    }
    default: {
      return (
        <div className={classes.cartContainer}>
          <ButtonBase className={classes.cart} onClick={() => history.push('/history')}>
            <Typography color='inherit' className={classes.inCart}>
              {t('app.custom.footer.cart.orderd_item')}
            </Typography>
          </ButtonBase>
          <ButtonBase className={classes.cart} disableRipple>
            <Box flexShrink={0} display='flex' flexDirection='column'>
              <Typography color='inherit' className={classes.table}>
                {t('app.common.table_number', { table })}
              </Typography>
              <Typography color='inherit' className={classes.totalQuantities}>
                {t('app.custom.footer.cart.total_quantity', { quantity: totalQuantities })}
              </Typography>
            </Box>
          </ButtonBase>
        </div>
      )
    }
  }
}

const useStyles = makeStyles(theme => ({
  cartContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: props => props.page === 'checkout' ? 'flex-end' : 'space-between',
  },
  cart: {
    backgroundColor: '#E73C0D',
    color: '#ffffff',
    padding: '4px 16px',
    minWidth: 160,
    marginRight: 40,
    height: constants.app.height['FOOTER--TABLET'] - 4,
  },
  inCart: {
    fontSize: '1.8rem',
  },
  table: {
    fontSize: '1.8rem',
    color: '#c2c2c2',
    textAlign: 'left',
  },
  totalQuantities: {
    fontSize: '1.8rem',
    textAlign: 'left',
  },
}))
