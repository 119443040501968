import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ServiceOption from './ServiceOption'

export default function ServiceRequestDialog (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const open = useSelector(state => state.app.dialogs.serviceRequest)
  const handleClose = () => dispatch(actions.app.toggleDialog('serviceRequest'))

  const services = [
    {
      name: t('app.dialog.service_request.service.pay'),
      onClick: () => dispatch(actions.order.requestService('pay')),
    },
    {
      name: t('app.dialog.service_request.service.waiter'),
      onClick: () => dispatch(actions.order.requestService('waiter')),
    },
  ]

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paperRoot }}>
      {_.map(services, (service, index) => <ServiceOption key={index} service={service} />)}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paperRoot: {
    '& li': {
      boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    },
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
}))
