import { ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 *
 * @param {{
 * onClick?: Function,
 * children: any,
 * className?: string,
 * }} props
 */
export default function SettingListItem (props) {
  const { children, onClick, className } = props
  const classes = useStyles(props)

  return (
    <ListItem className={clsx(classes.listItem, className)} button={onClick} onClick={onClick}>
      {children}
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 64,
  },
}))
