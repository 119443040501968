import dimorderApi from '@/libs/api/dimorder'
import { actions } from '@/redux'
import { setUserId } from '@root/src/libs/logger'
import ActionTypes from './ActionTypes'

/**
 * @returns {ThunkFunction}
 * @param {string} merchantId
 */
export function init (merchantId) {
  return async (dispatch) => {
    await dispatch(fetchMerchant(merchantId))
    setUserId(merchantId)
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} merchantId
 */
export function fetchMerchant (merchantId) {
  return async (dispatch) => {
    try {
      const merchant = await dimorderApi.merchant.getMerchant(merchantId)
      dispatch({
        type: ActionTypes.FETCH_MERCHANT,
        payload: { merchant },
      })
    } catch (error) {
      dispatch(actions.app.toggleAlert({
        title: '找不到餐廳',
        noBack: true,
      }))
      console.log('fetchMerchant error', error)
    }
  }
}
