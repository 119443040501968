import i18n from '@/i18n'
import {
  getSelectedBatch,
  getSelectedBatchItem,
} from '@/libs/order'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import actions from '../actions'
import ActionTypes from './ActionTypes'

/**
 * @param {boolean} showNotCompleteSets
 */
export function updateShowNotCompleteSets (showNotCompleteSets) {
  return {
    type: ActionTypes.SHOW_NOT_COMPLETE_SETS,
    payload: { showNotCompleteSets },
  }
}

/**
 * @returns {ThunkFunction}
 */
export function undo () {
  return (dispatch, getState) => {
    const preBatchHistories = getState().orderBatch.batchHistory.preBatchHistories
    const preBatchHistory = _.last(preBatchHistories)

    dispatch({
      type: ActionTypes.UNDO_BATCH,
    })

    // 復原選取狀態
    if (preBatchHistory.selectedItemKey !== null) {
      dispatch(selectItem(
        preBatchHistory.selectedItemKey,
        preBatchHistory.selectedSetItemKey,
      ))
    } else {
      dispatch(selectItem(null))
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function redo () {
  return (dispatch, getState) => {
    const pastBatchHistories = getState().orderBatch.batchHistory.pastBatchHistories
    const pastBatchHistory = _.first(pastBatchHistories)

    dispatch({
      type: ActionTypes.REDO_BATCH,
    })

    // 復原選取狀態
    if (pastBatchHistory.selectedItemKey !== null) {
      dispatch(selectItem(
        pastBatchHistory.selectedItemKey,
        pastBatchHistory.selectedSetItemKey,
      ))
    } else {
      dispatch(selectItem(null))
    }
  }
}

/**
 * @param {IAppOrderBatch} batch
 * @returns {ThunkFunction}
 */
export function selectBatch (batch) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SELECT_BATCH,
      payload: { batch },
    })
    dispatch(selectItem(null))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function deselectItems () {
  return (dispatch) => {
    dispatch(selectItem(null))
  }
}

/**
 * @param {string} itemKey
 * @returns {ThunkFunction}
 */
export function selectItem (itemKey, setItemKey = null) {
  return (dispatch, getState) => {
    const categories = getState().menu.categories
    const sets = getState().menu.sets
    const selectedSet = getState().menu.selectedSet
    const selectedBatch = getSelectedBatch()
    const [selectedItem] = getSelectedBatchItem()
    const selectItem = selectedBatch.items.find(item => item.key === itemKey)
    const selectSetItem = selectItem?.setItems.find(setItem => setItem.key === setItemKey)

    dispatch({
      type: ActionTypes.SELECT_ITEM,
      payload: { itemKey },
    })

    dispatch({
      type: ActionTypes.SELECT_SET_ITEM,
      payload: { setItemKey },
    })

    if (selectItem?.isSet) {
      // 選擇的是套餐

      if (selectedSet?.id !== selectItem.menuId) {
        // 和原本的 set 不同，選擇 set 和 step 0
        const set = sets[selectItem.menuId]
        dispatch(actions.menu.selectSet(set))
        dispatch(actions.menu.selectSetStep(set.steps[0]))
      }

      if (setItemKey && typeof setItemKey !== 'object') {
        // 有選套餐項目，比對選擇的 step
        const selectedSetStep = getState().menu.selectedSetStep
        if (selectSetItem?.step !== selectedSetStep?.id) {
          // 步驟不符，選擇所選的步驟
          const set = sets[selectItem.menuId]
          const selectStep = set.steps.find(step => step.id === selectSetItem.step)
          dispatch(actions.menu.selectSet(set))
          dispatch(actions.menu.selectSetStep(selectStep))
        }
      }
    } else if (selectedItem?.isSet) {
      // 選擇的不是套餐，但原本選擇的是套餐，取消選擇 SetMenu 和 Step
      const set = sets[selectedItem.menuId]
      dispatch(actions.menu.selectCategory(categories[set?.categoryId]))
      dispatch(actions.menu.selectSet(null))
      dispatch(actions.menu.selectSetStep(null))
    }
  }
}

/**
 * @param {number} serial
 * @returns {ThunkFunction}
 */
export function selectQuantity (serial) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_SET_ITEM,
      payload: { setItemKey: serial },
    })
  }
}

/**
 * @param {IAppMenuItem | IAppSet} menuItemOrSet
 * @returns {ThunkFunction}
 */
export function addItem (menuItemOrSet) {
  return (dispatch, getState) => {
    const batchItem = {
      key: uuid(),
      menuId: menuItemOrSet.id,
      categoryId: menuItemOrSet.categoryId,
      name: menuItemOrSet.name,
      desc: menuItemOrSet.desc,
      quantity: 1,
      price: menuItemOrSet.price || 0,
      priceUndetermined: menuItemOrSet.priceUndetermined,
      discount: menuItemOrSet.discount || 0,
      options: [],
      tags: [],
      remarks: [],
      setItems: [],
      modifiers: [],
      isSet: menuItemOrSet.isSet,
    }

    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: { item: batchItem },
    })
    dispatch(selectItem(batchItem.key))

    if (menuItemOrSet.isSet) {
      // 加入必點項目
      dispatch(adjustRequiredSetItem(menuItemOrSet))
    }
  }
}

/**
 * @param {IAppMenuItem | IAppSet} batchItem
 * @returns {ThunkFunction}
 */
export function addTempItem (batchItem) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: { item: batchItem },
    })
    // dispatch(selectItem(batchItem.key))
  }
}

/**
 * @param {IAppMenuItem} setMenuItem
 * @returns {ThunkFunction}
 */
export function addSetItem (setMenuItem) {
  return (dispatch, getState) => {
    const selectedStep = getState().menu.selectedCategory
    const selectedItemKey = getState().orderBatch.selectedItemKey
    const selectedSetItemKey = getState().orderBatch.selectedSetItemKey
    const [, selectedSetItem] = getSelectedBatchItem()

    /** @type {IAppBatchItem} */
    const batchItem = {
      key: uuid(),
      menuId: setMenuItem.menuId, // set menu 原始的 menu id
      setMenuId: setMenuItem.id, // set menu 自己的 id
      categoryId: selectedStep.parentId, // 所屬分類
      name: setMenuItem.name,
      desc: setMenuItem.desc,
      price: setMenuItem.price || 0,
      priceUndetermined: setMenuItem.priceUndetermined,
      quantity: 1,
      options: [],
      tags: [],
      discount: 0,
      remarks: [],
      setItems: [],
      modifiers: [],
      step: setMenuItem.step,
      setMenuIndex: null,
      isRequired: false,
    }

    // 如果有 selectedSetItem 使用 selectedSetItem 相同的 setMenuIndex
    if (typeof selectedSetItem?.setMenuIndex === 'number') {
      batchItem.setMenuIndex = selectedSetItem.setMenuIndex
    }
    // 如果 selectedSetItemKey 中有 setMenuIndex，表是是選擇一個 section，使用選擇的 setMenuIndex
    if (typeof selectedSetItemKey?.setMenuIndex === 'number') {
      batchItem.setMenuIndex = selectedSetItemKey.setMenuIndex
    }

    dispatch({
      type: ActionTypes.ADD_SET_ITEM,
      payload: { item: batchItem },
    })
    dispatch(selectItem(selectedItemKey, batchItem.key))
  }
}

/**
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function adjustRequiredSetItem (set) {
  return (dispatch, getState) => {
    const selectedStep = getState().menu.selectedCategory
    const [selectedItem] = getSelectedBatchItem()

    // 找出套餐中所有必點項目，轉換成 batchItem（必點幾個就會加入幾個）
    /** @type {IAppMenuItem[]} */
    const requiredBatchItems = []
    set.menus?.forEach(menuItem => {
      _.times(menuItem.min, () => {
        requiredBatchItems.push({
          key: uuid(),
          menuId: menuItem.menuId, // set menu 原始的 menu id
          setMenuId: menuItem.id, // set menu 自己的 id
          categoryId: selectedStep.parentId, // 所屬分類
          name: menuItem.name,
          price: menuItem.price || 0,
          quantity: 1,
          options: [],
          tags: [],
          discount: 0,
          remarks: [],
          setItems: [],
          modifiers: [],
          step: menuItem.step,
          setMenuIndex: null,
          isRequired: true,
        })
      })
    })

    // 套餐被點了幾個就應該要有幾份必點餐點，需要檢查每一個 setMenuIndex
    _.times(selectedItem.quantity, (setMenuIndex) => {
      // 找出目前的 setMenuIndex 的是否有必點餐點
      const hasRequiredItem = Boolean(selectedItem.setItems.find(setItem => setItem.setMenuIndex === setMenuIndex && setItem.isRequired))
      if (!hasRequiredItem) {
        // 沒有必點項目時加入必點項目
        requiredBatchItems.forEach(requiredBatchItem => {
          dispatch({
            type: ActionTypes.ADD_SET_ITEM,
            payload: {
              noHistory: true,
              item: {
                ...requiredBatchItem,
                key: uuid(),
                setMenuIndex,
              },
            },
          })
        })
      }
    })
  }
}

/**
 * 刪除超過套餐餐點 setMenuIndex > quantity 的餐點
 * @returns {ThunkFunction}
 */
export function deleteOverIndexSetMenuItems () {
  return (dispatch, getState) => {
    const [selectedItem] = getSelectedBatchItem()
    selectedItem.setItems.forEach(setItem => {
      if (setItem.setMenuIndex + 1 > selectedItem.quantity) {
        dispatch({
          type: ActionTypes.DELETE_SET_ITEM,
          payload: { noHistory: true, index: setItem.key },
        })
      }
    })
  }
}

/**
 * Use lodash.set to update selectedItem, calculate total.
 * @param {PropertyPath} path The path of the property to set .
 * @param {any} value The value to set.
 * @returns {ThunkFunction}
 */
export function updateSelectedItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM,
      payload: { path, value },
    })
  }
}

/**
 * Use lodash.set to update selectedItem, calculate total.
 * @param {PropertyPath} path The path of the property to set .
 * @param {any} value The value to set.
 * @returns {ThunkFunction}
 */
export function updateSelectedSetItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM,
      payload: { path, value },
    })
  }
}

/**
 * @param {number} newQuantity
 * @returns {ThunkFunction}
 */
export function updateQuantity (newQuantity) {
  return (dispatch, getState) => {
    const [selectedItem, selectedSetItem] = getSelectedBatchItem()
    const selectedItemKey = getState().orderBatch.selectedItemKey
    const selectedSetItemKey = getState().orderBatch.selectedSetItemKey

    if (!selectedItemKey && !selectedSetItemKey) return
    if (selectedSetItem?.isRequired) return // 必點項目無法調整數量

    const isSetItem = Boolean(selectedSetItemKey)

    if (isSetItem) {
      if (typeof selectedSetItemKey === 'object') {
        // selectedSetItemKey is a section, skip update quantity
        return
      }
      dispatch(updateSelectedSetItem('quantity', newQuantity))
    } else {
      dispatch(updateSelectedItem('quantity', newQuantity))
    }

    if (selectedItem.isSet) {
      const set = getState().menu.sets[selectedItem.menuId]
      // 補齊必點項目
      dispatch(adjustRequiredSetItem(set))
      // 刪除超過 quantity 的套餐項目
      dispatch(deleteOverIndexSetMenuItems())
    }
  }
}

/**
 * @param {number} addQuantity 增加數量（可為負）
 * @returns {ThunkFunction}
 */
export function addQuantity (addQuantity) {
  return (dispatch, getState) => {
    const [selectedItem, selectedSetItem] = getSelectedBatchItem()
    const item = selectedSetItem || selectedItem
    const isSetItem = Boolean(item?.step)

    if (!item) return
    if (selectedSetItem?.isRequired) return // 必點項目無法調整數量

    const newQuantity = item.quantity + addQuantity
    if (isSetItem) {
      if (typeof selectedSetItemKey === 'object') {
        // selectedSetItemKey is a section, skip update quantity
        return
      }
      dispatch(updateSelectedSetItem('quantity', newQuantity))
    } else {
      dispatch(updateSelectedItem('quantity', newQuantity))
    }

    if (selectedItem.isSet) {
      const set = getState().menu.sets[selectedItem.menuId]
      // 補齊必點項目
      dispatch(adjustRequiredSetItem(set))
    }
  }
}

/**
 * @param {number} price
 * @returns {ThunkFunction}
 */
export function updatePrice (price) {
  return (dispatch, getState) => {
    const [, selectedSetItem] = getSelectedBatchItem()
    const isSetItem = Boolean(selectedSetItem)

    if (isSetItem) {
      dispatch(updateSelectedSetItem('price', price))
      dispatch(updateSelectedSetItem('priceUndetermined', false))
    } else {
      dispatch(updateSelectedItem('price', price))
      dispatch(updateSelectedItem('priceUndetermined', false))
    }
  }
}

/**
 * @param {IMenuOptionGroup} optionGroup
 * @param {IMenuOptionItem} optionItem
 * @returns {ThunkFunction}
 */
export function updateOption (optionGroup, optionItem) {
  return (dispatch, getState) => {
    const serial = getState().orderBatch.selectedSetItemKey?.serial
    const [selectedItem, selectedSetItem] = getSelectedBatchItem()
    const item = selectedSetItem || selectedItem
    const isSetItem = Boolean(item?.step)

    if (!item) return

    let action = null
    const originalOptions = item.options.filter(itemOption => itemOption.optionGroupId === optionGroup.id)
    if (originalOptions.find(originalOption => originalOption.optionItemId === optionItem.id)) {
      // 選項項目已被選，刪除
      action = isSetItem ? ActionTypes.DELETE_SET_ITEM_OPTION : ActionTypes.DELETE_ITEM_OPTION
    } else {
      // 若選項群 multiple === true 但  max === 1 的話其實是單點
      const isMultiple = optionGroup.multiple && optionGroup.max > 1
      if (isMultiple) {
        // 多選
        if (optionGroup.max > 0 && originalOptions.length >= optionGroup.max) {
          // 有限制 max，且已達 max，跳出警告不給加入
          dispatch(actions.app.toggleAlert({
            message: i18n.t('app.alert.option_multiple_max.message'),
          }))
          return
        }
        // 多選，加入新的
        action = isSetItem ? ActionTypes.ADD_SET_ITEM_OPTION : ActionTypes.ADD_ITEM_OPTION
      } else {
        // 單選，直接蓋掉
        action = isSetItem ? ActionTypes.UPDATE_SET_ITEM_OPTION : ActionTypes.UPDATE_ITEM_OPTION
      }
    }

    const newOption = {
      optionGroupId: optionGroup.id,
      optionItemId: optionItem.id,
      optionNameGroup: optionGroup.name,
      name: optionItem.name,
      price: optionItem.price,
      quantity: 1,
    }

    if (serial) {
      // quantity item 被選擇，新增成新 item
      // 先更動 options
      const newOptions = [...item.options]
      const itemOptionIndex = item.options.findIndex(itemOption => itemOption.optionItemId === newOption.optionItemId)
      switch (action) {
        case ActionTypes.DELETE_ITEM_OPTION: {
          newOptions.splice(itemOptionIndex, 1)
          break
        }
        case ActionTypes.ADD_ITEM_OPTION: {
          newOptions.push(newOption)
          break
        }
        case ActionTypes.UPDATE_ITEM_OPTION: {
          if (itemOptionIndex >= 0) {
            newOptions[itemOptionIndex] = newOption
          } else {
            newOptions.push(newOption)
          }
          break
        }
      }

      // item quantity -1
      dispatch(addQuantity(-1))
      // add item with new options
      const newItemKey = uuid()
      dispatch({
        type: ActionTypes.ADD_ITEM,
        payload: {
          item: {
            ...item,
            key: newItemKey,
            quantity: 1,
            options: newOptions,
          },
        },
      })
      // select new item
      dispatch(selectItem(newItemKey))
    } else {
      dispatch({
        type: action,
        payload: {
          option: newOption,
        },
      })
    }
  }
}

/**
 * @param {IMenuTag} tag
 * @returns {ThunkFunction}
 */
export function toggleTag (tag) {
  return (dispatch, getState) => {
    const serial = getState().orderBatch.selectedSetItemKey?.serial
    const [selectedItem, selectedSetItem] = getSelectedBatchItem()
    const item = selectedSetItem || selectedItem
    const isSetItem = Boolean(item?.step)

    if (!item) return

    if (serial) {
      // quantity item 被選擇，新增成新 item
      // 先更動 tags
      const newTags = [...item.tags]
      const itemTagIndex = item.tags.findIndex(itemTag => itemTag.name === tag.name)
      if (itemTagIndex > -1) {
        newTags.splice(itemTagIndex, 1)
      } else {
        newTags.push(tag)
      }

      // item quantity -1
      dispatch(addQuantity(-1))
      // add item with new tags
      const newItemKey = uuid()
      dispatch({
        type: ActionTypes.ADD_ITEM,
        payload: {
          item: {
            ...item,
            key: newItemKey,
            quantity: 1,
            tags: newTags,
          },
        },
      })
      // select new item
      dispatch(selectItem(newItemKey))
    } else {
      dispatch({
        type: isSetItem ? ActionTypes.TOGGLE_SET_ITEM_TAG : ActionTypes.TOGGLE_ITEM_TAG,
        payload: { tag },
      })
    }
  }
}

/**
 * @param {string} remark
 * @returns {ThunkFunction}
 */
export function updateRemark (remark) {
  return (dispatch, getState) => {
    const serial = getState().orderBatch.selectedSetItemKey?.serial
    const [selectedItem, selectedSetItem] = getSelectedBatchItem()
    const item = selectedSetItem || selectedItem
    const isSetItem = Boolean(item?.step)

    if (!item) return

    if (serial) {
      // quantity item 被選擇，新增成新 item
      // item quantity -1
      dispatch(addQuantity(-1))
      // add item with new remark
      const newItemKey = uuid()
      dispatch({
        type: ActionTypes.ADD_ITEM,
        payload: {
          item: {
            ...item,
            key: newItemKey,
            quantity: 1,
            remarks: [remark],
          },
        },
      })
      // select new item
      dispatch(selectItem(newItemKey))
    } else {
      if (isSetItem) {
        dispatch(updateSelectedSetItem(['remarks', 0], remark))
      } else {
        dispatch(updateSelectedItem(['remarks', 0], remark))
      }
    }
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetItems () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_ITEMS,
      payload: {},
    })
  }
}

/**
 * @param {string} itemKey
 * @returns {ThunkFunction}
 */
export function deleteItem (itemKey) {
  return (dispatch, getState) => {
    const selectedBatch = getSelectedBatch()
    const [selectedItem] = getSelectedBatchItem()

    const deleteItemIndex = selectedBatch.items.findIndex(stateItem => stateItem.key === itemKey)
    const deleteSetItemIndex = selectedItem?.setItems.findIndex(setItem => setItem.key === itemKey)
    if (deleteItemIndex >= 0) {
      // is delete in items
      if (selectedItem?.key === itemKey) {
        dispatch(deselectItems())
      }
      dispatch({
        type: ActionTypes.DELETE_ITEM,
        payload: { index: deleteItemIndex },
      })
    }
    if (deleteSetItemIndex >= 0) {
      // is delete setItem in selectedItem
      dispatch(selectItem(selectedItem.key, null))
      dispatch({
        type: ActionTypes.DELETE_SET_ITEM,
        payload: { index: deleteSetItemIndex },
      })
    }
  }
}

/**
 * @param {IPriceModifier} modifier
 * @returns {ThunkFunction}
 */
export function updateModifier (modifier) {
  return (dispatch, getState) => {
    if (modifier.amount === 0 && modifier.percent === 0) {
      dispatch({
        type: ActionTypes.REMOVE_MODIFIER,
        payload: { modifier },
      })
      return
    }

    dispatch({
      type: ActionTypes.UPDATE_MODIFIER,
      payload: { modifier },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetBatch () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_BATCH,
    })
  }
}

/**
 * 儲存現在的 selectedBatch 到 stashes，並清除 selectedBatch
 * @returns {ThunkFunction}
 */
export function stashBatch () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.STASH_BATCH,
    })

    // 清除 Batch
    dispatch(resetBatch())
    // 開新訂單
    dispatch(actions.order.createOrder())
  }
}

/**
 * 刪除指定的 stash
 * @param {IBatchHistory} batchStash
 * @returns {ThunkFunction}
 */
export function dropStash (batchStash) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DROP_STASH,
      payload: { batchStash },
    })
  }
}

/**
 * 複製 Stash 蓋掉 selectedBatch
 * @param {IBatchHistory} batchStash
 * @returns {ThunkFunction}
 */
export function applyStash (batchStash) {
  return (dispatch, getState) => {
    dispatch(actions.order.selectOrderById(batchStash.batch.orderId))
    dispatch({
      type: ActionTypes.APPLY_STASH,
      payload: { batchStash },
    })
  }
}

/**
 * 用指定的 stash 蓋掉 selectedBatch，並刪除指定的 stash
 * @param {IBatchHistory} batchStash
 * @returns {ThunkFunction}
 */
export function popStash (batchStash) {
  return (dispatch, getState) => {
    dispatch(actions.order.selectOrderById(batchStash.batch.orderId))
    dispatch({
      type: ActionTypes.POP_STASH,
      payload: { batchStash },
    })
  }
}

/**
 * 刪除全部的 stash
 * @returns {ThunkFunction}
 */
export function dropAllStash () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.DROP_ALL_STASH,
    })
  }
}
