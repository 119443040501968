import CategoryMenu from '@/components/CategoryMenu'
import SplitPanel from '@/components/SplitPanel'
import hooks from '@/hooks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Buttons from './Buttons'
import Result from './Result'

export default function Content (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const content = (
    <Box flex='1' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Result />
      <Buttons />
    </Box>
  )

  if (mode.isTablet) {
    return (
      <SplitPanel menu={<CategoryMenu />} content={content} />
    )
  }
  return (
    <main className={classes.contentContainer}>
      <Result />
      <Buttons />
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 16,
    backgroundColor: theme.palette.secondary.main,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}))
