import Alerts from '@/components/Alerts'
import BackdropLoading from '@/components/BackdropLoading'
import ChangeLangDialog from '@/components/dialogs/ChangeLangDialog'
import DeleteItemsDialog from '@/components/dialogs/DeleteItemsDialog'
import DeleteSetItemsDialog from '@/components/dialogs/DeleteSetItemsDialog'
import InputRemarkDialog from '@/components/dialogs/InputRemarkDialog'
import ServiceRequestDialog from '@/components/dialogs/ServiceRequestDialog'
import SetItemOptionDialog from '@/components/dialogs/SetItemOptionDialog'
import UpdateDialog from '@/components/dialogs/UpdateDialog'
import OptionDrawer from '@/components/drawers/OptionDrawer'
import hooks from '@/hooks'
import AppRouter from '@/pages/AppRouter'
import { useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import SetItemDrawer from '../components/drawers/StepDrawer/SetItemDrawer'

export default function Main (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const isLoading = useSelector(state => state.app.isLoading)
  const lang = useSelector(state => state.app.lang)

  return (
    <SnackbarProvider
      maxSnack={2}
      classes={{
        root: classes.snackbarItemContnetRoot,
        containerRoot: classes.snackabrContainerRoot,
      }}
    >
      <SetItemOptionDialog />
      <AppRouter />
      <Alerts />
      <BackdropLoading open={isLoading} />
      <ChangeLangDialog lang={lang} />
      <UpdateDialog />
      <ServiceRequestDialog />
      <DeleteItemsDialog />
      <DeleteSetItemsDialog />
      <InputRemarkDialog />
      <OptionDrawer />
      <SetItemDrawer />
    </SnackbarProvider>
  )
}

const useStyles = makeStyles(theme => ({
  snackabrContainerRoot: {
    minWidth: props => props.mode.isTablet && '60%',
  },
  snackbarItemContnetRoot: {
    // fontSize: props => props.mode.isTablet && '2.8rem',
    // '& > div': {
    //   fontSize: 'inherit',
    // },
    // '& .MuiSvgIcon-root': {
    //   fontSize: 'inherit !important',
    // },
  },
}))
