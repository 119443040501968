import _ from 'lodash'
import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IBatchItemTempState} */
export const initialState = {
  batchItemTemp: {},
  batchSetItemTemp: {},
}

export default produce(
  /**
   * @param {IBatchItemTempState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      // ======================== BatchItem ========================
      case ActionTypes.ADD_ITEM: {
        const { batchItem } = action.payload
        draft.batchItemTemp = batchItem
        break
      }
      case ActionTypes.ADD_SET_ITEM: {
        const { batchSetItem } = action.payload
        draft.batchSetItemTemp = batchSetItem
        break
      }
      case ActionTypes.UPDATE_ITEM: {
        const { path, value } = action.payload
        _.set(draft.batchItemTemp, path, value)
        break
      }
      case ActionTypes.UPDATE_SET_ITEM: {
        const { path, value } = action.payload
        _.set(draft.batchSetItemTemp, path, value)
        break
      }
      case ActionTypes.RESET_ITEM: {
        return initialState
      }
      case ActionTypes.RSET_SET_ITEM: {
        draft.batchSetItemTemp = initialState.batchSetItemTemp
        break
      }

      // ======================== Option ========================
      case ActionTypes.ADD_ITEM_OPTION: {
        const { option } = action.payload
        const options = draft.batchItemTemp.options
        options.push(option)
        const orderedOptions = _.orderBy(options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        draft.batchItemTemp.options = orderedOptions
        break
      }
      case ActionTypes.ADD_SET_ITEM_OPTION: {
        const { option } = action.payload
        const options = draft.batchSetItemTemp.options
        options.push(option)
        const orderedOptions = _.orderBy(options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        draft.batchSetItemTemp.options = orderedOptions
        break
      }
      case ActionTypes.UPDATE_ITEM_OPTIONS: {
        const { options } = action.payload
        const orderedOptions = _.orderBy(options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        draft.batchItemTemp.options = orderedOptions
        break
      }
      case ActionTypes.UPDATE_SET_ITEM_OPTIONS: {
        const { options } = action.payload
        const orderedOptions = _.orderBy(options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        draft.batchSetItemTemp.options = orderedOptions
        break
      }

      // ======================== Modifier ========================
      case ActionTypes.UPDATE_MODIFIER: {
        break
      }
      case ActionTypes.REMOVE_MODIFIER: {
        break
      }

      // ======================== Reset ========================
      case 'LOGOUT': {
        return initialState
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
