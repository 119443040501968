import ChangeTable from '@/pages/ChangeTable'
import Checkout from '@/pages/Checkout'
import Confirmation from '@/pages/Confirmation'
import History from '@/pages/History'
import Home from '@/pages/Home'
import Paid from '@/pages/Paid'
import Setting from '@/pages/Setting'
import { useSelector } from '@/redux'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

export default function AppRouter (props) {
  const selectedTable = useSelector(state => state.app.table)

  if (!selectedTable) {
    return <ChangeTable />
  }

  return (
    <Switch>
      <Route path='/setting' component={Setting} />
      <Route path='/changeTable' component={ChangeTable} />
      <Route path='/checkout' component={Checkout} />
      <Route path='/confirmation' component={Confirmation} />
      <Route path='/history' component={History} />
      <Route path='/paid' component={Paid} />
      <Route path='/' component={Home} />
    </Switch>
  )
}
