import AppBar from '@/components/AppBar'
import Page from '@/components/Page'
import PageHeader from '@/components/Page/PageHeader'
import { actions, useDispatch, useSelector } from '@/redux'
import { Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export default function PasswordInput (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const inputRef = React.useRef()
  const [passcode, setPasscode] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const history = useHistory()
  const table = useSelector(state => state.orderBatch.selectedBatch?.table)

  const handleSubmit = () => {
    if (passcode === '111091') {
      dispatch(actions.app.loginSetting(true))
    } else {
      setShowError(true)
      setPasscode('')
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  return (
    <Page>
      <AppBar handleBack={() => history.push('/')} />
      <PageHeader
        title={t('app.setting.enter_password')}
        subtitle={'版本:' + process.env.REACT_APP_VERSION + ' 枱號: ' + table}
      />
      <div className={classes.content}>
        <TextField
          autoFocus
          ref={inputRef}
          color='primary'
          className={classes.textField}
          InputProps={{
            type: 'password',
            inputMode: 'numeric',
            classes: {
              input: classes.input,
            },
          }}
          inputProps={{
            maxLength: 6,
          }}
          value={passcode}
          onChange={event => setPasscode(event.target.value)}
          onSubmit={handleSubmit}
          helperText={showError ? t('app.setting.passcode_error') : ''}
          error={showError}
        />
        <Button
          tabIndex={6}
          disabled={passcode.length !== 6}
          className={classes.button}
          variant='contained'
          color='primary'
          size='large'
          onClick={handleSubmit}
        >
          <Typography variant='h6'>
            {t('setting.password_submit')}
          </Typography>
        </Button>
      </div>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 64,
  },
  subTitle: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  codeInputContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    flex: 1,
    width: '100%',
    maxWidth: 300,
    margin: theme.spacing(2),
  },
  input: {
    fontSize: 30,
    letterSpacing: 20,
    flex: 1,
    width: '100%',
    maxWidth: 300,
    textAlign: 'center',
  },
  button: {
    alignSelf: 'center',
  },
}))
