import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg'
import constants from '@/constants'
import hooks from '@/hooks'
import { Box, ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CategoryItem from './CategoryItem'

// TODO sub category
// TODO scroll into view (ios?)
export default function CategoryBar (props) {
  const mode = hooks.app.useMode()
  const categories = hooks.menu.useCategories()
  const selectedCategory = hooks.menu.useSelectedCategory()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [isExpand, setExpand] = useState(false)
  const toggleExpand = () => setExpand(!isExpand)

  if (mode.isTablet) return null

  return (
    <Box flexShrink={0} display='flex' flexDirection='column'>

      {/* category bar */}
      <div className={classes.categoryBar}>
        {isExpand
          ? (
            <Typography className={classes.category} style={{ flex: 1, justifyContent: 'flex-start' }}>
              {t('app.home.category_bar.all_categories')}
            </Typography>
          )
          : (
            <div className={classes.categoriesContainer}>
              {_.map(categories, (category, index) => {
                const isSelected = selectedCategory?.id === category.id
                return <CategoryItem key={index} category={category} selected={isSelected} />
              })}
            </div>
          )}

        {/* expand/collapse button */}
        <ButtonBase className={classes.expandButton} onClick={toggleExpand}>
          <ArrowDownIcon className={clsx({ [classes.collapseIcon]: isExpand })} />
        </ButtonBase>
      </div>

      {isExpand
        ? ( // expanded category
          <div className={classes.expandedContainer}>
            {_.map(categories, (category, index) => {
              const isSelected = selectedCategory?.id === category.id
              return <CategoryItem key={index} category={category} selected={isSelected} expanded onClick={toggleExpand} />
            },
            )}
          </div>
        )
        : ( // sub categorybar
          <div className={clsx(classes.categoryBar, classes.categoriesContainer)}>
            {_.map(selectedCategory?.categories, (category, index) => {
              const isSelected = selectedCategory?.id === category.id
              return <CategoryItem key={index} category={category} selected={isSelected} />
            })}
          </div>
        )}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  categoryBar: {
    position: 'relative',
    flexShrink: 0,
    display: 'flex',
    height: constants.app.height.CATEGORYBAR,
    zIndex: constants.app.zIndex.CATEGORYBAR,
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },
  categoriesContainer: {
    flex: 1,
    display: 'flex',
    overflowX: 'auto',
    '& :last-child': {
      marginRight: 16,
    },
  },
  category: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 60,
    padding: '0px 16px',
    fontSize: '1.4rem',
    color: theme.palette.common.white,
  },
  expandButton: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: constants.app.height.CATEGORYBAR,
    boxShadow: `${theme.palette.common.white} 1px -1px 0px inset`,
  },
  collapseIcon: {
    transform: 'rotate(180deg)',
  },
  expandedContainer: {
    flexShrink: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    backgroundColor: theme.palette.primary.main,
  },
}))
