import Sold from '@/components/drawers/StepDrawer/GroupItem/rightActions/Sold'
import PropTypes from 'prop-types'
import React from 'react'
import GroupItemBase from './GroupItemBase'

GroupItemSold.propTypes = {
  item: PropTypes.object,
}

/**
* @component
* @param {{
* item: IMenuOptionItem
* }} props
* @returns {Node}
*/
export default function GroupItemSold (props) {
  const { item } = props

  return (
    <GroupItemBase item={item} rightAction={<Sold />} />
  )
}
