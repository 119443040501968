import AppBarComponent from '@/components/AppBar'
import hooks from '@/hooks'
import libs from '@/libs'
import { actions, useDispatch } from '@/redux'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export default function AppBar (props) {
  const mode = hooks.app.useMode()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  const handleBack = () => history.push('/')
  // const menuItemQuantities = useSelector(state => state.orderBatch.menuItemQuantities)
  const selectedOrder = hooks.order.useSelectedOrder()
  const selectedBatch = hooks.order.useSelectedBatch()
  const totalQuantities = selectedOrder && _.sumBy([...selectedOrder.batches, selectedBatch], (batch) => {
    const { quantity } = libs.order.calculateDisplayBatchTotal(batch, selectedOrder)
    return quantity
  })
  const isDisabled = !totalQuantities
  const handleClearAll = () => dispatch(actions.app.toggleAlert({
    title: t('app.alert.clear_orders.title_'),
    message: t('app.alert.clear_orders.message'),
    onConfirm: () => dispatch(actions.orderBatch.resetItems()),
  }))

  const rightContent = (
    <Button className={classes.button} onClick={handleClearAll} disabled={isDisabled}>
      <Typography className={clsx(classes.buttonText, { [classes.disabled]: isDisabled })}>
        {t('app.checkout.appbar.clear_all')}
      </Typography>
    </Button>
  )

  return (
    <AppBarComponent handleBack={handleBack} right={rightContent} />
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText,
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '2rem'
    },
    padding: '8px 12px',
  },
  buttonText: {
    fontSize: 'inherit',
  },
  disabled: {
    color: theme.palette.disabled.main,
  },
}))
