import CustomServiceRequestImage from '@/assets/custom/images/service-request.jpg'
import ServiceRequestButtonComponent from '@/components/Footer/ServiceRequestButton'
import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

export default function ServiceRequestButton (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <ServiceRequestButtonComponent
      image={CustomServiceRequestImage}
      imageProps={{ className: classes.customServiceRequestImage }}
      className={classes.customServiceRequestButton}
    />
  )
}

const useStyles = makeStyles(theme => ({
  customServiceRequestButton: {
    padding: props => {
      if (props.mode.isMobile) return 'unset'
      if (props.mode.isTablet) return '0px 40px'
    },
  },
  customServiceRequestImage: {
    width: 'unset',
  },
}))
