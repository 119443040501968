import hooks from '@/hooks'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

GroupHeader.propTypes = {
  title: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

/**
 * @component
 * @param {{
 * title:String
 * hint?: String | Node
 * }} props
 */
export default function GroupHeader (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { title, hint } = props

  return (
    <div>
      <Typography variant='body1' className={classes.title} color='error'>{title}</Typography>
      {hint && <Typography variant='body2' className={classes.hint}>{hint}</Typography>}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.4rem'
      if (props.mode.isTablet) return '2rem'
    },
    padding: props => {
      if (props.mode.isMobile) return '0px 8px'
      if (props.mode.isTablet) return '0px 12px'
    },
  },
  hint: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.2rem'
      if (props.mode.isTablet) return '1.6rem'
    },
    padding: props => {
      if (props.mode.isMobile) return '0px 8px'
      if (props.mode.isTablet) return '0px 12px'
    },
    color: theme.palette.warning.main,
    '& > *': {
      marginRight: 8,
    },
  },
}))
