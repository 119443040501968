import PropTypes from 'prop-types'
import React from 'react'
import GroupItemRadio from './variants/GroupItemRadio'
import GroupItemSold from './variants/GroupItemSold'

GroupItem.propTypes = {
  variant: PropTypes.oneOf(['radio', 'sold']),
  item: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  // disabled: PropTypes.bool,
}

/**
 *@component
 * @param {{
 * variant: 'radio' | 'sold'
 * item: IMenuOptionItem | IAppMenuItem
 * group?: IMenuOptionGroup
 * onClick?: Function
 * selected?: Boolean
 * disabled?: Boolean
 * }} props
 */
export default function GroupItem (props) {
  const { variant, item, selected, onClick } = props

  switch (variant) {
    case 'radio':
      return <GroupItemRadio item={item} onClick={onClick} selected={selected} />

    case 'sold':
      return <GroupItemSold item={item} />

    default:
      return null
  }
}
