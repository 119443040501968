import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import { List, ListItem, ListItemSecondaryAction, Radio, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ optionGroup: IMenuOptionGroup }} props
 */
export default function OptionGroup (props) {
  const { optionGroup } = props
  const { options } = optionGroup
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()
  const selectedItem = hooks.order.useSelectedBatchItem()[0]

  const renderMinMaxHint = (optionGroup) => {
    if (optionGroup?.min === 1 && optionGroup?.max === 1) {
      return <Typography component='span'>{t('app.common.item.option.min1max1')}</Typography>
    }
    return (
      <>
        {optionGroup?.min === 0 && <Typography component='span'>{t('app.common.item.option.min0')}</Typography>}
        {optionGroup?.min > 0 && <Typography component='span'>{t('app.common.item.option.min', { min: optionGroup?.min })}</Typography>}
        {optionGroup?.max > 0 && <Typography component='span'>{t('app.common.item.option.max', { max: optionGroup?.max })}</Typography>}
      </>
    )
  }

  return (
    <div className={classes.optionGroupContainer}>
      <Typography className={classes.groupName} color='error'>
        {optionGroup.name}
      </Typography>
      <Typography className={classes.groupMinMax}>
        {renderMinMaxHint(optionGroup)}
      </Typography>
      <List className={classes.list}>
        {_.map(options, (option, index) => {
          const isSelected = Boolean(_.find(selectedItem?.options, opt => {
            return opt.optionItemId === option.id
          }))
          return (
            <ListItem
              key={index}
              className={classes.option}
              onClick={() => dispatch(actions.orderBatch.updateOption(optionGroup, option))}
            >
              <Typography className={classes.optionName}>{option.name}</Typography>
              {Boolean(option.price) && <Typography className={classes.optionPrice}>{`(+$${option.price})`}</Typography>}
              <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryActionRoot }}>
                <Radio
                  color='primary'
                  icon={<RadioButtonUncheckedIcon className={classes.radioUncheckedIcon} />}
                  classes={{ root: classes.radio }}
                  checked={isSelected}
                  onClick={() => dispatch(actions.orderBatch.updateOption(optionGroup, option))}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  optionGroupContainer: {
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0px',
  },
  groupName: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.4rem'
      if (props.mode.isTablet) return '2rem'
    },
    padding: props => {
      if (props.mode.isMobile) return '0px 8px'
      if (props.mode.isTablet) return '0px 12px'
    },
  },
  groupMinMax: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.2rem'
      if (props.mode.isTablet) return '1.6rem'
    },
    padding: props => {
      if (props.mode.isMobile) return '0px 8px'
      if (props.mode.isTablet) return '0px 12px'
    },
    color: theme.palette.warning.main,
    '& > *': {
      marginRight: 8,
    },
  },
  list: {

  },
  option: {
    boxShadow: '0px 1px #E7E7E7',
    padding: props => {
      if (props.mode.isMobile) return '8px 48px 8px 8px'
      if (props.mode.isTablet) return '12px 48px 12px 12px'
    },
  },
  optionName: {
    fontSize: '1.8rem',
  },
  optionPrice: {
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
    marginLeft: 8,
  },
  radio: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  radioUncheckedIcon: {
    color: theme.palette.text.primary,
  },
  listItemSecondaryActionRoot: {
    right: 0,
  },
}))
