import { Divider, ListItemText } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SettingListItem from './SettingListItem'

export default function Version (props) {
  const { t } = useTranslation()
  const version = `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_ENV})`
  const sha = `${process.env.REACT_APP_GIT_SHA}`

  return (
    <>
      <SettingListItem>
        <ListItemText
          primary={t('app.setting.version')}
          secondary={version}
        />
      </SettingListItem>
      <Divider />
      <SettingListItem>
        <ListItemText
          primary='SHA'
          secondary={sha}
        />
      </SettingListItem>
      <Divider />
    </>
  )
}
