import i18n from '@/i18n'
import dimorderApi from '@/libs/api/dimorder'
import { isSetItemComplete } from '@/libs/order'
import { actions } from '@/redux'
import colors from '@/theme/colors'
import logger from '@root/src/libs/logger'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import ActionTypes from './ActionTypes'
import { initBatch } from './reducer'

/**
 * @param {string} orderId
 * @returns {ThunkFunction}
 */
export function selectOrderById (orderId) {
  return async (dispatch, getState) => {
    const orders = getState().orderHistory.orders
    const order = orders.find(order => order.id === orderId)
    dispatch(selectOrder(order))
  }
}

/**
 * @param {IAppOrder} targetOrder
 * @returns {ThunkFunction}
 */
export function selectOrder (targetOrder) {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SELECT_ORDER,
      payload: { order: targetOrder },
    })
  }
}

/**
 * @param {Partial<IAppOrder>} updateFields
 * @returns {ThunkFunction}
 */
export function updateSelectedOrderFields (updateFields) {
  return async (dispatch, getState) => {
    const surcharge = getState().merchant.data.surcharge
    const selectedOrder = getState().order.selectedOrder
    if (selectedOrder.orderSerial) {
      console.log('已送出的訂單無法直接修改')
      return
    }
    const updatedOrder = {
      ...selectedOrder,
      ...updateFields,
    }

    if (updatedOrder.deliveryType === 'table') {
      updatedOrder.surcharge = surcharge
    } else {
      updatedOrder.surcharge = { percent: 0, amount: 0 }
    }

    dispatch(selectOrder(updatedOrder))
  }
}

/**
 * @param {string?} orderId 有給 id 會從 orderHistory 中選擇
 * @param {string?} batchId 如果要指定 batch 可以給 batchId
 * @returns {ThunkFunction}
 */
export function startOrder (orderId, batchId) {
  return (dispatch, getState) => {
    if (orderId) {
      // 如果有給 orderId 從 orderHistory.orders 找出 order 來用
      const orders = getState().orderHistory.orders
      const order = orders.find(order => order.id === orderId)
      dispatch(selectOrder(order))
    }
    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch

    if (selectedBatch?.orderId === selectedOrder.id) {
      // 選擇的訂單沒有變
      const sendedOrderBatch = selectedOrder.batches.find(batch => batch.id === selectedBatch.batchId)
      if (!sendedOrderBatch && !selectedBatch.updatedAt) {
        // 選擇的 batch 還沒送出，可以直接繼續點餐
        return
      }
    }

    if (batchId) {
      const batch = selectedOrder.batches.find(batch => batch.id === batchId)
      if (!batch.updatedAt) {
        // batch 未送出，可以選擇 batch 繼續點餐
        dispatch(actions.orderBatch.selectBatch(batch))
        return
      }
    }

    // 選擇的 order 變了或是沒選 batch 或是選擇的 batch 已送出時
    // 新開一個 batch 來點餐
    const newBatchId = uuid()
    dispatch(actions.orderBatch.selectBatch({
      ...initBatch,
      id: newBatchId,
      orderId: selectedOrder.id,
      orderSerial: selectedOrder.serial,
      batchId: newBatchId,
      index: selectedOrder.batches.length,
      table: selectedOrder.table,
      status: 'pending',
      createdAt: new Date(),
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function createOrder () {
  return async (dispatch, getState) => {
    const merchantID = getState().merchant.data.id
    const table = getState().app.table
    if (!table) {
      return
    }

    const order = await dimorderApi.order.createOrder({
      merchantID,
      deliveryType: 'table',
      table,
      customerCount: 1,
      // create: true, // create === false is join
    })

    dispatch(selectOrder(order))
    dispatch(startOrder())
  }
}

/**
 * 當 submit order batch 失敗需重新建立訂單時使用
 * 1. 建立新的 order
 * 2. 複製舊的 batch 資料，並更改部分資料為新的 order
 * @returns {ThunkFunction}
 */
export function recreateOrder () {
  return async (dispatch, getState) => {
    const merchantID = getState().merchant.data.id
    const table = getState().app.table
    if (!table) {
      return
    }

    const order = await dimorderApi.order.createOrder({
      merchantID,
      deliveryType: 'table',
      table,
      customerCount: 1,
      // create: true, // create === false is join
    })

    dispatch(selectOrder(order))

    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch

    const newBatchId = uuid()
    dispatch(actions.orderBatch.selectBatch({
      ...selectedBatch,
      id: newBatchId,
      orderId: selectedOrder.id,
      orderSerial: selectedOrder.serial,
      batchId: newBatchId,
      index: selectedOrder.batches.length,
      table: selectedOrder.table,
      status: 'pending',
      createdAt: new Date(),
    }))
  }
}

/**
 * @returns {ThunkFunction}
 */
export function submitOrderBatch () {
  return async (dispatch, getState) => {
    const isInventoryEnabled = getState().merchant.data.setting.inventory
    const menus = getState().menu.menus
    const sets = getState().menu.sets
    const selectedOrder = getState().order.selectedOrder
    const selectedBatch = getState().orderBatch.selectedBatch
    const menuItemQuantities = getState().orderBatch.menuItemQuantities

    const errorMessages = []
    selectedBatch.items.forEach(item => {
      // 找出未完成的套餐
      if (!isSetItemComplete(item, sets[item.menuId])) {
        errorMessages.push(item.name + ' 套餐未完成')
      }
    })
    if (isInventoryEnabled) {
      // 找出庫存不足的品項
      _.forEach(menuItemQuantities, (orderedQuantity, menuId) => {
        const menu = menus[menuId]
        if (menu?.inventory && orderedQuantity > menu.inventory) {
          errorMessages.push(menu.name + ' 庫存不足')
        }
      })
    }

    if (errorMessages.length > 0) {
      dispatch(actions.app.toggleAlert({
        title: '送出失敗',
        messages: errorMessages,
      }))
      dispatch(actions.orderBatch.updateShowNotCompleteSets(true))
      return
    }

    if (!selectedOrder.orderSerial) {
      // 先 create order
      await dispatch(createOrder())
    }

    try {
      // submit batch
      const newOrder = await dimorderApi.order.submitBatch({
        orderId: selectedOrder.id,
        orderBatch: selectedBatch,
        deliveryType: selectedOrder.deliveryType,
        shipping: selectedOrder.shipping,
      })
      // update to selectedOrder
      dispatch(selectOrder(newOrder))
      // update to orderHistory.order
      dispatch(actions.orderHistory.updateOrder(newOrder))
    } catch (error) {
      if (error.response) {
        logger.error('submit error', { error: error.response.data })
        if (error.response.data?.error === 'cutoff order') {
          // 訂單建立時間距離送單時間太久，超過 orderCutOffMins
          // 發生這種情況時
          // 1. 重新建立一張訂單
          // 2. 取消舊的訂單
          // 3. 用新的訂單重送
          await dispatch(recreateOrder())
          await dimorderApi.axiosGo.put(`/c/order/${selectedOrder.id}/cancel`, { reason: 'bbq cutoff order' })
          await dispatch(submitOrderBatch())
        }

        if (error.response.data?.error === 'expired order') {
          dispatch(actions.app.toggleAlert({
            title: '訂單過期',
            message: '請重新開始',
          }))
        }
        if (error.response.data?.error === 'invalid timeslot') {
          dispatch(actions.app.toggleAlert({
            title: '非營業時間',
            message: '很抱歉現在非餐廳營業時間',
          }))
        }
        if (error.response.data?.error === 'submit too fast') {
          dispatch(actions.app.toggleAlert({
            title: '太快了',
            message: '慢一點',
          }))
        }
        if (error.response.data?.error === 'some items are not available') {
          // 庫存不足
          const { items, inventory } = error.response.data
          const itemNames = _.uniq(items.map(item => item.name))
          // TODO: 重新取得庫存資訊
          dispatch(actions.app.toggleAlert({
            title: '庫存不足',
            messages: ['唔好意思，以下您所選的商品已經售完。是否繼續提交訂單以購買其他商品？', ...itemNames],
            buttons: [
              {
                color: colors.info.main,
                text: '繼續',
                onClick: async () => {
                  // ignoreUnavailable 並繼續
                  const newOrder = await dimorderApi.order.submitBatch(selectedOrder.id, selectedBatch, selectedOrder.deliveryType, selectedOrder.shipping, {}, true)
                  // update to selectedOrder
                  dispatch(selectOrder(newOrder))
                  // update to orderHistory.order
                  dispatch(actions.orderHistory.updateOrder(newOrder))
                },
              },
              {
                text: '返回',
                onClick: () => {
                  dispatch(actions.orderBatch.updateSoldOutInventory(inventory))
                },
              },
            ],
          }))
        }
      }
      return
    }
    // ! DIFF: 繼續點餐
    dispatch(actions.order.startOrder())
    window.appHistory.push('/confirmation')
  }
}

/**
 * @param {IPriceModifier} modifier
 * @returns {ThunkFunction}
 */
export function updateModifier (modifier) {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder
    const modifiers = []
    if (modifier.amount !== 0 || modifier.percent !== 0) {
      modifiers.push(modifier)
    }

    const responseOrder = await dimorderApi.order.updateModifiers(selectedOrder.id, [])
    dispatch(selectOrder(responseOrder))
  }
}

/**
 * @param {string} itemId
 * @param {number} price
 * @returns {ThunkFunction}
 */
export function updateItemPrice (itemId, price) {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder

    const responseOrder = await dimorderApi.order.updateItemPrice(selectedOrder.id, itemId, price)
    dispatch(selectOrder(responseOrder))
  }
}

// ! DIFF: 新增
/**
 *
 * @param {('pay' | 'waiter')} type
 */
export function requestService (type) {
  return async (dispatch, getState) => {
    const selectedOrder = getState().order.selectedOrder
    if (!selectedOrder) return

    const requestServiceTime = getState().order.requestServiceTime
    const canRequest = requestServiceTime == null || moment().isAfter(moment(requestServiceTime).add(1, 'minute'))

    if (canRequest) {
      if (type === 'pay') {
        dispatch(actions.app.toggleAlert({
          message: i18n.t('app.snackbar.request_service.pay'),
        }))
        await dimorderApi.order.requestPaying(selectedOrder.id)
      }
      if (type === 'waiter') {
        dispatch(actions.app.toggleAlert({
          message: i18n.t('app.snackbar.request_service.waiter'),
        }))
        await dimorderApi.order.requestService(selectedOrder.id)
      }
      dispatch({
        type: ActionTypes.UPDATE_REQUEST_SERVICE_TIME,
        payload: { time: new Date() },
      })
    } else {
      dispatch(actions.app.toggleAlert({
        title: '太快了',
        message: '慢一點',
      }))
    }
  }
}
