import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import { MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @param {{
 * lang: string,
 * selected: boolean,
 * onClose: function,
 * }} props
 */
export default function LangOption (props) {
  const { lang, selected, onClose } = props
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode, selected })

  const changeLanguage = (lang) => {
    dispatch(actions.app.updateLang(lang))
    onClose()
  }

  return (
    <MenuItem onClick={() => changeLanguage(lang)}>
      <Typography className={classes.optionText}>{lang}</Typography>
    </MenuItem>
  )
}

const useStyles = makeStyles(theme => ({
  optionText: {
    minWidth: 200,
    fontSize: '1.6rem',
    textAlign: 'center',
    padding: '8px 16px',
    fontWeight: props => props.selected && 'bold',
    color: props => props.selected && theme.palette.warning.main,
  },
}))
