import { Divider, IconButton, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SettingListItem from './SettingListItem'

export default function (props) {
  const { t } = useTranslation()
  const handleClick = () => { window.location.href = '/' }

  return (
    <>
      <SettingListItem onClick={handleClick}>
        <ListItemText primary={t('app.setting.reload')} />
        <ListItemSecondaryAction>
          <IconButton edge='end' onClick={handleClick}>
            <RefreshIcon fontSize='large' />
          </IconButton>
        </ListItemSecondaryAction>
      </SettingListItem>
      <Divider />
    </>
  )
}
