import PriceLabel from '@/components/PriceLabel'
import Stepper from '@/components/Stepper'
import constants from '@/constants'
import libs from '@/libs'
import { getMenuImage } from '@/libs/menu'
import { actions, useDispatch } from '@/redux'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

MenuItem.propTypes = {
  menu: PropTypes.object,
  setItemMenu: PropTypes.object,
}

/**
 * @param {{
 * size?: 'sm' | 'md' | 'lg',
 * menu: IAppMenuItem
 * setItemMenu?: IAppMenuItem
 * }} props
 */
export default function MenuItem (props) {
  const { size = 'md', menu, setItemMenu } = props
  const {
    desc,
    price,
  } = menu
  const name = setItemMenu ? setItemMenu.name : menu.name
  const _menu = !_.isEmpty(setItemMenu) ? setItemMenu : menu
  const image = libs.cloudinary.transformation(getMenuImage(_menu), 'w_1000') || constants.app.image.EMPTY_IMAGE
  const dispatch = useDispatch()
  const stepperRef = useRef()
  const [ref, setRef] = useState()
  useEffect(() => {
    setRef(stepperRef)
  }, [stepperRef])
  const classes = useStyles({ ...props, image, stepper: ref?.current })

  const handleShowDetail = () => {
    dispatch(actions.app.toggleAlert({
      dialogProps: { classes: { paper: classes.dialogPaper } },
      title: name,
      message: desc,
      children: (
        <Box
          flexShrink={0}
          display='flex'
          alignItems='center'
          justifyContent='center'
          style={{ marginTop: 16 }}
        >
          {image
            ? <div className={classes.menuItemImage} style={{ width: '60vh' }} />
            : <div className={classes.emptyImage} />}
        </Box>
      ),
    }))
  }

  switch (size) {
    case 'md':
      return (
        <Grid item xs={6} className={classes.gridItem}>
          <div className={classes.menuItemContainer}>
            {image
              ? <div className={classes.menuItemImage} onClick={handleShowDetail} />
              : <div className={classes.emptyImage} onClick={handleShowDetail} />}
            <div className={clsx(classes.panel)}>
              <Stepper
                ref={stepperRef}
                size={size}
                shadow
                className={classes.stepper}
                menu={menu}
                setItemMenu={setItemMenu}
              />
              <section className={clsx(classes.pannelSection, classes.pannelSectionLeft)}>
                <Typography className={classes.menuItemName}>
                  {name}
                </Typography>
              </section>
              <section className={clsx(classes.pannelSection, classes.pannelSectionRight)}>
                <PriceLabel price={price} fontSize='1.4rem' signSize='1.2rem' />
              </section>
            </div>
          </div>
        </Grid>
      )
    case 'lg':
      return (
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.menuItemContainer}>
            {image
              ? <div className={classes.menuItemImage} onClick={handleShowDetail} />
              : <div className={classes.emptyImage} onClick={handleShowDetail} />}
            <div className={clsx(classes.panel)}>
              <Stepper
                ref={stepperRef}
                size={size}
                shadow
                className={classes.stepper}
                menu={menu}
                setItemMenu={setItemMenu}
              />
              <section className={clsx(classes.pannelSection, classes.pannelSectionLeft)}>
                <Typography className={classes.menuItemName}>
                  {name}
                </Typography>
              </section>
              <section className={clsx(classes.pannelSection, classes.pannelSectionRight)}>
                <PriceLabel price={price} fontSize='1.6rem' signSize='1.4rem' />
              </section>
            </div>
          </div>
        </Grid>
      )
    default:
      break
  }
}
const useStyles = makeStyles(theme => ({
  gridItem: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  menuItemContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
  },
  emptyImage: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%', // aspect ratio 1:1
    backgroundColor: theme.palette.grey[200],
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      opacity: 0.15,
      backgroundImage: `url(${constants.app.image.EMPTY_IMAGE})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  menuItemImage: {
    width: '100%',
    paddingTop: '100%',
    backgroundImage: props => {
      const url = libs.cloudinary.transformation(props.image, `w_${constants.app.image['MENU/IMAGE_WIDTH']}`)
      return `url(${url})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  panel: {
    position: 'relative',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    padding: props => {
      if (props.size === 'md') return '24px 16px 16px 16px'
      if (props.size === 'lg') return 24
    },
    height: props => {
      if (props.size === 'md') return 104
      if (props.size === 'lg') return 96
    },
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
  },
  pannelSection: {
    display: 'flex',
    alignSelf: 'stretch',
    boxSizing: 'border-box',
  },
  pannelSectionLeft: {
    flex: 8,
    overflow: 'hidden',
  },
  pannelSectionRight: {
    flex: 4,
    justifyContent: 'center',
  },
  stepper: {
    position: 'absolute',
    left: '50%',
    // 避免 clientHeight undefined 造成 top, marginLeft NaN
    top: props => -(props.stepper?.clientHeight ?? 0) / 2,
    marginLeft: props => -(props.stepper?.clientWidth ?? 0) / 2,
    // 避免 clientWidth 出來前 top 和 marginLeft 顯示不正確的位置
    visibility: props => props.stepper?.clientWidth ? 'visible' : 'hidden',
  },
  menuItemName: {
    color: theme.palette.text.primaryDark,
    fontWeight: 700,
    fontSize: props => {
      if (props.size === 'md') return '1.4rem'
      if (props.size === 'lg') return '1.6rem'
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
  },
  dialogPaper: {
    minWidth: '80%',
  },
}))
