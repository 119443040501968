import SwipeableDrawer from '@/components/SwipeableDrawer'
import hooks from '@/hooks'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import DrawerContent from './DrawerContent'

export default function OptionDrawer (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.drawers.option)
  const [selectedItem] = hooks.order.useSelectedBatchItem()

  const toggleDrawer = () => dispatch(actions.app.toggleDrawer('option'))
  const handleClose = () => {
    dispatch(actions.orderBatch.deleteItem(selectedItem.key))
    toggleDrawer()
  }

  return (
    <SwipeableDrawer
      open={open}
      onOpen={toggleDrawer}
      onClose={handleClose}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
    >
      <DrawerContent />
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 66px)',
  },
}))
