import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

/**
* @component
* @param {*} props
* @returns {Node}
*/
export default function Sold (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Typography
      color='textSecondary'
      className={classes.sold}
    >
      {t('app.common.sold_out')}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  sold: {
    fontWeight: 'bold',
    padding: theme.spacing(0, 1),
    textAlign: 'center',
  },
}))
