import store from '@/redux/store'
import orderappLibs from '@dimorder/orderapp-lib/dist/libs'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

/** @type {() => IRootState} */
const getState = store.getState

/**
 * @returns {IAppOrderBatch}
 */
export function getSelectedBatch () {
  return getState().orderBatch.selectedBatch
}

/**
 * @returns {[IAppBatchItem, IAppBatchItem]}
 */
export function getSelectedBatchItem () {
  const selectedItemKey = getState().orderBatch.selectedItemKey
  const selectedSetItemKey = getState().orderBatch.selectedSetItemKey

  const selectedBatch = getState().orderBatch.selectedBatch
  const selectedItem = selectedBatch?.items.find(item => item.key === selectedItemKey)
  const selectedSetItem = selectedItem?.setItems?.find(setItem => setItem.key === selectedSetItemKey)

  return [selectedItem, selectedSetItem]
}

/**
 * @param {IAppBatchItem} item
 * @returns {IFormatedBatchItem}
 */
export function getDisplayBatchItem (item) {
  const selectedOrder = getState().order.selectedOrder
  return orderappLibs.calculateBatchItem(orderappLibs.formatBatchItemStatus(item), selectedOrder)
}
/**
 * 計算套餐內的所選步驟的餐點總數量
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function calculateStepQuantityTotal (item, setStep) {
  const setItems = item?.setItems
  const stepQuantityTotal = _.reduce(setItems, (sum, setItem) => {
    if (setItem?.step === setStep?.id) {
      return sum + setItem?.quantity
    }
    return sum
  }, 0)
  return stepQuantityTotal
}

/**
 * 計算套餐內的所選步驟的餐點總數量上限
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function getStepQuantityMax (item, setStep) {
  const stepQuantityLimit = setStep && (setStep?.max || setStep?.min || 1)
  return stepQuantityLimit * item?.quantity || 1
}

/**
 * 計算套餐內的所選步驟的餐點總數量上限
 * @param {ISetStep} setStep
 * @returns {number}}
 */
export function getStepQuantityMin (item, setStep) {
  const stepQuantityLimit = setStep && (setStep?.min)
  return stepQuantityLimit * item?.quantity
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已達步驟上限
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @returns {boolean}
 */
export function calculateStepIsMax (item, setStep) {
  if (item?.isSet) {
    const stepQuantityMax = getStepQuantityMax(item, setStep)
    const stepQuantityTotal = calculateStepQuantityTotal(item, setStep)
    const isMax = Boolean(stepQuantityMax) && stepQuantityTotal >= stepQuantityMax
    return isMax
  }
}

/**
 * 判斷套餐內的所選步驟的餐點總數量是否已完成
 * @param {IAppBatchItem} item
 * @param {ISetStep} setStep
 * @returns {boolean}
 */
export function calculateStepIsComplete (item, setStep) {
  if (item?.isSet) {
    const stepQuantityMin = getStepQuantityMin(item, setStep)
    const stepQuantityTotal = calculateStepQuantityTotal(item, setStep)
    const isComplete = /* Boolean(stepQuantityMin) && */ stepQuantityTotal >= stepQuantityMin
    return isComplete
  }
}

/**
 * 判斷選項是否完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppMenuItem} menu
 * @returns {boolean}
 */
export function isOptionsComplete (batchItem, menu) {
  let isComplete = true
  menu.options.forEach(menuOptionGroup => {
    if (menuOptionGroup.min > 0) {
      const optionGroupOptions = batchItem.options.filter(optionGroup => optionGroup.optionGroupId === menuOptionGroup.id)
      const optionGroupQuantity = _.sumBy(optionGroupOptions, 'quantity')
      if (optionGroupQuantity < menuOptionGroup.min) {
        isComplete = false
      }
    }
  })

  return isComplete
}

/**
 * 判斷套餐是否已完成
 * @param {IAppBatchItem} batchItem
 * @param {IAppSet} set
 * @returns {boolean}
 */
export function isSetItemComplete (batchItem, set) {
  if (!batchItem.isSet) {
    // 非套餐，直接算完成
    return true
  }

  const notCompleteStep = set?.steps?.find(step => {
    return !calculateStepIsComplete(batchItem, step)
  })

  return !notCompleteStep
}

/**
 * @function
 * @param {IAppMenuItem | IAppSet} menu
 * @returns {IAppBatchItem | {}}
 */
export function createBatchItem (menu) {
  if (!menu) return {}
  const batchItem = {
    key: uuid(),
    setId: menu.isSet ? menu.id : undefined,
    menuId: menu.id,
    categoryId: menu.categoryId,
    name: menu.name,
    desc: menu.desc,
    quantity: 1,
    price: menu.price || 0,
    priceUndetermined: menu.priceUndetermined,
    discount: menu.discount || 0,
    options: [],
    tags: [],
    remarks: [],
    setItems: [],
    modifiers: [],
    isSet: menu.isSet,
  }
  return batchItem
}

/**
 * @function
 * @param {IAppMenuItem | IAppSet} setItemMenu
 * @returns {IAppBatchItem | {}}
 */
export function createBatchSetItem (setItemMenu) {
  if (!setItemMenu) return {}
  const batchSetItem = {
    key: uuid(),
    menuId: setItemMenu.menuId, // set menu 原始的 menu id
    setMenuId: setItemMenu.id, // set menu 自己的 id
    categoryId: setItemMenu.categoryId,
    name: setItemMenu.name,
    desc: setItemMenu.desc,
    price: setItemMenu.price || 0,
    priceUndetermined: setItemMenu.priceUndetermined,
    quantity: 1,
    options: [],
    tags: [],
    discount: 0,
    remarks: [],
    setItems: [],
    modifiers: [],
    step: setItemMenu.step,
    setMenuIndex: null,
    isRequired: false,
  }
  return batchSetItem
}

/**
 * @function
 * @param {IAppOrderBatch} selectedBatch
 * @param {IAppOrder} selectedOrder
 * @returns {{
 *  quantity: number
 *  totalDiscount: number
 *  total: number
 *  discountTotal: number
 *  originalTotal: number
 * }}
 */
export function calculateDisplayBatchTotal (selectedBatch, selectedOrder) {
  let quantity = 0
  let discountTotal = 0
  let total = 0
  let originalTotal = 0

  _.forEach(selectedBatch?.items, item => {
    const calculatedItem = orderappLibs.calculateBatchItem(item, selectedOrder)
    quantity += item.quantity
    discountTotal += calculatedItem.totalDiscount
    total += calculatedItem.total
  })
  originalTotal = total + discountTotal

  return {
    quantity,
    total,
    discountTotal, // Modifiers 不含 menu discount
    originalTotal, // total + discountTotal
  }
}

/**
 * @function
 * 移除『承接餐單』
 * 例：
 * step1 『漢堡』 （經典漢堡、炸雞漢堡）
 * step2 『選擇套餐』（沙拉、炸物）
 * step3 『沙拉』（水果沙拉、雞肉沙拉） <dependsOnSetMenuItem: 沙拉>
 * step4 『炸物』（薯條、可樂餅）  <dependsOnSetMenuItem: 炸物>
 * step5 『飲料』（可樂、紅茶、咖啡）
 * 若 step2 已選擇沙拉，且 step3 已選擇水果沙拉
 * 當使用者移除 step2 的沙拉時，應同時移除 step3 的水果沙拉
 * @param {string} id
 * @param {IAppBatchItem[]} setItems
 */
export function deleteDependentItem (id, setItems) {
  const setMenu = getState().app.drawers.setItem.data.setMenu
  const batchItem = getState().app.drawers.setItem.data.batchItem
  const sets = getState().menu.sets
  _.remove(setItems, (setItem, index) => {
    // batchItem 代表在 checkout 編輯
    const steps = batchItem ? sets[batchItem.menuId].steps : setMenu.steps
    const stepId = setItem.step
    const step = _.find(steps, step => step.id === stepId)
    const dependentItemId = step.dependsOnSetMenuItem
    if (dependentItemId === id) return true
  })
}

export default {
  getSelectedBatch,
  getSelectedBatchItem,
  getDisplayBatchItem,
  calculateStepQuantityTotal,
  getStepQuantityMax,
  getStepQuantityMin,
  calculateStepIsMax,
  calculateStepIsComplete,
  calculateDisplayBatchTotal,
  isOptionsComplete,
  isSetItemComplete,
  createBatchItem,
  createBatchSetItem,
}
