import PriceLabel from '@/components/PriceLabel'
import hooks from '@/hooks'
import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * name: string,
 * total: number
 * }} props
 */
export default function DrawerHeader (props) {
  const { name, total } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <div className={classes.headerContainer}>
      <div className={clsx(classes.headerSection, classes.headerSectionLeft)}>
        <Typography className={classes.headerText}>
          {name}
        </Typography>
        <span className={classes.label} />
      </div>
      <div className={clsx(classes.headerSection, classes.headerSectionRight)}>
        <PriceLabel
          price={total}
          fontSize={mode.isMobile ? '1.8rem' : '2.4rem'}
          signSize={mode.isMobile ? '1.6rem' : '2rem'}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  headerContainer: {
    flexShrink: 0,
    display: 'flex',
  },
  headerSection: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  headerSectionLeft: {
    flex: 8,
    position: 'relative',
    overflow: 'visible',
  },
  headerSectionRight: {
    flex: 4,
    justifyContent: props => {
      if (props.mode.isMobile) return 'flex-end'
      if (props.mode.isTablet) return 'flex-end'
    },
    marginRight: 8,
  },
  headerText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.8rem'
      if (props.mode.isTablet) return '2.2rem'
    },
    fontWeight: 'bold',
  },
  label: {
    zIndex: -1,
    position: 'absolute',
    left: 0,
    top: props => {
      if (props.mode.isMobile) return 18
      if (props.mode.isTablet) return 20
    },
    width: props => {
      if (props.mode.isMobile) return 40
      if (props.mode.isTablet) return 60
    },
    height: props => {
      if (props.mode.isMobile) return 10
      if (props.mode.isTablet) return 16
    },
    marginLeft: props => {
      if (props.mode.isMobile) return -16
      if (props.mode.isTablet) return -24
    },
    borderRadius: '0px 800px 800px 0px',
    backgroundColor: colors.components.label.item,
  },
}))
