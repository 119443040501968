export default {
  SHOW_NOT_COMPLETE_SETS: 'BATCH/SHOW_NOT_COMPLETE_SETS',
  UNDO_BATCH: 'BATCH/UNDO_BATCH',
  REDO_BATCH: 'BATCH/REDO_BATCH',
  SELECT_BATCH: 'BATCH/SELECT_BATCH',
  ADD_ITEM: 'BATCH/ADD_ITEM',
  SELECT_ITEM: 'BATCH/SELECT_ITEM',
  UPDATE_ITEM: 'BATCH/UPDATE_ITEM',
  ADD_ITEM_OPTION: 'BATCH/ADD_ITEM_OPTION',
  UPDATE_ITEM_OPTION: 'BATCH/UPDATE_ITEM_OPTION',
  DELETE_ITEM_OPTION: 'BATCH/DELETE_ITEM_OPTION',
  TOGGLE_ITEM_TAG: 'BATCH/TOGGLE_ITEM_TAG',
  DELETE_ITEM: 'BATCH/DELETE_ITEM',
  RESET_ITEMS: 'BATCH/RESET_ITEMS',
  ADD_SET_ITEM: 'BATCH/ADD_SET_ITEM',
  SELECT_SET_ITEM: 'BATCH/SELECT_SET_ITEM',
  UPDATE_SET_ITEM: 'BATCH/UPDATE_SET_ITEM',
  ADD_SET_ITEM_OPTION: 'BATCH/ADD_SET_ITEM_OPTION',
  UPDATE_SET_ITEM_OPTION: 'BATCH/UPDATE_SET_ITEM_OPTION',
  DELETE_SET_ITEM_OPTION: 'BATCH/DELETE_SET_ITEM_OPTION',
  TOGGLE_SET_ITEM_TAG: 'BATCH/TOGGLE_SET_ITEM_TAG',
  DELETE_SET_ITEM: 'BATCH/DELETE_SET_ITEM',
  UPDATE_MODIFIER: 'BATCH/UPDATE_MODIFIER',
  REMOVE_MODIFIER: 'BATCH/REMOVE_MODIFIER',
  RESET_BATCH: 'BATCH/RESET_BATCH',
  STASH_BATCH: 'BATCH/STASH_BATCH',
  DROP_STASH: 'BATCH/DROP_STASH',
  APPLY_STASH: 'BATCH/APPLY_STASH',
  POP_STASH: 'BATCH/POP_STASH',
  DROP_ALL_STASH: 'BATCH/DROP_ALL_STASH',
}
