import hooks from '@/hooks'
import SubmitButtonCheckout from '@/pages/Checkout/Footer/SubmitButton'
import SubmitButtonHome from '@/pages/Home/Footer/SubmitButton'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * page: 'checkout',
 * totalQuantities: number,
 * }} props
 */
export default function SubmitButton (props) {
  const { page, totalQuantities } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  switch (page) {
    case 'checkout': {
      return (
        <SubmitButtonCheckout
          className={clsx(
            { [classes.customColorTablet]: mode.isTablet },
            { [classes.customColorMobile]: mode.isMobile },
          )}
        />
      )
    }
    default: {
      return (
        <SubmitButtonHome
          totalQuantities={totalQuantities}
          className={clsx(
            { [classes.customColorTablet]: mode.isTablet },
            { [classes.customColorMobile]: mode.isMobile },
          )}
        />
      )
    }
  }
}

const useStyles = makeStyles(theme => ({
  customColorTablet: {
    backgroundColor: '#E73C0D',
  },
  customColorMobile: {
    backgroundColor: '#F2D40D',
    color: theme.palette.text.primaryDark,
    fontWeight: 'normal',
  },
}))
