import { actions, useDispatch, useSelector } from '@/redux'
import _ from 'lodash'
import React from 'react'
import Alert from './Alert'

export default function Alerts (props) {
  const dispatch = useDispatch()
  const alerts = useSelector(state => state.app.alerts)
  const closeAlert = (id) => dispatch(actions.app.toggleAlert(undefined, id, false))
  const openingAlerts = _.filter(alerts, alert => alert.open)

  return (
    _.map(openingAlerts, (alert, index) => {
      const { id, alertConfig } = alert
      return (
        <Alert
          key={index}
          onClose={() => closeAlert(id)}
          alertConfig={alertConfig}
        />
      )
    })
  )
}
