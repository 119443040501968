import PriceLabel from '@/components/PriceLabel'
import hooks from '@/hooks'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {{item: IOrderBatchItem}} props
 */
export default function OrderHistoryItem (props) {
  const { name, quantity, total } = props.item
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <div className={classes.orderHistoryItemContainer}>
      <Typography className={classes.itemQuantity} color='inherit'>
        {t('app.common.item.quantity', { quantity })}
      </Typography>
      <Box display='flex'>
        <Typography className={classes.itemName} color='inherit'>
          {name}
        </Typography>
        <PriceLabel
          price={total}
          fontSize={mode.isMobile ? '2rem' : '2.2rem'}
          signSize={mode.isMobile ? '1.4rem' : '1.6rem'}
        />
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  orderHistoryItemContainer: {
    position: 'relative',
    zIndex: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primaryDark,
    padding: 8,
    marginLeft: props => {
      if (props.mode.isMobile) return 8
      if (props.mode.isTablet) return 'unset'
    },
    boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
  },
  itemQuantity: {
    fontSize: '1.2rem',
    color: `${theme.palette.text.primaryDark}${opacity[80]}`,
  },
  itemName: {
    flex: 1,
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '1.8rem'
    },
  },
}))
