import ServiceRequestImage from '@/assets/images/footer/service-request.png'
import constants from '@/constants'
import hooks from '@/hooks'
import { actions, useDispatch, useSelector } from '@/redux'
import { Box, ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * image?: string,
 * imageProps?: object,
 * className?: string,
 * }} props
 */
export default function ServiceRequestButton (props) {
  const { image, imageProps, className } = props
  const mode = hooks.app.useMode()
  const dispatch = useDispatch()
  const classes = useStyles({ ...props, mode })
  const handleClick = () => dispatch(actions.app.toggleDialog('serviceRequest'))
  const display = useSelector(state => state.merchant.data?.setting?.waiter)

  if (!display) return null
  return (
    <ButtonBase onClick={handleClick} className={clsx(classes.serviceRequestButton, className)}>
      <Box flex={1} display='flex'>
        <img
          src={image ?? ServiceRequestImage}
          alt='service-request-image'
          className={clsx(classes.serviceRequestImage, imageProps?.className)}
        />
      </Box>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  serviceRequestButton: {},
  serviceRequestImage: {
    boxSizing: 'border-box',
    width: props => props.mode.isMobile && 128,
    height: props => {
      if (props.mode.isMobile) return constants.app.height['FOOTER--MOBILE']
      if (props.mode.isTablet) return constants.app.height['FOOTER--TABLET']
    },
    padding: props => props.mode.isMobile && '8px 16px',
  },
}))
