import constants from '@/constants'
import { useSelector } from '@/redux'
import customColors from '@/theme/colors/custom'
import { useSnackbar } from 'notistack'

export function useMode () {
  return useSelector(state => state.app.mode)
}

export function useCustomColors () {
  return customColors
}

export function useShowSnackbar () {
  const { enqueueSnackbar } = useSnackbar()
  /**
   * @param {string} message
   * @param {'success' | 'error' | 'warning' | 'default' | null} variant
   * @param {object} options
   */
  const showSnackbar = (message, variant, options) => {
    enqueueSnackbar(message, { ...constants.app.snackbar.OPTIONS, ...options, variant })
  }
  return showSnackbar
}

export default {
  useMode,
  useCustomColors,
  useShowSnackbar,
}
