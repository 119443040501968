import libs from '@/libs'
import _ from 'lodash'
import ActionTypes from './ActionTypes'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function resetItem () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RESET_ITEM,
      payload: {},
    })
  }
}

/**
 * @function
 * @returns {ThunkFunction}
 */
export function resetSetItem () {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.RSET_SET_ITEM,
      payload: {},
    })
  }
}

/**
 * @function
 * 如果有 menu 代表在 /restaurant 點餐
 * 如果有 batchItem 代表在 /checkout 編輯
 * @param {IAppMenuItem | IAppSet?} menu
 * @param {IAppBatchItem?} batchItem
 * @returns {ThunkFunction}
 */
export function addItem (menu, batchItem) {
  return (dispatch, getState) => {
    const isSet = menu?.isSet
    const isEditing = !_.isEmpty(batchItem)
    dispatch({
      type: ActionTypes.ADD_ITEM,
      payload: {
        batchItem: isEditing
          ? batchItem
          : libs.order.createBatchItem(menu),
      },
    })

    // 如果是在 /restaurant 點套餐的話自動加入必點項目
    // 如果是在 /checkout 編輯的話不需要
    if (isSet && !isEditing) {
      dispatch(addRequiredSetItem(menu))
    }
  }
}

/**
 * @param {IAppSet} set
 * @returns {ThunkFunction}
 */
export function addRequiredSetItem (set) {
  return (dispatch, getState) => {
    // 找出套餐中所有必點項目，轉換成 batchItem（必點幾個就會加入幾個）
    /** @type {IAppMenuItem[]} */
    const requiredBatchItems = []
    set.menus.forEach(menuItem => {
      _.times(menuItem.min, () => {
        requiredBatchItems.push(libs.order.createBatchSetItem(menuItem))
      })
    })

    dispatch(updateItem('setItems', requiredBatchItems))
  }
}

/**
 * @function
 * @param {IAppMenuItem} menu
 * @returns {ThunkFunction}
 */
export function addSetItem (menu) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_SET_ITEM,
      payload: { batchSetItem: libs.order.createBatchSetItem(menu) },
    })
  }
}

/**
 * Use lodash.set to update batchItemTemp
 * @function
 * @param {PropertyPath} path
 * @param {*} value
 * @returns {ThunkFunction}
 */
export function updateItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM,
      payload: { path, value },
    })
  }
}

/**
 * Use lodash.set to update batchSetItemTemp
 * @function
 * @param {PropertyPath} path
 * @param {*} value
 * @returns {ThunkFunction}
 */
export function updateSetItem (path, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM,
      payload: { path, value },
    })
  }
}

/**
 * @function
 * @param {IMenuOptionItem} option
 * @returns {ThunkFunction}
 */
export function addItemOption (option) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_ITEM_OPTION,
      payload: { option },
    })
  }
}

/**
 * @function
 * @param {IMenuOptionItem} option
 * @returns {ThunkFunction}
 */
export function addSetItemOption (option) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.ADD_SET_ITEM_OPTION,
      payload: { option },
    })
  }
}

/**
 * @function
 * @param {IMenuOptionItem[]} options
 * @returns {ThunkFunction}
 */
export function updateItemOptions (options) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_ITEM_OPTIONS,
      payload: { options },
    })
  }
}

/**
 * @function
 * @param {IMenuOptionItem[]} options
 * @returns {ThunkFunction}
 */
export function updateSetItemOptions (options) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UPDATE_SET_ITEM_OPTIONS,
      payload: { options },
    })
  }
}

/**
  * @function
  * @param {IMenuOptionItem} option
  * @param {IMenuOptionGroup} group
  * @param {Boolean} isSet
  * @param {IMenuOptionItem?} tempOption
  * @param {number?} increaseQuantity
  * @returns {ThunkFunction}
  */
export function updateOptions (option, group, isSet, tempOption, increaseQuantity) {
  return (dispatch, getState) => {
    const temp = isSet
      ? getState().batchItemTemp.batchSetItemTemp
      : getState().batchItemTemp.batchItemTemp

    const newOption = {
      optionGroupId: group.id,
      optionGroupName: group.name,
      optionItemId: option.id,
      name: option.name,
      price: option.price,
      quantity: (tempOption?.quantity ?? 0) + (increaseQuantity ?? 1),
    }

    const prevOptions = [...temp.options]
    const isMultiple = group.multiple && group.max !== 1
    const isStepper = isMultiple && (option.max > 1 || option.max === 0) // option.max === 1 為單選
    const isSelected = Boolean(_.find(prevOptions, prevOption => prevOption.optionItemId === option.id))

    // ======================== Stepper ========================
    if (isStepper) {
      const index = _.findIndex(prevOptions, opt => opt.optionItemId === option.id)
      if (index >= 0) {
        if (newOption.quantity > 0) {
          // 替換成 newOption
          prevOptions.splice(index, 1, newOption)
        } else {
          // 刪除
          prevOptions.splice(index, 1)
        }
      } else {
        // 新增 newOption
        prevOptions.push(newOption)
      }
      if (isSet) {
        dispatch(updateSetItemOptions(prevOptions))
      } else {
        dispatch(updateItemOptions(prevOptions))
      }
      return
    }

    // ======================== Radio ========================
    // 一個 option group 內只能選一個 option
    if (!isMultiple) {
      // 移除這個 option group 裡已選擇的 option
      _.remove(prevOptions, prevOption => prevOption.optionGroupId === group.id)

      // active 的 radio: 移除這個 option
      if (isSelected) {
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      } else {
        // inactive 的 radio: 新增這個 option
        prevOptions.push(newOption)
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      }
      return
    }

    // ======================== Checkbox ========================
    // 一個 option group 內最多只能選 group.max 個 option
    if (isMultiple) {
      // active 的 checkbox: 移除這個 option
      if (isSelected) {
        _.remove(prevOptions, prevOption => prevOption.optionItemId === option.id)
        if (isSet) {
          dispatch(updateSetItemOptions(prevOptions))
        } else {
          dispatch(updateItemOptions(prevOptions))
        }
      } else {
        // inactive 的 checkbox: 要判斷已選擇的 options 數量是否已達 group 的上限
        const selectedGroupOptions = _.filter(prevOptions, prevOption => prevOption.optionGroupId === group.id)
        const isMax = group.max !== 0 && selectedGroupOptions.length >= group.max
        // 還沒到上限: 新增這個 option
        if (!isMax) {
          prevOptions.push(newOption)
          if (isSet) {
            dispatch(updateSetItemOptions(prevOptions))
          } else {
            dispatch(updateItemOptions(prevOptions))
          }
        }
      }
    }
  }
}
