import produce from 'immer'
import _ from 'lodash'
import ActionTypes from './ActionTypes'

export const initBatchHistory = {
  preBatchHistories: [],
  pastBatchHistories: [],
}

/** @type {IOrderBatchState} */
export const initialState = {
  batchStashes: [],
  batchHistory: initBatchHistory,
  selectedBatch: null,
  menuItemQuantities: {},
  selectedItemKey: null,
  selectedSetItemKey: null,
}

/**
 * @param {IOrderBatchState} draft
 * @returns {IBatchHistory}
 */
function createBatchHistory (draft, itemKey, setItemKey) {
  return {
    selectedItemKey: itemKey === undefined ? draft.selectedItemKey : itemKey,
    selectedSetItemKey: setItemKey === undefined ? draft.selectedSetItemKey : setItemKey,
    batch: _.cloneDeep(draft.selectedBatch),
    menuItemQuantities: _.cloneDeep(draft.menuItemQuantities),
    createdAt: new Date(),
  }
}

/**
 * @param {IOrderBatchState} draft
 * @param {boolean?} noHistory
 */
function storeHistory (draft, noHistory = false) {
  // 不紀錄歷史
  if (noHistory) return

  // 記錄歷史
  const history = createBatchHistory(draft)
  draft.batchHistory.preBatchHistories.push(history)
  // 清除 REDO
  draft.batchHistory.pastBatchHistories = []
}

export default produce(
  /**
   * @param {IOrderBatchState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.SHOW_NOT_COMPLETE_SETS: {
        const { showNotCompleteSets } = action.payload
        draft.selectedBatch.showNotCompleteSets = showNotCompleteSets
        break
      }
      case ActionTypes.UNDO_BATCH: {
        // 將現在狀態放入 pastBatchHistory 的第一個
        const history = createBatchHistory(draft)
        draft.batchHistory.pastBatchHistories.unshift(history)

        // 拿出最後一個 preBatchHistories，並用覆寫目前的 batch
        const preBatchHistory = draft.batchHistory.preBatchHistories.pop()
        draft.selectedBatch = _.cloneDeep(preBatchHistory.batch)
        draft.menuItemQuantities = _.cloneDeep(preBatchHistory.menuItemQuantities)
        break
      }
      case ActionTypes.REDO_BATCH: {
        // 將現在狀態放入 preBatchHistories 的最後
        const history = createBatchHistory(draft)
        draft.batchHistory.preBatchHistories.push(history)

        // 拿出第一個 pastBatchHistories batch
        const pastBatchHistory = draft.batchHistory.pastBatchHistories.shift()
        draft.selectedBatch = _.cloneDeep(pastBatchHistory.batch)
        draft.menuItemQuantities = _.cloneDeep(pastBatchHistory.menuItemQuantities)
        break
      }
      case ActionTypes.SELECT_BATCH: {
        const { batch } = action.payload

        // reset batch history
        draft.batchHistory = initBatchHistory

        draft.selectedBatch = batch

        // 重新統計 menuItemQuantities
        const menuItemQuantities = {}
        batch.items.forEach(item => {
          menuItemQuantities[item.menuId] = (menuItemQuantities[item.menuId] || 0) + item.quantity
          item.setItems.forEach(setItem => {
            menuItemQuantities[setItem.menuId] = (menuItemQuantities[setItem.menuId] || 0) + setItem.quantity
          })
        })
        draft.menuItemQuantities = menuItemQuantities
        break
      }
      case ActionTypes.ADD_ITEM: {
        const { item, noHistory } = action.payload

        storeHistory(draft, noHistory)

        // 新增項目
        draft.selectedBatch.items.push(item)
        // 記錄餐點數量
        draft.menuItemQuantities[item.menuId] = (draft.menuItemQuantities[item.menuId] || 0) + item.quantity
        break
      }
      case ActionTypes.ADD_SET_ITEM: {
        const { item, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 新增項目
        selectedItem.setItems.push(item)
        // 記錄餐點數量
        draft.menuItemQuantities[item.menuId] = (draft.menuItemQuantities[item.menuId] || 0) + item.quantity
        break
      }
      case ActionTypes.SELECT_ITEM: {
        const { itemKey } = action.payload
        draft.selectedItemKey = itemKey
        break
      }
      case ActionTypes.SELECT_SET_ITEM: {
        const { setItemKey } = action.payload
        draft.selectedSetItemKey = setItemKey
        break
      }
      case ActionTypes.UPDATE_ITEM: {
        const { path, value, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        if (!selectedItem) return

        storeHistory(draft, noHistory)

        // 如果是更改數量，記錄餐點數量
        if (path === 'quantity' || path[0] === 'quantity') {
          const originQuantity = _.get(selectedItem, path)
          draft.menuItemQuantities[selectedItem.menuId] -= originQuantity
          draft.menuItemQuantities[selectedItem.menuId] += value
        }

        // 更改項目
        _.set(selectedItem, path, value)
        break
      }
      case ActionTypes.UPDATE_SET_ITEM: {
        const { path, value, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 如果是更改數量，記錄餐點數量
        if (path === 'quantity' || path[0] === 'quantity') {
          const originQuantity = _.get(selectedSetItem, path)
          draft.menuItemQuantities[selectedSetItem.menuId] -= originQuantity
          draft.menuItemQuantities[selectedSetItem.menuId] += value
        }

        // 更改項目
        _.set(selectedSetItem, path, value)
        break
      }
      case ActionTypes.RESET_ITEMS: {
        storeHistory(draft)

        // 清除所有 items
        draft.selectedBatch.items = []
        // 清除餐點數量
        draft.menuItemQuantities = {}
        break
      }
      case ActionTypes.DELETE_ITEM: {
        const { index, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items[index]

        storeHistory(draft, noHistory)

        // 扣除餐點數量
        selectedItem.setItems.forEach(setItem => {
          draft.menuItemQuantities[setItem.menuId] -= setItem.quantity
        })
        draft.menuItemQuantities[selectedItem.menuId] -= selectedItem.quantity

        // 刪除
        draft.selectedBatch.items.splice(index, 1)
        break
      }
      case ActionTypes.DELETE_SET_ITEM: {
        const { index, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems[index]

        storeHistory(draft, noHistory)

        // 扣除餐點數量
        draft.menuItemQuantities[selectedSetItem.menuId] -= selectedSetItem.quantity

        // 刪除
        selectedItem.setItems.splice(index, 1)
        break
      }
      case ActionTypes.ADD_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 新增 option
        selectedItem.options.push(option)
        selectedItem.options = _.orderBy(selectedItem.options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        break
      }
      case ActionTypes.ADD_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 新增 option
        selectedSetItem.options.push(option)
        selectedSetItem.options = _.orderBy(selectedSetItem.options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        break
      }
      case ActionTypes.UPDATE_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedItemOptionIndex = selectedItem.options.findIndex(selectedItemOption => selectedItemOption.optionGroupId === option.optionGroupId)

        storeHistory(draft, noHistory)

        // 修改 option
        if (selectedItemOptionIndex >= 0) {
          selectedItem.options[selectedItemOptionIndex] = option
        } else {
          selectedItem.options.push(option)
        }
        selectedItem.options = _.orderBy(selectedItem.options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        break
      }
      case ActionTypes.TOGGLE_ITEM_TAG: {
        const { noHistory } = action.payload
        /** @type {IBatchItemTag} */
        const tag = action.payload.tag
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)

        storeHistory(draft, noHistory)

        // 修改 tags
        const itemTagIndex = selectedItem.tags.findIndex(itemTag => itemTag.name === tag.name)
        if (itemTagIndex > -1) {
          selectedItem.tags.splice(itemTagIndex, 1)
        } else {
          selectedItem.tags.push(tag)
        }
        selectedItem.tags = _.orderBy(selectedItem.tags, ['name', 'id'], ['asc', 'asc'])
        break
      }
      case ActionTypes.UPDATE_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)
        const selectedSetItemOptionIndex = selectedSetItem.options.findIndex(selectedSetItemOption => selectedSetItemOption.optionItemId === option.optionItemId)

        storeHistory(draft, noHistory)

        // 修改 option
        if (selectedSetItemOptionIndex >= 0) {
          selectedSetItem.options[selectedSetItemOptionIndex] = option
        } else {
          selectedSetItem.options.push(option)
        }
        selectedSetItem.options = _.orderBy(selectedSetItem.options, ['price', 'name', 'id'], ['desc', 'asc', 'asc'])
        break
      }
      case ActionTypes.DELETE_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedItemOptionIndex = selectedItem.options.findIndex(selectedItemOption => selectedItemOption.optionItemId === option.optionItemId)

        if (selectedItemOptionIndex >= 0) {
          storeHistory(draft, noHistory)

          // 刪除
          selectedItem.options.splice(selectedItemOptionIndex, 1)
        }
        break
      }
      case ActionTypes.DELETE_SET_ITEM_OPTION: {
        const { noHistory } = action.payload
        /** @type {IAppBatchItemOption} */
        const option = action.payload.option
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)
        const selectedSetItemOptionIndex = selectedSetItem.options.findIndex(selectedSetItemOption => selectedSetItemOption.optionItemId === option.optionItemId)

        if (selectedSetItemOptionIndex >= 0) {
          storeHistory(draft, noHistory)

          // 刪除
          selectedSetItem.options.splice(selectedSetItemOptionIndex, 1)
        }
        break
      }
      case ActionTypes.TOGGLE_SET_ITEM_TAG: {
        const { noHistory } = action.payload
        /** @type {IBatchItemTag} */
        const tag = action.payload.tag
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = selectedItem.setItems.find(setItem => setItem.key === draft.selectedSetItemKey)

        storeHistory(draft, noHistory)

        // 修改 tags
        const setItemTagIndex = selectedSetItem.tags.findIndex(itemTag => itemTag.name === tag.name)
        if (setItemTagIndex > -1) {
          selectedSetItem.tags.splice(setItemTagIndex, 1)
        } else {
          selectedSetItem.tags.push(tag)
        }
        selectedSetItem.tags = _.orderBy(selectedSetItem.tags, ['name', 'id'], ['asc', 'asc'])
        break
      }
      case ActionTypes.UPDATE_MODIFIER: {
        const { modifier, noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = _.find(selectedItem?.setItems, setItem => setItem.key === draft.selectedSetItemKey)
        const target = selectedSetItem || selectedItem

        storeHistory(draft, noHistory)

        target.modifiers[0] = modifier
        break
      }
      case ActionTypes.REMOVE_MODIFIER: {
        const { noHistory } = action.payload
        const selectedItem = draft.selectedBatch.items.find(item => item.key === draft.selectedItemKey)
        const selectedSetItem = _.find(selectedItem?.setItems, setItem => setItem.key === draft.selectedSetItemKey)
        const target = selectedSetItem || selectedItem

        storeHistory(draft, noHistory)

        target.modifiers = []
        break
      }
      case ActionTypes.RESET_BATCH: {
        return initialState
      }
      case ActionTypes.STASH_BATCH: {
        // reset batch history
        draft.batchHistory = initBatchHistory
        draft.menuItemQuantities = {}

        // 儲存現在的 batchHistory 到 stashes
        const history = createBatchHistory(draft)
        draft.batchStashes.push(history)
        break
      }
      case ActionTypes.DROP_STASH: {
        const { createdAt } = action.payload.batchStash
        _.remove(draft.batchStashes, batchStash => batchStash.createdAt === createdAt)
        break
      }
      case ActionTypes.APPLY_STASH: {
        const { batchStash } = action.payload
        // reset batch history
        draft.batchHistory = initBatchHistory
        draft.selectedBatch = batchStash.batch
        draft.menuItemQuantities = batchStash.menuItemQuantities
        break
      }
      case ActionTypes.POP_STASH: {
        const { batch, menuItemQuantities, createdAt } = action.payload.batchStash
        // reset batch history
        draft.batchHistory = initBatchHistory

        _.remove(draft.batchStashes, batchStash => batchStash.createdAt === createdAt)
        draft.selectedBatch = batch
        draft.menuItemQuantities = menuItemQuantities
        break
      }
      case ActionTypes.DROP_ALL_STASH: {
        draft.batchStashes = []
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
