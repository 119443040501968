import hooks from '@/hooks'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {{ itemOptions: IAppBatchItemOption[][] }} props
 */
export default function Options (props) {
  const { itemOptions = [] } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  // 若全部 options 都是 [] 的話不顯示 options
  if (!_.find(itemOptions, option => !_.isEmpty(option))) return null

  return (
    <Box className={classes.optionsContainer}>
      {!_.isEmpty(itemOptions) && (
        <Typography className={classes.optionTitle}>
          {t('app.common.item.options')}
        </Typography>
      )}
      <List>
        {_.map(itemOptions, (options, index) => {
          return (
            <ListItem key={index} className={classes.option}>
              {
                _.isEmpty(options)
                  ? <Typography color='inherit' component='span'>{t('app.common.none')}</Typography>
                  : (
                    _.map(options, (option, index) => {
                      const lastIndex = options.length - 1
                      const showDot = index !== lastIndex
                      return (
                        <Box key={index} className={classes.opt}>
                          <Typography className={classes.optText} color='inherit' component='span'>{option.name}</Typography>
                          {Boolean(option.price) && <Typography className={classes.optText} id='price' component='span'>{`(+$${option.price})`}</Typography>}
                          {showDot && <Typography className={classes.optText} color='inherit' component='span'>,</Typography>}
                        </Box>
                      )
                    })
                  )
              }
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  optionsContainer: {
    padding: 4,
  },
  optionTitle: {
    fontSize: '1.4rem',
    color: theme.palette.text.primaryLight,
  },
  option: {
    fontSize: '1.2rem',
    color: theme.palette.info.main,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    margin: '4px 0px',
  },
  opt: {
    fontSize: 'inherit',
    marginRight: 4,
    '& #price': {
      marginLeft: 4,
      color: theme.palette.grey[500],
    },
  },
  optText: {
    fontSize: 'inherit',
  },
}))
