import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @param {{ children: any }} props
 */
export default function PageContent (props) {
  const { children } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  return (
    <div className={classes.content}>
      <div className={classes.background}>
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  background: {
    flex: 1,
    backgroundImage: `linear-gradient(${theme.palette.primary.main} 64px, ${theme.palette.secondary.main} 64px, ${theme.palette.secondary.main})`,
    padding: props => {
      if (props.mode.isMobile) return '0px 16px'
      if (props.mode.isTablet) return '0px 24px'
    },
  },
}))
