import constants from '@/constants'
import hooks from '@/hooks'
import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * text: string,
 * onClick: onClick,
 * disabled?: boolean,
 * children?: any,
 * className?: string,
 * }} props
 */
export default function SubmitButton (props) {
  const { text, onClick, disabled = false, children, className } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <ButtonBase
      disabled={disabled}
      className={clsx(classes.submitButton, className, { [classes.disabled]: disabled })}
      onClick={onClick}
    >
      <Typography variant='h5' className={classes.submitButtonText}>
        {text}
      </Typography>
      {children}
    </ButtonBase>
  )
}
const useStyles = makeStyles(theme => ({
  submitButton: {
    position: 'relative',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: props => {
      if (props.mode.isMobile) return constants.app.width['FOOTER/SUBMIT_BUTTON--MOBILE']
      if (props.mode.isTablet) return constants.app.width['FOOTER/SUBMIT_BUTTON--TABLET']
    },
    paddingLeft: 16,
    borderRadius: '80px 0px 0px 80px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    fontWeight: 'bold',
  },
  disabled: {
    backgroundColor: `${theme.palette.disabled.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  submitButtonText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '2.8rem'
    },
    fontWeight: 'inherit',
  },
}))
