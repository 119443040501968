import constants from '@/constants'
import hooks from '@/hooks'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * handleBack?: function,
 * right?: node
 * }} props
 */
export default function AppBar (props) {
  const { handleBack, right } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <main className={classes.appBarContainer}>
      <section className={clsx(classes.section, classes.sectionLeft)}>
        {handleBack && (
          <IconButton className={clsx(classes.iconButton)} onClick={handleBack}>
            <ArrowBackIcon className={classes.backIcon} />
          </IconButton>
        )}
      </section>
      <section className={clsx(classes.section, classes.sectionRight)}>
        {right}
      </section>
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    display: 'flex',
    height: props => {
      if (props.mode.isMobile) return constants.app.height['APPBAR--MOBILE']
      if (props.mode.isTablet) return constants.app.height['APPBAR--TABLET']
    },
    backgroundColor: theme.palette.primary.main,
    padding: props => {
      if (props.mode.isMobile) return '0px 4px'
      if (props.mode.isTablet) return '0px 12px'
    },
  },
  section: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  sectionLeft: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  sectionRight: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
  backIcon: {
    fontSize: props => {
      if (props.mode.isMobile) return '2.4rem'
      if (props.mode.isTablet) return '2.4rem'
    },
  },
}))
