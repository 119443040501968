import produce from 'immer'
import ActionTypes from './ActionTypes'

/** @type {IMenuState} */
export const initialState = {
  isInit: false,
  isPictureMode: false,
  isNumberPadMode: false,
  isBatchListExpand: false,
  rootCategory: {},
  rootSetCategory: {},
  categories: {},
  menus: {},
  sets: {},
  selectedCategory: null,
  selectedSet: null,
  selectedSetStep: null,
  isSearchExpanded: false, // only use in text mode
  searchText: '',
}

export default produce(
  /**
   * @param {IMenuState} draft
   * @param {IAction} action
   */
  (draft, action) => {
    switch (action?.type) {
      case 'LOGOUT': {
        return initialState
      }
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_PICTURE_MODE: {
        draft.isPictureMode = action.payload.isPictureMode
        break
      }
      case ActionTypes.UPDATE_BATCH_LIST_EXPAND: {
        draft.isBatchListExpand = action.payload.isBatchListExpand
        break
      }
      case ActionTypes.UPDATE_SEARCH_EXPAND: {
        draft.isSearchExpanded = action.payload.isSearchExpanded
        break
      }
      case ActionTypes.UPDATE_CATEGORIES: {
        const { rootCategory, rootSetCategory, categories, menus, sets } = action.payload
        draft.rootCategory = rootCategory
        draft.rootSetCategory = rootSetCategory
        draft.categories = categories
        draft.menus = menus
        draft.sets = sets
        break
      }
      case ActionTypes.SELECT_CATEGORY: {
        draft.selectedCategory = action.payload.category
        break
      }
      case ActionTypes.SELECT_SET: {
        draft.selectedSet = action.payload.set
        break
      }
      case ActionTypes.SELECT_SET_STEP: {
        draft.selectedSetStep = action.payload.setStep
        break
      }
      case ActionTypes.UPDATE_SEARCH_TEXT: {
        draft.searchText = action.payload.searchText
        break
      }
      default: {
        return draft
      }
    }
  },
  initialState,
)
