import DrawerHeader from '@/components/drawers/OptionDrawer/DrawerHeader'
import SubmitButton from '@/components/drawers/OptionDrawer/SubmitButton'
import StepGroup from '@/components/drawers/StepDrawer/StepGroup'
import SwipeableDrawer from '@/components/SwipeableDrawer'
import hooks from '@/hooks'
import { isSetItemComplete } from '@/libs/order'
import { actions, useDispatch, useSelector } from '@/redux'
import orderappLibs from '@dimorder/orderapp-lib/dist/libs'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import DrawerContent from './DrawerContent'

/**
 * @component
 * @param {*} props
 * @returns {Node}
 */
export default function SetItemDrawer (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const dispatch = useDispatch()
  const [selectedItem] = hooks.order.useSelectedBatchItem()
  const selectedOrder = hooks.order.useSelectedOrder()

  const open = useSelector(state => state.app.drawers.setItem.open)
  const openDrawer = () => dispatch(actions.app.toggleSetItemDrawer('setItem', true))
  const closeDrawer = () => {
    dispatch(actions.app.toggleSetItemDrawer('setItem', false))
  }
  const resetDrawerData = () => {
    setTimeout(() => {
      dispatch(actions.app.toggleSetItemDrawer('setItem', false, {}))
      dispatch(actions.batchItemTemp.resetItem())
      // 200ms delay for drawer close transition duration
    }, 200)
  }
  const handleClose = () => {
    dispatch(actions.orderBatch.deleteItem(selectedItem?.key))
    closeDrawer()
    resetDrawerData()
  }

  // IAppSet
  const setMenus = useSelector(state => state.menu.sets)
  const setMenu = useSelector(state => state.app.drawers.setItem.data?.setMenu) // 如果有 setMenu 代表在 /restaurant 點餐
  const setItemMenu = useSelector(state => state.app.drawers.setItem.data.setItemMenu) // 如果有 setItemMenu 代表點開了根據特定步驟拆開的套餐的其中一個 setItem
  const batchItem = useSelector(state => state.app.drawers.setItem.data?.batchItem) // 如果有 batchItem 代表在 /checkout 編輯
  const originalSetMenu = !_.isEmpty(batchItem) ? setMenus[batchItem?.menuId] : setMenu
  const separatedStep = originalSetMenu?.separatedStep

  // IAppSet
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const isComplete = open && isSetItemComplete(batchItemTemp, setMenu)
  const handleAddItem = () => {
    dispatch(actions.orderBatch.addTempItem(batchItemTemp))
  }
  /* const handleReplaceItem = () => {
    dispatch(actions.orderBatch.replaceItem(batchItemTemp))
  } */
  const handleSubmit = () => {
    handleClose()
    // 有給 batchItem 代表在 checkout 編輯，所以編輯完後要取代原有的 batchItem
    /* if (!_.isEmpty(batchItem)) {
      return handleReplaceItem()
    } */
    handleAddItem()
  }

  const calculatedItem = !_.isEmpty(batchItemTemp) && Boolean(selectedOrder)
    ? orderappLibs.calculateBatchItem(batchItemTemp, selectedOrder)
    : {}

  return (
    <SwipeableDrawer
      open={open}
      onOpen={openDrawer}
      onClose={handleClose}
      paperClassName={classes.drawerPaper}
      anchor='bottom'
    >
      <DrawerContent>
        <DrawerHeader name={setItemMenu?.name} total={calculatedItem.total} />
        <ul className={classes.groupList}>
          {_.map(setMenu?.steps, (step, index) => {
            if (step.id === separatedStep) {
              // 不顯示已經被拆開成獨立套餐的步驟
              return null
            }
            return (
              <StepGroup
                key={index}
                group={step}
                menu={setMenu}
              />
            )
          })}
        </ul>
        <SubmitButton
          disabled={!isComplete}
          onClick={handleSubmit}
        />
      </DrawerContent>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    maxHeight: 'calc(100% - 66px)',
  },
  groupList: {
    display: 'grid',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
  },
}))
