import hooks from '@/hooks'
import colors from '@/theme/colors'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

/**
 * @typedef {Object} IButtonConfig
 * @property {string} text
 * @property {function?} onClick
 */
/**
 * @param {{
 * button: IButtonConfig,
 * buttons: IButtonConfig[]
 * }} props
 */
export default function Buttons (props) {
  const { button, buttons } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  const renderButtons = () => {
    if (button) {
      const { text, onClick } = button
      return (
        <Button
          className={classes.button}
          variant='contained'
          fullWidth
          disableElevation
          onClick={onClick}
        >
          <Typography className={classes.buttonText}>
            {text}
          </Typography>
        </Button>
      )
    }
    if (buttons) {
      return _.map(buttons, (button, index) => {
        const { text, onClick } = button
        return (
          <Button
            key={index}
            className={classes.button}
            variant='contained'
            fullWidth
            disableElevation
            onClick={onClick}
          >
            <Typography className={classes.buttonText}>
              {text}
            </Typography>
          </Button>
        )
      })
    }
  }

  return (
    <div className={classes.buttonsContainer}>
      {renderButtons()}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    flexShrink: 0,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& button:first-child': {
      backgroundColor: colors.components.button.blue.primary.main,
      color: colors.components.button.blue.primary.contrastText,
    },
    marginBottom: 24,
  },
  button: {
    backgroundColor: colors.components.button.blue.secondary.main,
    color: colors.components.button.blue.secondary.contrastText,
    padding: '8px 16px',
    margin: props => {
      if (props.mode.isMobile) return '4px auto'
      if (props.mode.isTablet) return '6px auto'
    },
    maxWidth: props => {
      if (props.mode.isMobile) return 'unset'
      if (props.mode.isTablet) return '80%'
    },
  },
  buttonText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.6rem'
      if (props.mode.isTablet) return '1.8rem'
    },
  },
}))
