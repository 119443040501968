import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import LangOption from './LangOption'

/**
 * @param {{ lang: string }} props
 */
export default function ChangeLangDialog (props) {
  const langs = ['en-US', 'zh-HK', 'zh-TW']
  const { lang } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.dialogs.changLang)
  const handleClose = () => dispatch(actions.app.toggleDialog('changLang'))

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paperRoot }}>
      {_.map(langs, (lng, index) => {
        const isSelected = lng === lang
        return <LangOption key={index} lang={lng} selected={isSelected} onClose={handleClose} />
      })}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paperRoot: {
    '& li': {
      boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
    },
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
}))
