import { Radio as MuiRadio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import PropTypes from 'prop-types'
import React from 'react'

Radio.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * selected: Boolean
 * onClick: Function
 * }} props
 */
export default function Radio (props) {
  const { selected, onClick } = props
  const classes = useStyles(props)

  return (
    <MuiRadio
      color='primary'
      icon={<RadioButtonUncheckedIcon className={classes.radioUncheckedIcon} />}
      classes={{ root: classes.radioRoot }}
      checked={selected}
      onClick={onClick}
    />
  )
}

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.1,
  },
  radioRoot: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  radioUncheckedIcon: {
    color: theme.palette.text.primary,
  },
  listItemSecondaryActionRoot: {
    right: 0,
  },
}))
