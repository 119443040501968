import hooks from '@/hooks'
import orderappLibs from '@dimorder/orderapp-lib/dist/libs'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import CheckoutItem from './CheckoutItem'
import EmptyItem from './CheckoutItem/EmptyItem'

export default function CheckoutItemList (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const selectedOrder = hooks.order.useSelectedOrder()
  const batchItems = hooks.order.useSelectedBatch()?.items ?? []
  const batchItemGroups = _.chain(batchItems)
    .map((batchItem) => orderappLibs.calculateBatchItem(batchItem, selectedOrder))
    .groupBy('menuId')
    .value()

  return (
    <div className={classes.checkoutItemListContainer}>
      {
        !_.isEmpty(batchItemGroups)
          ? _.map(batchItemGroups, (batchItems, menuId) => {
            return <CheckoutItem key={menuId} menuId={menuId} batchItems={batchItems} />
          })
          : <EmptyItem />
      }
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  checkoutItemListContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    marginBottom: 64,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[10],
  },
}))
