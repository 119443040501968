import hooks from '@/hooks'
import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * category: IAppCategory,
 * onClick: function
 * }} props
 */
export default function CategoryMenuItem (props) {
  const { category, onClick } = props
  const classes = useStyles(props)
  const selectedCategory = hooks.menu.useSelectedCategory()
  const isSelected = selectedCategory?.id === category.id

  return (
    <Typography
      variant='h3'
      className={clsx(
        classes.categoryMenuItem,
        { [classes.categoryMenuItemSelected]: isSelected },
      )}
      onClick={onClick}
    >
      {category.name}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  categoryMenuItem: {
    cursor: 'pointer',
    flexShrink: 0,
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
    margin: 4,
    padding: '12px 16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '2.2rem',
    fontWeight: 700,
    border: `4px solid ${colors.divider}`,
  },
  categoryMenuItemSelected: {
    color: theme.palette.primary.contrastTextHighlight,
    border: `4px solid ${theme.palette.primary.contrastTextHighlight}`,
  },
}))
