import SubmitSuccess from '@/assets/icons/SubmitSuccess'
import hooks from '@/hooks'
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Result (props) {
  const mode = hooks.app.useMode()
  const theme = useTheme()
  const customColors = hooks.app.useCustomColors()
  const classes = useStyles({ ...props, mode, customColors })
  const { t } = useTranslation()
  const description = t('app.confirmation.success.description').split('\\n')

  return (
    <main className={classes.resultContainer}>
      <SubmitSuccess
        className={classes.resultIcon}
        classes={{ root: classes.resultIconRoot }}
        strokecolor={theme.palette.secondary.contrastText}
      />
      <Typography className={clsx(classes.resultText, classes.resultTitle)}>
        {t('app.confirmation.success.title')}
      </Typography>

      <Typography className={clsx(classes.resultText, classes.resultDescription)}>
        {_.map(description, (line, index) => <span key={index}>{line}</span>)}
      </Typography>

    </main>
  )
}
const useStyles = makeStyles(theme => ({
  resultContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,
  },
  resultIcon: {
    width: props => {
      if (props.mode.isMobile) return 80
      if (props.mode.isTablet) return 80
    },
    height: props => {
      if (props.mode.isMobile) return 80
      if (props.mode.isTablet) return 80
    },
    margin: props => {
      if (props.mode.isMobile) return 8
      if (props.mode.isTablet) return 8
    },
  },
  resultIconRoot: {
    fill: 'none',
  },
  resultText: {
    fontWeight: 'bold',
    color: theme.palette.secondary.contrastText,
    padding: '4px 16px',
  },
  resultTitle: {
    fontSize: '2.4rem',
    color: props => {
      if (props.customColors.secondary.main) {
        return theme.palette.secondary.contrastText
      }
      return theme.palette.error.main
    },
  },
  resultDescription: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: props => {
      if (props.mode.isMobile) return '1.4rem'
      if (props.mode.isTablet) return '1.4rem'
    },
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
}))
