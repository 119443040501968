import SubmitButton from '@/components/drawers/OptionDrawer/SubmitButton'
import hooks from '@/hooks'
import { deleteDependentItem, isOptionsComplete } from '@/libs/order'
import { actions, useDispatch, useSelector } from '@/redux'
import { Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SetOptionGroup from './SetOptionGroup'

export default function SetItemOptionDialog (props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const open = useSelector(state => state.app.dialogs.setItemOption.open)
  const handleClose = () => {
    dispatch(actions.app.toggleIModalDialog('setItemOption', false))
    setTimeout(() => {
      dispatch(actions.app.toggleIModalDialog('setItemOption', false, {}))
      // 200ms delay for dialog close transition duration
    }, 200)
  }

  // SetItemStepper handleIncrement 的時候把 setItemMenu (IAppMenuItem) 存到 redux 讓這裡可以拿到
  const setItemMenu = useSelector(state => state.app.dialogs.setItemOption.data?.setItemMenu) ?? {} // IAppMenuItem
  useEffect(() => { open && dispatch(actions.batchItemTemp.addSetItem(setItemMenu)) }, [setItemMenu])

  // IMenuOptionGroup
  const options = setItemMenu?.options

  // IAppBatchItem
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const batchSetItemTemp = hooks.batchItemTemp.useBatchSetItemTemp()
  const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
  const isComplete = open && !_.isEmpty(batchSetItemTemp) && isOptionsComplete(batchSetItemTemp, setItemMenu)
  const isMax = useSelector(state => state.app.dialogs.setItemOption.data?.isMax) ?? false

  const handleAddSetItem = () => {
    if (isMax) {
      const prevSelectedSetItem = _.find(setItems, setItem => setItem.step === setItemMenu.step)
      // 判斷該步驟是否以達上限，若已達上限則移除其他同步驟的餐點
      // Radio 才會有這個情況
      // Checkbox 因為步驟 isMax 後其他未被選擇的餐點會被 disabled 沒辦法點，所以沒有需要移除的問題
      _.remove(setItems, setItem => setItem.step === setItemMenu.step)
      // 也要移除所有 depends 原本選擇的 setItem 的餐點
      deleteDependentItem(prevSelectedSetItem?.setMenuId, setItems)
    }
    setItems.push(batchSetItemTemp)
    dispatch(actions.batchItemTemp.updateItem(['setItems'], setItems))
  }

  const handleSubmit = () => {
    handleAddSetItem()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.rootPaper }}
      disableBackdropClick
    >
      <header className={classes.header}>
        <Typography className={classes.title}>
          {t('app.dialog.setItem_option_dialog.custom')}
        </Typography>
      </header>
      <ul className={classes.groupList}>
        {_.map(options, (optionGroup, index) => {
          return (
            <SetOptionGroup
              key={index}
              group={optionGroup}
              menu={setItemMenu}
            />
          )
        })}
      </ul>
      <footer className={classes.footer}>
        <SubmitButton
          text={t('app.common.confirm')}
          disabled={!isComplete}
          onClick={handleSubmit}
        />
      </footer>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  rootPaper: {
    minWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
  },
  title: {
    fontWeight: 'bold',
    color: 'white',
    padding: 15,
    fontSize: '2rem',
    textAlign: 'center',
  },
  groupList: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(1),
    minHeight: 160,
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
    overflow: 'auto',
    '& p': {
      fontSize: '1.8rem',
    },
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  footer: {
    display: 'grid',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1),
  },
}))
