import ArrowBackIcon from '@/assets/icons/ArrowBackIcon'
import { IconButton, SwipeableDrawer as MuiSwipeableDrawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @param {{
 * open: boolean,
 * onOpen: function,
 * onClose: function,
 * anchor: 'top' | 'bottom' | 'left' | 'right',
 * children: any,
 * }} props
 */
export default function SwipeableDrawer (props) {
  const { open, onOpen, onClose, anchor, children, paperClassName } = props
  const classes = useStyles(props)

  return (
    <MuiSwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: clsx(classes.drawerPaper, paperClassName) }}
    >
      <IconButton className={classes.backButton} onClick={onClose}>
        <ArrowBackIcon strokecolor='white' className={classes.arrowBackIcon} classes={{ root: classes.arrowBackIconRoot }} />
      </IconButton>
      {children}
    </MuiSwipeableDrawer>
  )
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    overflowY: 'visible',
  },
  backButton: {
    position: 'absolute',
    top: -64,
    padding: 24,
  },
  arrowBackIcon: {
    width: 18,
    height: 18,
  },
  arrowBackIconRoot: {
    fill: 'none',
  },

}))
