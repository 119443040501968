import hooks from '@/hooks'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Header from './Header'
import OrderHistoryItemList from './OrderHistoryItemList'
import Total from './Total'

// TODO: 「我的」和「全部」的 filter

/**
 * @param {{ order: IAppOrder }} props
 */
export default function OrderHistory (props) {
  const { order } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <main className={classes.orderHistoryContainer}>
      <Header order={order} />
      <OrderHistoryItemList order={order} />
      <Total order={order} />
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  orderHistoryContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: props => {
      if (props.mode.isMobile) return 8
      if (props.mode.isTablet) return 16
    },
    marginBottom: 16,
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[10],
  },
}))
