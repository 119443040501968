import hooks from '@/hooks'
import { ListItem, ListItemIcon, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function EmptyTable (props) {
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <ListItem className={classes.table}>
      <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
        <ReportProblemOutlinedIcon fontSize='large' className={classes.alertIcon} />
      </ListItemIcon>
      <Typography className={classes.tableText}>
        {t('app.change_table.empty_group')}
      </Typography>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
  },
  tableText: {
    fontSize: props => {
      if (props.mode.isMobile) return '1.4rem'
      if (props.mode.isTablet) return '2.2rem'
    },
  },
  listItemIconRoot: {
    minWidth: 'unset',
    marginRight: 4,
  },
  alertIcon: {
    color: theme.palette.warning.main,
  },
}))
