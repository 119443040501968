import hooks from '@/hooks'
import { ListItem, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

GroupItemBase.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  rightAction: PropTypes.node,
}

/**
 * @component
 * @param {{
 * variant?: 'radio' | 'sold'
 * item: IMenuOptionItem
 * onClick: Function
 * rightAction: Node
 * }} props
 */
export default function GroupItemBase (props) {
  const { item, onClick, rightAction } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <ListItem
      className={classes.groupItem}
      onClick={onClick}
    >
      <Typography className={classes.name}>
        {item.name}
        {Boolean(item.price) && <Typography variant='caption' className={classes.price}>{`(+$${item.price})`}</Typography>}
      </Typography>
      <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryActionRoot }}>
        {rightAction}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  price: {
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
  },
  listItemSecondaryActionRoot: {
    right: 0,
  },
  groupItem: {
    boxShadow: '0px 1px #E7E7E7',
    padding: props => {
      if (props.mode.isMobile) return '8px 48px 8px 8px'
      if (props.mode.isTablet) return '12px 48px 12px 12px'
    },
  },
  name: {
    fontSize: '1.8rem',
  },
  optionPrice: {
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
    marginLeft: 8,
  },
}))
