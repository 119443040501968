import hooks from '@/hooks'
import colors from '@/theme/colors'
import opacity from '@/theme/opacity'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TimeLabel from './TimeLabel'

/**
 * @param {{ order: IAppOrder }} props
 */
export default function Header (props) {
  const { table, serial, status, createdAt } = props.order
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })
  const { t } = useTranslation()

  return (
    <div className={classes.headerConainer}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <TimeLabel createdAt={createdAt} />
        <Typography component='span' className={classes.statusLabel}>
          {t(`app.status.order.${status}`)}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' style={{ marginTop: 24 }}>
        <Typography className={classes.orderSerial}>
          {t('app.common.order_serial', { serial })}
        </Typography>
        <Typography className={classes.table}>
          {t('app.common.table_number', { table })}
        </Typography>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  headerConainer: {
    position: 'relative',
    zIndex: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primaryDark,
    padding: 8,
    marginLeft: props => {
      if (props.mode.isMobile) return 8
      if (props.mode.isTablet) return 'unset'
    },
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusLabel: {
    padding: '2px 4px',
    fontSize: '1.2rem',
    color: colors.components.label.status,
    border: `1px solid ${colors.components.label.status}`,
    borderRadius: 2,
  },
  orderSerial: {
    fontSize: '1.2rem',
    minWidth: 160,
    paddingBottom: 4,
    boxShadow: `0px 1px ${colors.divider}${opacity[25]}`,
  },
  table: {
    fontSize: '1.6rem',
  },
}))
