import hooks from '@/hooks'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @param {{ title: string }} props
 * @param {{ subtitle: string }} props
 */
export default function PageHeader (props) {
  const { title, subtitle } = props
  const mode = hooks.app.useMode()
  const classes = useStyles({ ...props, mode })

  return (
    <div className={classes.pageHeaderContainer}>
      <Typography className={classes.pageHeaderText}>
        {title}
        {
          subtitle &&
            <span className={classes.pageSubtitleText}>
              {subtitle}
            </span>
        }
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  pageHeaderContainer: {
    padding: props => {
      if (props.mode.isMobile) return '16px 16px'
      if (props.mode.isTablet) return '16px 24px'
    },
    backgroundColor: theme.palette.primary.main,
  },
  pageHeaderText: {
    color: theme.palette.primary.contrastText,
    fontSize: props => {
      if (props.mode.isMobile) return '2.8rem'
      if (props.mode.isTablet) return '2.8rem'
    },
    letterSpacing: '0.2rem',
  },
  pageSubtitleText: {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    letterSpacing: '0.1rem',
    marginLeft: '8px',
  },
}))
