import ExpandButton from '@/components/drawers/StepDrawer/ExpandButton'
import GroupItem from '@/components/drawers/StepDrawer/GroupItem'
import hooks from '@/hooks'
import { calculateStepIsMax, createBatchSetItem, deleteDependentItem, getStepQuantityMax } from '@/libs/order'
import { actions, useDispatch } from '@/redux'
import { Collapse, List } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

StepItemList.propTypes = {
  group: PropTypes.object,
  list: PropTypes.array,
  max: PropTypes.number,
  menu: PropTypes.object,
}

/**
 * @component
 * @param {{
 * group: IAppSet
 * list: IAppMenuItem[]
 * max?: Number
 * menu: IAppSet
 * }} props
 * @returns {Node}
 */
export default function StepItemList (props) {
  const { group: step, list, max, menu } = props
  const dispatch = useDispatch()
  const merchant = hooks.merchant.useMerchant()
  const batchItemTemp = hooks.batchItemTemp.useBatchItemTemp()
  const setItems = batchItemTemp.setItems ? [...batchItemTemp.setItems] : []
  const stepMax = getStepQuantityMax(batchItemTemp, step)
  const [expanded, setExpanded] = useState(false)
  const collapsedList = _.take(list, max ?? list.length)
  const remainList = _.drop(list, max)
  const isMultiple = stepMax > 1
  const isMax = calculateStepIsMax(batchItemTemp, step)
  const stepSetItems = _.filter(menu.menus, m => m.step === step.id)

  useEffect(() => {
    // 自動選滿 step 底下的全部 setItem
    const shouldSelectAll = isMultiple && // 當 step 為複選
    _.size(stepSetItems) <= step.min && // step 最少需點數量與底下 setItem 數量相等
    _.every(stepSetItems, setItem => setItem.max <= 1 && _.isEmpty(setItem.options)) // 底下每個 setItem 皆為單選且沒有選項

    if (shouldSelectAll) {
      const batchSetItemTemps = list.map(setItemMenu => {
        return createBatchSetItem(setItemMenu)
      })
      dispatch(actions.batchItemTemp.updateItem(['setItems'], [...setItems, ...batchSetItemTemps]))
    }
  }, [])

  const renderGroupItems = (list) => {
    return _.map(
      expanded ? list : collapsedList,
      (setItemMenu, index) => {
        const batchSetItemTemp = createBatchSetItem(setItemMenu)
        const hasOptions = !_.isEmpty(setItemMenu.options)
        const isSelected = Boolean(_.find(setItems, setItem => setItem.setMenuId === setItemMenu.id))
        const isStepper = isMultiple && setItemMenu.max > 1
        let isDisabled = false
        if (isMultiple && (isMax && !isSelected)) {
          isDisabled = true
        }

        const handleRadioClick = () => {
          // 一個 step group 內只能選一個 setItem
          if (!isSelected) {
            // 紀錄原本這個 step 所選擇的 setItem
            const prevSelectedSetItem = _.find(setItems, setItem => setItem.step === setItemMenu.step)
            // 移除所有 step 跟目前選擇的 setItem 相同的 setItem
            _.remove(setItems, setItem => setItem.step === setItemMenu.step)
            // 也要移除所有 depends 原本選擇的 setItem
            deleteDependentItem(prevSelectedSetItem?.setMenuId, setItems)
            // 新增目前選擇的 stepItem
            setItems.push(batchSetItemTemp)
          } else {
            // 移除所有 step 跟目前選擇的 setItem 相同的 setItem
            _.remove(setItems, setItem => setItem.step === setItemMenu.step)
            // 也要移除所有 depends 目前選擇的 setItem
            deleteDependentItem(setItemMenu.id, setItems)
          }
          dispatch(actions.batchItemTemp.updateItem(['setItems'], setItems))
        }

        const handleCheckboxClick = () => {
          // 一個 step group 內最多只能選 group.max 個 setItem
          if (!isSelected) {
            // 如果沒有選過這個 stepItem 就新增
            setItems.push(batchSetItemTemp)
          } else {
            // 如果已經選擇這個 stepItem 就移除
            _.remove(setItems, setItem => setItem.setMenuId === setItemMenu.id)
            // 除了移除 stepItem 本身以外，也要移除任何 depends 這個 setItem 的餐點
            deleteDependentItem(setItemMenu.id, setItems)
          }
          dispatch(actions.batchItemTemp.updateItem(['setItems'], setItems))
        }

        const handleClick = () => {
          if (isDisabled) return

          if (hasOptions) {
            if (!isSelected) {
              return dispatch(actions.app.toggleIModalDialog('setItemOption', true, { setItemMenu, isMax }))
            }
          }

          if (isMultiple) {
            handleCheckboxClick()
          } else {
            handleRadioClick()
          }
        }

        const isSold = hooks.menu.useSold(setItemMenu, merchant)

        if (isSold) {
          return <GroupItem key={index} variant='sold' item={setItemMenu} />
        }
        if (isStepper) {
          return <GroupItem key={index} variant='stepper' item={setItemMenu} />
        }
        return (
          isMultiple
            ? <GroupItem key={index} variant='radio' item={setItemMenu} selected={isSelected} disabled={isDisabled} onClick={handleClick} />
            : <GroupItem key={index} variant='radio' item={setItemMenu} selected={isSelected} onClick={handleClick} />
        )
      },
    )
  }

  return (
    <List>
      {renderGroupItems(collapsedList)}

      <Collapse in={expanded}>
        {renderGroupItems(remainList)}
      </Collapse>

      <Collapse in={!expanded && remainList.length > 0}>
        <ExpandButton
          number={remainList.length}
          onClick={() => setExpanded(true)}
        />
      </Collapse>
    </List>
  )
}
