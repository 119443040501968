import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function MinusIcon (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 66 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='32.0186' cy='32.0186' r='29.7686' stroke={strokecolor} strokeWidth='4.5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M46.6108 34.2686H44.3608H19.6766H17.4266V29.7686H19.6766H44.3608H46.6108V34.2686Z' fill={strokecolor} />
    </SvgIcon>
  )
}
