import navLogoTakeaway from '@/assets/images/navbar/nav-logo--takeaway.jpg'
import navLogo from '@/assets/images/navbar/nav-logo.png'
import constants from '@/constants'
import hooks from '@/hooks'
import { actions, useDispatch } from '@/redux'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { faArrowLeft, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function Navbar (props) {
  const mode = hooks.app.useMode()
  const merchant = hooks.merchant.useMerchant()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const [showBackButton, setShowBackButton] = useState(false)
  const [logoClickTimes, setLogoClickTimes] = React.useState(0)

  const isTakeaway = false
  const toggleShowBackButton = () => setShowBackButton(!showBackButton)

  React.useEffect(() => {
    let clickTimeout = null
    if (logoClickTimes !== 0) {
      clickTimeout = setTimeout(() => {
        setLogoClickTimes(0)
      }, 3000)
    }

    return () => {
      if (clickTimeout) {
        clearTimeout(clickTimeout)
      }
    }
  }, [logoClickTimes])

  if (mode.isTablet) return null
  return (
    <main className={classes.navContainer}>
      <section className={clsx(classes.navSection, classes.navSectionLeft)}>
        {showBackButton && (
          <IconButton className={clsx(classes.navIconButton, classes.navBackButton)} onClick={toggleShowBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        {isTakeaway
          ? <img src={navLogoTakeaway} alt='nav-logo-takeaway' className={classes.navLogoTakeaway} />
          : (
            <img
              src={navLogo}
              alt='nav-logo'
              className={classes.navLogo}
              onClick={() => {
                if (logoClickTimes === 9) {
                  setLogoClickTimes(0)
                  history.push('/setting')
                } else {
                  setLogoClickTimes(logoClickTimes + 1)
                }
              }}
            />
          )}
        <Typography variant='h4' className={classes.navTitle}>
          {merchant.name}
        </Typography>
      </section>
      <section className={clsx(classes.navSection, classes.navSectionRight)}>
        <IconButton className={classes.navIconButton} onClick={() => dispatch(actions.app.toggleDialog('changLang'))}>
          <FontAwesomeIcon icon={faGlobeAmericas} />
        </IconButton>
        <IconButton className={classes.navIconButton} onClick={() => history.push('/history')}>
          <FontAwesomeIcon icon={faFileAlt} />
        </IconButton>
      </section>
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  navContainer: {
    display: 'flex',
    height: constants.app.height['NAVBAR--MOBILE'],
  },
  navSection: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  navSectionLeft: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  navSectionRight: {
    justifyContent: 'flex-end',
  },
  navLogo: {
    height: '2.4rem',
    marginRight: 12,
    marginBottom: -4, // 圖片垂直置中問題
  },
  navLogoTakeaway: {
    height: '2.8rem',
    marginRight: 12,
  },
  navTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.text.primaryDark,
  },
  navIconButton: {
    fontSize: '2.4rem',
    color: theme.palette.text.primaryDark,
  },
  navBackButton: {
    fontSize: '1.6rem',
  },
}))
